import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: 'none',
    color: '#999',
  },
}));

interface ILinkProps {
  // eslint-disable-next-line react/require-default-props
  href?: string;
  title: string;
}

const Link: React.FC<ILinkProps> = ({
  href = undefined,
  ...props
}): JSX.Element => {
  const classes = useStyles();
  const { title } = props;

  return (
    <a href={href || '#'} className={classes.link}>
      { title }
    </a>
  );
};

export default Link;
