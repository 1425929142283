import React, { useEffect, useState } from 'react';
import {
  Box, Card, makeStyles,
} from '@material-ui/core';
import { timeStampToStringView } from '../../helpers/convert-to-timestamp';
import alertIconRed from '../../assets/svg/alertIconRed.svg';
import alertIconYellow from '../../assets/svg/alertIconYellow.svg';
import alertIconGreen from '../../assets/svg/alertIconGreen.svg';
import ToolTip from '../ToolTip/ToolTip';

interface ITaskItemStudentProps {
  task: any;
}

const useStyles = makeStyles(() => ({
  card: {
    padding: '1em',
    marginBottom: '1em',
    '& h3': {
      margin: '0',
    },
    '& h4': {
      margin: '0',
    },
  },
  shadow: {
    boxShadow: `rgb(0 0 0 / 20%) 0px 10px 10px -4px, 
    rgb(0 0 0 / 14%) 0px 3px 4px 0px, 
    rgb(0 0 0 / 12%) 0px 1px 8px 0px`,
  },
  svg: {
    maxWidth: '2.5em',
    minWidth: '2.5em',
  },
}));

const TaskItemStudent: React.FC<ITaskItemStudentProps> = ( props ) => {
  const classes = useStyles();
  const { task } = props;

  const [theTask, setTheTask] = useState<any>();

  useEffect(() => {
    setTheTask( task );
  }, [task]);

  return (
    <Card className={`${classes.card} ${classes.shadow}`}>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <h3>{theTask && theTask.subject}</h3>
          <h4>
            {theTask && theTask.title}
            {'  -  '}
            Fecha de entrega
            {': '}
            {timeStampToStringView( theTask && theTask.dateEnd.seconds )}
          </h4>
        </Box>
        {theTask && theTask.notes
        && theTask.notes.length >= 1
        && new Date( theTask.notes[0].fecha.toDate()) < new Date( theTask.dateEnd.toDate()) && (
          <ToolTip title="Completada">
            <Box display="block" textAlign="center" ml={1} mr={1}>
              <img className={classes.svg} src={alertIconGreen} alt="" />
            </Box>
          </ToolTip>
        )}
        {theTask
        && theTask.notes
        && theTask.notes.length <= 0
        && (
          <ToolTip title="No completada">
            <Box display="block" textAlign="center" ml={1} mr={1}>
              <img className={classes.svg} src={alertIconYellow} alt="" />
            </Box>
          </ToolTip>
        )}
        {theTask
        && theTask.notes
        && theTask.notes.length >= 1
        && new Date( theTask.notes[0].fecha.toDate()) > new Date( theTask.dateEnd.toDate())
        && (
          <ToolTip title="Completada con retraso">
            <Box display="block" textAlign="center" ml={1} mr={1}>
              <img className={classes.svg} src={alertIconRed} alt="" />
            </Box>
          </ToolTip>
        )}
      </Box>
    </Card>
  );
};

export default TaskItemStudent;
