import React from 'react';
import {
  reduxForm, InjectedFormProps, Field,
} from 'redux-form';
import { Grid } from '@material-ui/core';
import { urlValidator } from '../../helpers/validate-forms';
import Input from '../Input/Input';
import Button from '../Button/Button';
import CenterLine from '../Center/CenterInLine';
import ContainerForm from '../ContainerForm/ContainerForm';
import ContainerInputs from '../ContainerInputs/ContainerInputs';
import FormRedux from '../FormRedux/FormRedux';

const ZoomForm: React.FC<InjectedFormProps> = ( props ) => {
  const {
    handleSubmit, submitting,
  } = props;

  return (
    <ContainerForm>
      <FormRedux onSubmit={handleSubmit}>
        <ContainerInputs>
          <Grid item xs={12}>
            <Field
              name="urlZoom"
              type="text"
              label="Enlace para Zoom"
              component={Input}
              placeholder="Url para zoom"
              validate={[urlValidator]}
            />
          </Grid>
          <Grid item xs={12}>
            <CenterLine>
              <Button type="submit" disabled={submitting}>
                Enviar
              </Button>
            </CenterLine>
          </Grid>
        </ContainerInputs>
      </FormRedux>
    </ContainerForm>
  );
};

export default reduxForm({
  form: 'zoom',
  enableReinitialize: true,
})( ZoomForm );
