import React, { Fragment } from 'react';
import * as IconM from '@material-ui/icons/';
import { GridValueGetterParams, GridColDef } from '@material-ui/data-grid';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import ContainerTable from '../Table/ContainerTable/ContainerTable';
import Table from '../Table/Table';
import { IActivity } from '../../interfaces/IActivity';
import CenterInLine from '../Center/CenterInLine';
import ToolTip from '../ToolTip/ToolTip';
import BookName from './components/BookName/BookName';

interface ITableProps{
  activities: IActivity[];
  handleEdit: any;
  handleDelete: any;
  handleNew: any;
  handleShow: any;
}

const TableActivity:React.FC<ITableProps> = ( props ) => {
  const {
    activities, handleDelete, handleEdit, handleNew, handleShow,
  } = props;

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 250,
    },
    {
      field: 'name',
      type: 'string',
      headerName: 'Nombre',
      editable: false,
      minWidth: 200,
    },
    {
      field: 'maxGrade',
      headerName: 'Nota máxima',
      type: 'string',
      editable: false,
      minWidth: 200,
    },
    {
      field: 'bookId',
      headerName: 'Libro',
      editable: false,
      minWidth: 200,
      renderCell: (
        { value },
      ) => ( <BookName bookId={value} /> ),
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      minWidth: 300,
      renderCell: ({ id }: GridValueGetterParams ) => (
        <CenterInLine isRow>
          <ToolTip title="Mostrar detalle de actividad">
            <ButtonIcon handleClick={() => handleShow( id )}>
              <IconM.Visibility />
            </ButtonIcon>
          </ToolTip>
          <ToolTip title="Editar actividad">
            <ButtonIcon handleClick={() => handleEdit( id )}>
              <IconM.Edit />
            </ButtonIcon>
          </ToolTip>
          <ToolTip title="Eliminar actividad">
            <ButtonIcon handleClick={() => handleDelete( id )}>
              <IconM.Delete />
            </ButtonIcon>
          </ToolTip>
        </CenterInLine>

      ),
    },
  ];

  return (
    <>
      <ButtonIcon handleClick={handleNew}>
        <IconM.Add />
      </ButtonIcon>
      <span>Agregar nueva actividad</span>
      <ContainerTable>
        <Table
          columns={columns}
          data={activities}
        />
      </ContainerTable>
    </>
  );
};

export default TableActivity;
