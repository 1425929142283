import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  contentInfo: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
  },
  collapse: {
    padding: 10,
    marginTop: 10,
    backgroundColor: '#eee',
  },
}));

export default useStyles;
