import React, { useState, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import * as IconM from '@material-ui/icons/';
import InputTime from '../InputTime/InputTime';
import LabelInfo from '../LabelInfo/LabelInfo';
import Button from '../Button/Button';
import { isObjEmpty } from '../../helpers/validate-forms';

const useStyles = makeStyles(() => ({
  items: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    columnGap: 10,
  },
}));

interface IInputState {
  start: string;
  end: string;
}

interface IFormTimeProps {
  // eslint-disable-next-line react/require-default-props
  label?: string;
  // eslint-disable-next-line react/require-default-props
  onSave?: any;
  index: number;
  // eslint-disable-next-line react/require-default-props
  dataHours?: any;
  // eslint-disable-next-line react/require-default-props,react/no-unused-prop-types
  loading?: boolean;
}

const FormTime: React.FC<IFormTimeProps> = ( props ) => {
  const classes = useStyles();
  const {
    label = '',
    onSave,
    index: indexDay,
    dataHours,
  } = props;

  const [inputs, setInputs] = useState<IInputState[]>([]);

  useEffect(() => {
    if ( !isObjEmpty( dataHours )) {
      const data = Object.values( dataHours );
      const hours = data.map(( item: any ) => ({
        start: item.start,
        end: item.end,
      }));
      setInputs( hours );
    } else {
      setInputs([{ start: '', end: '' }]);
    }
  }, [props]);

  const handleAddInput = (): void => {
    const inputState = { start: '', end: '' };
    const data = [...inputs, inputState];
    setInputs( data );
  };

  const onChange = (
    index: number, date: any, name: string,
  ): void => {
    const data = inputs.map(( item: IInputState, i: number ) => {
      if ( i !== index ) return item;
      return { ...item, [name]: date.toString() };
    });

    setInputs( data );
    onSave( data, indexDay );
  };

  const handleRemoveInput = (
    index: number,
  ): void => {
    const data = inputs.filter(( item ) => item !== inputs[index]);
    setInputs( data );
    onSave( data, indexDay );
  };

  return (
    <>
      {/* {JSON.stringify( inputs )} */}
      {inputs.map(( item: any, index: number ) => (
        <Grid
          container
          key={index.toString()}
          className={classes.items}
        >
          <Grid item xs={12} sm={12} md={2}>
            {index === 0 && <LabelInfo text={label} />}
          </Grid>
          <Grid item xs={12} sm={5} md={3}>
            <InputTime
              onChange={onChange}
              index={index}
              name="start"
              value={item.start}
              label="Inicio"
            />
          </Grid>
          <Grid item xs={12} sm={5} md={3}>
            <InputTime
              onChange={onChange}
              index={index}
              name="end"
              value={item.end}
              label="Fin"
            />
          </Grid>
          {inputs.length - 1 === index && (
            <Grid item xs={4} sm={2} md={1}>
              <Button
                onClick={handleAddInput}
                color="success"
              >
                <IconM.Add />
              </Button>
            </Grid>
          )}
          <Grid item xs={4} sm={2} md={1}>
            <Button
              onClick={() => handleRemoveInput( index )}
              color="error"
            >
              <IconM.Close />
            </Button>
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default FormTime;
