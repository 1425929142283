import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiPopover from '@material-ui/core/Popover';

export const Accordion = withStyles({
  root: {
    border: '0!important',
  },
  expanded: {},
})( MuiAccordion );

export const AccordionSummary = withStyles({
  root: {
    boxShadow: `rgb(0 0 0 / 20%) 0px 10px 10px -4px, 
    rgb(0 0 0 / 14%) 0px 3px 4px 0px, 
    rgb(0 0 0 / 12%) 0px 1px 8px 0px!important`,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})( MuiAccordionSummary );

export const AccordionDetails = withStyles({
  root: {
    padding: '0px 16px 8px 16px!important',
    background: '#F0F0F0!important',
  },
})( MuiAccordionDetails );

export const Popover = withStyles({
  paper: {
    background: '#F0F0F0!important',
    padding: '1em!important',
  },
})( MuiPopover );
