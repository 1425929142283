import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#fff',
    height: '100vh',
    width: '100vw',
    padding: 0,
    margin: 0,
    display: `block`,
  },
});

interface ICotainerProps {
  // eslint-disable-next-line react/require-default-props
  children?: JSX.Element | JSX.Element[];
}

const Container : React.FC<ICotainerProps> = ( props ) => {
  const classes = useStyles();

  const { children } = props;

  return (
    <div className={classes.root}>
      { children }
    </div>
  );
};

export default Container;
