import React from 'react';
import {
  withRouter, Route, RouteComponentProps,
  RouteChildrenProps, RouteProps,
} from 'react-router-dom';
import Layout from '../components/Layout/Layout';

const RoutePrivate:React.FC<RouteComponentProps &
RouteProps & RouteChildrenProps> = ( props ) => (
  <Layout>
    <Route {...props} />
  </Layout>
);

export default withRouter( RoutePrivate );
