import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { InjectedFormProps } from 'redux-form';
// components
import BookForm from '../../../components/BookForm/BookForm';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { actions } from '../../../redux/ducks/BookDucks';
import { RootState } from '../../../redux/store';
import { IBook } from '../../../interfaces/IBook';
// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import Card from '../../../components/Card/Card';
import Spinner from '../../../components/Spinner/Spinner';

interface Params {
  id: string;
}

const BookEdit: React.FC<InjectedFormProps> = () => {
  const params = useParams<Params>();

  const dispatch = useDispatch();

  const history = useHistory();

  const { bookById, loading } = useSelector(
    ( state: RootState ) => state.book,
  );

  const handleSubmit = ( values: any ): void => {
    const { photo, ...data } = values;
    const book = { ...data } as IBook;
    if ( !String( photo ).includes( 'https' )) {
      dispatch( actions.updateBook( params.id, book, photo ));
    } else {
      dispatch( actions.updateBook( params.id, book, undefined ));
    }
    return history.push( '/admin/libros' );
  };

  useEffect(() => {
    if ( params.id ) {
      dispatch( actions.getBook( params.id ));
    }
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <ContainerBreadcrumbs>
            <Breadcrumbs
              itemsLinks={[{ name: 'libros', url: 'admin/libros' }]}
              itemsText={['editar-libro']}
            />
          </ContainerBreadcrumbs>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          { !loading
            ? <BookForm onSubmit={handleSubmit} initialValues={bookById} />
            : (
              <Spinner />
            )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default BookEdit;
