import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CardMedia } from '@material-ui/core';
import ImageStart from '../../assets/img/Imageninicio.jpg';

const useStyles = makeStyles({
  containerImage: {
    padding: 0,
    margin: 0,
  },
  image: {
    width: '100%',
    height: '100%',
    backgroundColor: '#eee',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

interface ILeftProps {
  // eslint-disable-next-line react/require-default-props
  children?: JSX.Element | JSX.Element[];
}

const LeftSection: React.FC<ILeftProps> = ( props ) => {
  const classes = useStyles();
  const { children } = props;

  return (
    <CardMedia
      className={classes.image}
      image={ImageStart}
    >
      { children }
    </CardMedia>
  );
};

export default LeftSection;
