import { TextField } from '@material-ui/core';
import React from 'react';

interface ISearchBar{
  keyword: string,
  setKeyword: any,
}

const SearchBar: React.FC<ISearchBar> = ( props ) => {
  const { keyword, setKeyword } = props;
  return (
    <TextField
      id="input"
      label="Buscar..."
      value={keyword}
      onChange={( e: any ) => setKeyword( e.target.value )}
      fullWidth
    />
  );
};

export default SearchBar;
