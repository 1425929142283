import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  titleItem: {
    fontWeight: 'bold',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: 5,
  },
  rubric: {
    flex: 1,
  },
  calification: {
    flex: 0.3,
    backgroundColor: '#fff',
    width: 50,
    // height: 30,
    borderRadius: 5,
    // padding: 5,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  itemRubric: {
    marginBottom: 10,
  },
  input: {
  },
}));

export default useStyles;
