import React from 'react';
import {
  makeStyles, Box, Card, CardContent,
} from '@material-ui/core';
import good from '../../assets/svg/hand-peace.svg';

const useStyles = makeStyles({
  svg: {
    width: '3em',
  },
});

const NoHomeworksStudent: React.FC = () => {
  const classes = useStyles();
  return (
    <Card>
      <CardContent>
        <Box display="block" textAlign="center" mt={2}>
          <img className={classes.svg} src={good} alt="goodJob" />
          <p>Excelente, no tienes tareas pendientes.</p>
        </Box>
      </CardContent>
    </Card>
  );
};

export default NoHomeworksStudent;
