import React from 'react';
import { useStyles } from './Styles';
import alertIconYellow from '../../../../assets/svg/alertIconYellow.svg';
import alertIconGreen from '../../../../assets/svg/alertIconGreen.svg';
import alertIconRed from '../../../../assets/svg/alertIconRed.svg';

interface ICalificatorStatus {
  taskItem: any;
}

const CalificatorStatus: React.FC<ICalificatorStatus> = ( props ) => {
  const { taskItem } = props;
  const classes = useStyles();
  const [date] = React.useState( new Date());

  const returnIcon = (): JSX.Element => {
    let htmlContainer;
    switch ( true ) {
      case ( taskItem
        && taskItem.notes
        && taskItem.notes.length >= 1
        && new Date( taskItem.notes[0].fecha.toDate()) < new Date( taskItem.dateEnd.toDate())):
        htmlContainer = (
          <img className={classes.svg} src={alertIconGreen} alt="alerIcon" />
        );
        break;
      case ( taskItem
        && taskItem.notes
        && taskItem.notes.length >= 1
        && new Date( taskItem.notes[0].fecha.toDate()) > new Date( taskItem.dateEnd.toDate())):
        htmlContainer = (
          <img className={classes.svg} src={alertIconRed} alt="alerIcon" />
        );
        break;
      case ( taskItem
        && taskItem.notes
        && taskItem.notes.length <= 0 ):
        htmlContainer = (
          <img
            className={classes.svg}
            src={alertIconYellow}
            alt="alerIcon"
          />
        );
        break;
      case ( taskItem
        && taskItem.notes
        && taskItem.notes.length <= 0
        && new Date( taskItem.dateEnd.toDate()) < date ):
        htmlContainer = (
          <img
            className={classes.svg}
            src={alertIconYellow}
            alt="alerIcon"
          />
        );
        break;
      default:
        htmlContainer = (
          <img className={classes.svg} src={alertIconGreen} alt="alerIcon" />
        );
        break;
    }
    return htmlContainer;
  };

  const returnText = (): JSX.Element => {
    let htmlContainer;
    switch ( true ) {
      case ( taskItem
        && taskItem.notes
        && taskItem.notes.length <= 0 ):
        htmlContainer = (
          <p className={classes.marginRight}>Sin enviar</p>
        );
        break;
      case ( taskItem
        && taskItem.notes
        && taskItem.notes.length >= 1
        && new Date( taskItem.notes[0].fecha.toDate()) > new Date( taskItem.dateEnd.toDate())):
        htmlContainer = (
          <p className={classes.marginRight}>Con retraso</p>
        );
        break;
      case ( taskItem
        && taskItem.notes
        && taskItem.notes.length >= 1
        && new Date( taskItem.notes[0].fecha.toDate()) < new Date( taskItem.dateEnd.toDate())):
        htmlContainer = (
          <p className={classes.marginRight}>Enviado</p>
        );
        break;
      default:
        htmlContainer = (
          <p className={classes.marginRight}>N/A</p>
        );
        break;
    }
    return htmlContainer;
  };

  return (
    <>
      {returnText()}
      {returnIcon()}
    </>
  );
};

export default CalificatorStatus;
