import { timeStamp, TimeStamp } from '../services/firebase';

const getDateWithSeconds = ( seconds: number ):
Date => new Date( seconds * 1000 );

const formatDateYYMMDD = ( date: Date ): string => (
  `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
);

const formatDateDDMMYY = ( date: Date ): string => (
  `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
);

const formatTime = ( date: Date ): string => (
  `${date.getHours()}:${date.getMinutes()}`
);

const formatAmOrPm = ( date: Date ): string => (
  `${date.getHours() > 12 ? 'pm' : 'am'}`
);

const stringToTimeStamp = ( date: string ): TimeStamp => {
  let dateTimeStap = new Date( date );
  dateTimeStap = new Date(
    dateTimeStap.getFullYear(),
    dateTimeStap.getMonth(),
    dateTimeStap.getDate(),
    dateTimeStap.getHours(),
    dateTimeStap.getMinutes(),
  );
  return timeStamp.fromDate( dateTimeStap );
};

const timeStampToStringForm = ( seconds: number ): string => (
  formatDateYYMMDD( getDateWithSeconds( seconds ))
);

const timeStampToStringDateTime = ( seconds: number ): string => (
  `${formatDateYYMMDD( getDateWithSeconds( seconds ))} \
  ${formatTime( getDateWithSeconds( seconds ))}`
);

const timeStampToStringView = ( seconds: number ): string => (
  `${formatDateDDMMYY( getDateWithSeconds( seconds ))} \
  ${formatTime( getDateWithSeconds( seconds ))} \
  ${formatAmOrPm( getDateWithSeconds( seconds ))}`
);

export {
  stringToTimeStamp,
  timeStampToStringForm,
  timeStampToStringDateTime,
  timeStampToStringView,
  formatDateDDMMYY,
  formatAmOrPm,
  formatTime,
  getDateWithSeconds,
};
