import React, { useState, useEffect } from 'react';
import { ISubject } from '../../../../interfaces/ISubject';
import { useStyles } from './Styles';

interface IScheduleProps {
  // eslint-disable-next-line react/require-default-props
  subjects?: any;
}

interface IDays {
  end: string;
  start: string;
  day: number | string;
  subject: string;
}

const Schedule : React.FC<IScheduleProps> = ( props ) => {
  const { subjects } = props;
  const [firstHour, setFirstHour] = useState<IDays[]>([]);
  const [secondHour, setSecondHour] = useState<IDays[]>([]);
  const [thirdHour, setThirdHour] = useState<IDays[]>([]);
  const [fourthHour, setFourthHour] = useState<IDays[]>([]);
  const [fifthHour, setFifthHour] = useState<IDays[]>([]);
  const [sixthHour, setSixthHour] = useState<IDays[]>([]);
  const [seventhHour, setSeventhHour] = useState<IDays[]>([]);
  const [days] = useState<number[]>([1, 2, 3, 4, 5]);

  const classes = useStyles();

  const setInHours = (): void => {
    const first: IDays[] = [];
    const second: IDays[] = [];
    const third: IDays[] = [];
    const fourth: IDays[] = [];
    const fifth: IDays[] = [];
    const sixth: IDays[] = [];
    const seventh: IDays[] = [];
    const allHoursObject: IDays[] = [];
    const allHoursMiniObject: IDays[] = [];
    const allHours: IDays[] = [];
    // eslint-disable-next-line array-callback-return
    subjects.forEach(( subject: ISubject ) => {
      const object = { ...subject.schedule };
      Object.values( object ).forEach(( item: any, i: number ) => {
        const keys = Object.keys( object );
        const keySelected = keys[i];
        const itemWithSubjectName = {
          ...item,
          subject: subject.name,
          day: keySelected,
        };
        allHoursObject.push( itemWithSubjectName );
      });
    });
    Object.values( allHoursObject ).forEach(( item: any ) => {
      Object.values( item ).forEach(( hour: any ) => {
        allHoursMiniObject.push({
          ...hour,
          subject: item.subject,
          day: item.day,
        });
      });
    });
    Object.values( allHoursMiniObject ).forEach(( item: any ) => {
      allHours.push( item );
    });
    allHours.forEach(( item: IDays ) => {
      let itemWithDay: IDays = {
        day: 0,
        start: '',
        end: '',
        subject: '',
      };
      switch ( item.day ) {
        case 'monday':
          itemWithDay = { ...item, day: 1 };
          break;
        case 'tuesday':
          itemWithDay = { ...item, day: 2 };
          break;
        case 'wednesday':
          itemWithDay = { ...item, day: 3 };
          break;
        case 'thursday':
          itemWithDay = { ...item, day: 4 };
          break;
        case 'friday':
          itemWithDay = { ...item, day: 5 };
          break;
        default:
          break;
      }
      if ( item.start?.includes( '07:15' )) {
        first.push( itemWithDay );
      }
      if ( item.start?.includes( '07:55' )) {
        second.push( itemWithDay );
      }
      if ( item.start?.includes( '08:35' )) {
        third.push( itemWithDay );
      }
      if ( item.start?.includes( '09:35' )) {
        fourth.push( itemWithDay );
      }
      if ( item.start?.includes( '10:15' )) {
        fifth.push( itemWithDay );
      }
      if ( item.start?.includes( '11:15' )) {
        sixth.push( itemWithDay );
      }
      if ( item.start?.includes( '11:55' )) {
        seventh.push( itemWithDay );
      }
    });
    days.forEach(( day: any ) => {
      const resultFirst = first.find(( item: any ) => item.start.includes( '07:15' ) && item.end.includes( '07:55' ) && item.day === day );
      if ( !resultFirst && first.length < 5 ) {
        first.push({
          day,
          end: 'Thu Jul 15 2021 07:55:33 GMT-0500 (Ecuador Time)',
          start: 'Thu Jul 15 2021 07:15:21 GMT-0500 (Ecuador Time)',
          subject: 'N/A',
        });
      }
      const resultSecond = second.find(( item: any ) => item.start.includes( '07:55' ) && item.end.includes( '08:35' ) && item.day === day );
      if ( !resultSecond && second.length < 5 ) {
        second.push({
          day,
          end: 'Thu Jul 15 2021 08:35:33 GMT-0500 (Ecuador Time)',
          start: 'Thu Jul 15 2021 07:55:21 GMT-0500 (Ecuador Time)',
          subject: 'N/A',
        });
      }
      const resultThird = third.find(( item: any ) => item.start.includes( '08:35' ) && item.end.includes( '09:15' ) && item.day === day );
      if ( !resultThird && third.length < 5 ) {
        third.push({
          day,
          end: 'Thu Jul 15 2021 09:15:33 GMT-0500 (Ecuador Time)',
          start: 'Thu Jul 15 2021 08:35:21 GMT-0500 (Ecuador Time)',
          subject: 'N/A',
        });
      }
      const resultFourth = fourth.find(( item: any ) => item.start.includes( '09:35' ) && item.end.includes( '10:15' ) && item.day === day );
      if ( !resultFourth && fourth.length < 5 ) {
        fourth.push({
          day,
          end: 'Thu Jul 15 2021 10:15:33 GMT-0500 (Ecuador Time)',
          start: 'Thu Jul 15 2021 09:35:21 GMT-0500 (Ecuador Time)',
          subject: 'N/A',
        });
      }
      const resultFifth = fifth.find(( item: any ) => item.start.includes( '10:15' ) && item.end.includes( '10:55' ) && item.day === day );
      if ( !resultFifth && fifth.length < 5 ) {
        fifth.push({
          day,
          end: 'Thu Jul 15 2021 10:55:33 GMT-0500 (Ecuador Time)',
          start: 'Thu Jul 15 2021 10:15:21 GMT-0500 (Ecuador Time)',
          subject: 'N/A',
        });
      }
      const resultSixth = sixth.find(( item: any ) => item.start.includes( '11:15' ) && item.end.includes( '11:55' ) && item.day === day );
      if ( !resultSixth && sixth.length < 5 ) {
        sixth.push({
          day,
          end: 'Thu Jul 15 2021 11:55:33 GMT-0500 (Ecuador Time)',
          start: 'Thu Jul 15 2021 11:15:21 GMT-0500 (Ecuador Time)',
          subject: 'N/A',
        });
      }
      const resultSeventh = seventh.find(( item: any ) => item.start.includes( '11:55' ) && item.end.includes( '12:35' ) && item.day === day );
      if ( !resultSeventh && seventh.length < 5 ) {
        seventh.push({
          day,
          end: 'Thu Jul 15 2021 12:35:33 GMT-0500 (Ecuador Time)',
          start: 'Thu Jul 15 2021 11:55:21 GMT-0500 (Ecuador Time)',
          subject: 'N/A',
        });
      }
    });
    setFirstHour( first );
    setSecondHour( second );
    setThirdHour( third );
    setFourthHour( fourth );
    setFifthHour( fifth );
    setSixthHour( sixth );
    setSeventhHour( seventh );
  };

  useEffect(() => {
    setInHours();
  }, [props]);

  return (
    <table>
      <thead>
        <tr>
          <th scope="col" className={classes.daysWeak}>Hora</th>
          <th scope="col" className={classes.daysWeak}>L</th>
          <th scope="col" className={classes.daysWeak}>M</th>
          <th scope="col" className={classes.daysWeak}>M</th>
          <th scope="col" className={classes.daysWeak}>J</th>
          <th scope="col" className={classes.daysWeak}>V</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row" className={classes.hourWeak}>
            <div>
              <p className={classes.noMargin}>7:15</p>
              <p className={classes.noMargin}>7:55</p>
            </div>
          </th>
          {firstHour && (
            <>
              {firstHour.sort(( a: any, b:any ) => a.day - b.day )
                .map(( item: IDays, i: number ) => (
                  <td className={classes.daysStrong} key={i.toString()}>
                    {item.subject}
                  </td>
                ))}
            </>
          )}
        </tr>
        <tr>
          <th scope="row" className={classes.hourWeak}>
            <div>
              <p className={classes.noMargin}>7:55</p>
              <p className={classes.noMargin}>8:35</p>
            </div>
          </th>
          {secondHour && (
            <>
              {secondHour.sort(( a:any, b:any ) => a.day - b.day )
                .map(( item: IDays, i: number ) => (
                  <td className={classes.daysStrong} key={i.toString()}>
                    {item.subject}
                  </td>
                ))}
            </>
          )}
        </tr>
        <tr>
          <th scope="row" className={classes.hourWeak}>
            <div>
              <p className={classes.noMargin}>8:35</p>
              <p className={classes.noMargin}>9:15</p>
            </div>
          </th>
          {thirdHour && (
            <>
              {thirdHour.sort(( a:any, b:any ) => a.day - b.day )
                .map(( item: IDays, i: number ) => (
                  <td className={classes.daysStrong} key={i.toString()}>
                    {item.subject}
                  </td>
                ))}
            </>
          )}
        </tr>
        <tr>
          <th scope="row" className={classes.hourWeak}>
            <div>
              <p className={classes.noMargin}>9:15</p>
              <p className={classes.noMargin}>9:35</p>
            </div>
          </th>
          <td className={classes.break}>Receso</td>
          <td className={classes.break}>Receso</td>
          <td className={classes.break}>Receso</td>
          <td className={classes.break}>Receso</td>
          <td className={classes.break}>Receso</td>
        </tr>
        <tr>
          <th scope="row" className={classes.hourWeak}>
            <div>
              <p className={classes.noMargin}>9:35</p>
              <p className={classes.noMargin}>10:15</p>
            </div>
          </th>
          {fourthHour && (
            <>
              {fourthHour.sort(( a:any, b:any ) => a.day - b.day )
                .map(( item: IDays, i: number ) => (
                  <td className={classes.daysStrong} key={i.toString()}>
                    {item.subject}
                  </td>
                ))}
            </>
          )}
        </tr>
        <tr>
          <th scope="row" className={classes.hourWeak}>
            <div>
              <p className={classes.noMargin}>10:15</p>
              <p className={classes.noMargin}>10:55</p>
            </div>
          </th>
          {fifthHour && (
            <>
              {fifthHour.sort(( a:any, b:any ) => a.day - b.day )
                .map(( item: IDays, i: number ) => (
                  <td className={classes.daysStrong} key={i.toString()}>
                    {item.subject}
                  </td>
                ))}
            </>
          )}
        </tr>
        <tr>
          <th scope="row" className={classes.hourWeak}>
            <div>
              <p className={classes.noMargin}>10:55</p>
              <p className={classes.noMargin}>11:15</p>
            </div>
          </th>
          <td className={classes.break}>Receso</td>
          <td className={classes.break}>Receso</td>
          <td className={classes.break}>Receso</td>
          <td className={classes.break}>Receso</td>
          <td className={classes.break}>Receso</td>
        </tr>
        <tr>
          <th scope="row" className={classes.hourWeak}>
            <div>
              <p className={classes.noMargin}>11:15</p>
              <p className={classes.noMargin}>11:55</p>
            </div>
          </th>
          {sixthHour && (
            <>
              {sixthHour.sort(( a:any, b:any ) => a.day - b.day )
                .map(( item: IDays, i: number ) => (
                  <td className={classes.daysStrong} key={i.toString()}>
                    {item.subject}
                  </td>
                ))}
            </>
          )}
        </tr>
        <tr>
          <th scope="row" className={classes.hourWeak}>
            <div>
              <p className={classes.noMargin}>11:55</p>
              <p className={classes.noMargin}>12:35</p>
            </div>
          </th>
          {seventhHour && (
            <>
              {seventhHour.sort(( a:any, b:any ) => a.day - b.day )
                .map(( item: IDays, i: number ) => (
                  <td className={classes.daysStrong} key={i.toString()}>
                    {item.subject}
                  </td>
                ))}
            </>
          )}
        </tr>
      </tbody>
    </table>
  );
};

export default Schedule;
