import React, { useEffect, useState } from 'react';

// Material Ui
import {
  Box,
} from '@material-ui/core';

// Icons
import alertIconRed from '../../assets/svg/alertIconRed.svg';
import alertIconYellow from '../../assets/svg/alertIconYellow.svg';
import alertIconGreen from '../../assets/svg/alertIconGreen.svg';
import ToolTip from '../ToolTip/ToolTip';

interface ISubjectTaskStatusArrayProps {
  tasks: any;
  // eslint-disable-next-line react/require-default-props
  iconClasses?: any;
  // eslint-disable-next-line react/require-default-props
  iconTextClasses?: any;
}

const SubjectTaskStatusArray: React.FC<ISubjectTaskStatusArrayProps> = ( props ): any => {
  const {
    tasks,
    iconClasses,
    iconTextClasses,
  } = props;

  const [totalCompleted, setTotalCompleted] = useState( 0 );
  const [totaluncompleted, setUncotalCompleted] = useState( 0 );
  const [totalLost, setTotalost] = useState( 0 );

  useEffect(() => {
    let allCompletedLosted = 0;
    let allCompleted = 0;
    if ( tasks.length > 0 ) {
      const notes: any[] = tasks.filter(
        ( item: any ) => item.students?.length >= 1,
      );
      const noNotes: any[] = tasks.filter(
        ( item: any ) => item.students?.length <= 0,
      );
      notes.forEach(( task: any ) => {
        if ( new Date( task.notes[0]?.fecha.toDate()) > new Date( task.dateEnd.toDate())) {
          allCompletedLosted += 1;
        }
        if ( new Date( task.notes[0]?.fecha.toDate()) <= new Date( task.dateEnd.toDate())) {
          allCompleted += 1;
        }
      });
      setTotalCompleted( allCompleted );
      setUncotalCompleted( noNotes.length );
      setTotalost( allCompletedLosted );
    } else {
      setTotalost( 0 );
      setUncotalCompleted( 0 );
      setTotalCompleted( 0 );
    }
  }, [props]);

  return (
    <>
      <ToolTip title="Completados con retraso">
        <Box display="block" textAlign="center" ml={1} mr={1}>
          <img className={iconClasses} src={alertIconRed} alt="" />
          <p className={iconTextClasses}>{totalLost}</p>
        </Box>
      </ToolTip>
      <ToolTip title="No completados">
        <Box display="block" textAlign="center" ml={1} mr={1}>
          <img className={iconClasses} src={alertIconYellow} alt="" />
          <p className={iconTextClasses}>{totaluncompleted}</p>
        </Box>
      </ToolTip>
      <ToolTip title="Completados">
        <Box display="block" textAlign="center" ml={1} mr={1}>
          <img className={iconClasses} src={alertIconGreen} alt="" />
          <p className={iconTextClasses}>{totalCompleted}</p>
        </Box>
      </ToolTip>
    </>
  );
};

export default SubjectTaskStatusArray;
