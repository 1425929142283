import React from 'react';
import {
  reduxForm, InjectedFormProps, Field, formValueSelector,
} from 'redux-form';
import { Grid, MenuItem } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { required } from '../../helpers/validate-forms';
import Input from '../Input/Input';
import Button from '../Button/Button';
import CenterLine from '../Center/CenterInLine';
import ContainerForm from '../ContainerForm/ContainerForm';
import FormRedux from '../FormRedux/FormRedux';
import ContainerInputs from '../ContainerInputs/ContainerInputs';
import { RootState } from '../../redux/store';
import Select from '../Select/Select';
import InputDateTime from '../InputDateTime/InputDateTime';
import { IBook } from '../../interfaces/IBook';
import { IUnit } from '../../interfaces/IUnit';

const TaskForm: React.FC<InjectedFormProps> = ( props ) => {
  const selector = formValueSelector( 'task' );
  const { formData: subjectData } = useSelector(
    ( state: RootState ) => state.subject,
  );
  const bookId = useSelector(
    ( state: RootState ) => selector( state, 'bookId' ),
  );
  const { booksCourse: books } = useSelector(
    ( state: RootState ) => state.book,
  );
  const { formData } = useSelector(( state: RootState ) => state.institute );
  const { units } = useSelector(( state: RootState ) => state.unit );
  const {
    handleSubmit, submitting,
  } = props;

  return (
    <ContainerForm>
      <FormRedux onSubmit={handleSubmit}>
        <ContainerInputs>
          <Grid item xs={12} sm={6}>
            <Field
              name="title"
              type="text"
              label="Titulo"
              component={Input}
              placeholder="Titulo"
              validate={[required]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="description"
              type="text"
              label="Descripción"
              component={Input}
              placeholder="Descripción"
              validate={[required]}
              multiline
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="dateStart"
              type="datetime"
              label="Fecha de inicio"
              component={InputDateTime}
              placeholder="Fecha de inicio"
              validate={[required]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="dateEnd"
              type="datetime"
              label="Fecha de fin"
              component={InputDateTime}
              placeholder="Fecha de fin"
              validate={[required]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="partial"
              component={Select}
              validate={[required]}
              label="Parcial"
            >
              {formData
                  && formData.partial.map(( item: any, index: number ) => (
                    <MenuItem
                      key={index.toString()}
                      value={`${item.name}`}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
            </Field>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="bookId"
              component={Select}
              // validate={[required]}
              label="Libro"
            >
              {subjectData.books
              && books.map(( item: IBook, index: number ) => (
                subjectData.books.includes( item.id ) && (
                  <MenuItem
                    key={index.toString()}
                    value={`${item.id}`}
                  >
                    {item.name}
                  </MenuItem>
                )
              ))}
            </Field>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="unitId"
              component={Select}
              // validate={[required]}
              label="Unidad"
            >
              {units.map(( item: IUnit, index: number ) => (
                bookId === item.bookId && (
                  <MenuItem
                    key={index.toString()}
                    value={`${item.id}`}
                  >
                    {item.name}
                  </MenuItem>
                )
              ))}
            </Field>
          </Grid>
          <Grid item xs={12} sm={12}>
            <CenterLine>
              <Button type="submit" disabled={submitting}>
                Enviar
              </Button>
            </CenterLine>
          </Grid>
        </ContainerInputs>
      </FormRedux>
    </ContainerForm>
  );
};

export default reduxForm({
  form: 'task',
})( TaskForm );
