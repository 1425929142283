import React from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import SubjectForm from '../../../components/SubjectForm/SubjectForm';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import { ISubject } from '../../../interfaces/ISubject';
import { createSubjectThunk } from '../../../redux/ducks/SubjectDucks';
import Card from '../../../components/Card/Card';

const SubjectCreate = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handlerSubmit = ( values: any ): void => {
    const subject = values as ISubject;
    dispatch( createSubjectThunk( subject ));
    history.push( '/admin/materias' );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <ContainerBreadcrumbs>
            <Breadcrumbs
              itemsLinks={[{ name: 'Materias', url: 'admin/materias' }]}
              itemsText={['Crear una nueva Materia']}
            />
          </ContainerBreadcrumbs>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <SubjectForm onSubmit={handlerSubmit} />
        </Card>
      </Grid>
    </Grid>
  );
};

export default SubjectCreate;
