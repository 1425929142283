import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(( theme: Theme ) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    columnGap: 20,
    rowGap: 10,
    flexWrap: 'wrap',
  },
  card: {
    borderRadius: 5,
  },
  student: {
    flex: 1,
    // cursor: 'pointer',
    padding: '10px 50px 10px 20px',
  },
  calification: {
    padding: 10,
    flex: 0.3,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 100,
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
  },
  containerIcon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: 5,
  },
  icon: {
    width: 25,
    height: 25,
  },
  avatar: {
    width: 40,
    height: 40,
  },
  titleCal: {
    fontWeight: 600,
    color: '#149E3D',
    fontSize: 25,
  },
  progress: {
    height: 35,
    width: 35,
  },
  iconCheck: {
    color: theme.palette.success.main,
  },
}));
