import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// Interface
import { useDispatch, useSelector } from 'react-redux';
// Redux
import {
  Grid,
} from '@material-ui/core';
import { useParams } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import {
  actions as actionsTask,
  getAllNotesByTaskIdAndAddStudentsThunk,
} from '../../../../redux/ducks/TaskDucks';
// Components
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import ContainerBreadcrumbs from '../../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import { RootState } from '../../../../redux/store';
import Card from '../../../../components/Card/Card';
import TaskRateInfo from '../../../../components/TaskRateInfo/TaskRateInfo';
import TaskRateGrade from '../../../../components/TaskRateGrade/TaskRateGrade';
import MessageCard from '../../../../components/MessageCard/MessageCard';

interface Params {
  id: string;
}

const useStyles = makeStyles(() => ({
  card: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
}));

const Rate: React.FC = () => {
  const classes = useStyles();
  const params = useParams<Params>();
  const dispatch = useDispatch();
  const history = useHistory();
  const subject = useSelector(( state: RootState ) => state.subject.formData );
  const task = useSelector(( state: RootState ) => state.task.formData );

  useEffect(() => {
    dispatch( actionsTask.setInicitalFormTask());
    dispatch( getAllNotesByTaskIdAndAddStudentsThunk( params.id ));
  }, []);

  const handleQualify = ( idStudent: string ): void => {
    // /docente/tareas/calificaciones/:taskId/:studentId
    history.push( `/docente/tareas/calificaciones/estudiante/${idStudent}` );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <div className={classes.card}>
            <ContainerBreadcrumbs>
              <Breadcrumbs
                itemsLinks={[
                  {
                    name: subject.name,
                    url: `docente/materias/detalle/${subject.id}`,
                  }]}
                itemsText={['Calificado', 'Calificando tarea']}
              />
            </ContainerBreadcrumbs>
          </div>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <TaskRateInfo task={task && task} />
      </Grid>
      {task && task.notes && task.notes.length > 0 ? (
        task.notes.map(( item: any, i: number ) => (
          <Grid item xs={12} key={i.toString()}>
            <TaskRateGrade
              note={item}
              onQualify={() => handleQualify( item.idStudent )}
            />
          </Grid>
        ))
      ) : (
        <Grid item xs={12}>
          <MessageCard>
            <h3>Ningún estudiante ha terminado la tarea</h3>
          </MessageCard>
        </Grid>
      )}
    </Grid>
  );
};

export default Rate;
