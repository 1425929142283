import React, { useState, useEffect } from 'react';
import { ICourse } from '../../../../interfaces/ICourse';

import { getCourseById } from '../../../../services/Course.Services';

interface ICourseIdProps{
  courseId: any;
}

const CourseName: React.FC<ICourseIdProps> = ( props ) => {
  const { courseId } = props;

  const [course, setCourse] = useState<ICourse>();
  const handleGetCourseById = async (): Promise<void> => {
    const resCourse = await getCourseById( courseId );
    setCourse( resCourse );
  };

  useEffect(() => {
    let isSuscribed = false;
    if ( !isSuscribed ) {
      handleGetCourseById();
    }
    return () => {
      isSuscribed = true;
    };
  }, [props]);

  return (
    <span>{course ? course.name : '..........'}</span>
  );
};

export default CourseName;
