import { ThunkAction } from 'redux-thunk';
import { Dispatch, AnyAction } from 'redux';
import { RootState } from '../store';
import { db } from '../../services/firebase';
import { IInstitute } from '../../interfaces/IInstitute';

export const actionType = {
  GET_INSTITUTE_REQUEST: 'GET_INSTITUTE_REQUEST',
  GET_INSTITUTE_SUCCESS: 'GET_INSTITUTE_SUCCESS',
  GET_INSTITUTE_ERROR: 'GET_INSTITUTE_ERROR',
};

export interface IInitialStateInstitute {
  loading: boolean;
  errors: string;
  formData?: any;
}

const initialState: IInitialStateInstitute = {
  loading: false,
  errors: '',
  formData: '',
};

export default function instituteReducer(
  state = initialState, action: AnyAction,
) : any {
  const { type, payload } = action;

  switch ( type ) {
    case actionType.GET_INSTITUTE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.GET_INSTITUTE_SUCCESS:
      return {
        ...state,
        loading: false,
        formData: payload,
      };
    case actionType.GET_INSTITUTE_ERROR:
      return {
        ...state,
        loading: false,
        errors: payload,
      };
    default:
      return state;
  }
}

export const actions = {
  getInstituteRequest: (): AnyAction => ({
    type: actionType.GET_INSTITUTE_REQUEST,
  }),
  getInstituteSuccess: ( payload: IInstitute ): AnyAction => ({
    type: actionType.GET_INSTITUTE_SUCCESS,
    payload,
  }),
  getInstituteError: ( payload: string ): AnyAction => ({
    type: actionType.GET_INSTITUTE_ERROR,
    payload,
  }),
};

export const getInstituteThunk = ():
ThunkAction<
void, RootState, null, AnyAction
> => async ( dispatch: Dispatch ) => {
  dispatch( actions.getInstituteRequest());
  try {
    const result = await db.collection( 'institute' ).get();
    if ( !result.empty ) {
      const data: IInstitute[] = [];
      result.docs.forEach(( doc: any ) => {
        data.push( doc.data());
      });
      dispatch( actions.getInstituteSuccess( data[0]));
    }
  } catch ( error ) {
    dispatch( actions.getInstituteError(
      'No se pudo obtener los datos del intituto.',
    ));
  }
};
