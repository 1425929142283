import React from 'react';
import { Paper } from '@material-ui/core';
import useStyles from './Styles';
import Image from '../../../../../../components/Image/Image';

interface IProps {
  title: string;
  subtitle: string;
  onClick: any;
  image: any;
}

const ItemDialog: React.FC<IProps> = ( props ) => {
  const classes = useStyles();
  const {
    title,
    subtitle,
    onClick,
    image,
  } = props;

  const handleClick = (): void => {
    onClick();
  };

  return (
    <Paper
      className={classes.root}
      onClick={handleClick}
      elevation={5}
    >
      <div className={classes.containerIcon}>
        <Image src={image} />
      </div>
      <span className={classes.title}>{title}</span>
      <span className={classes.subtitle}>{subtitle}</span>
    </Paper>
  );
};

export default ItemDialog;
