import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { toast } from 'react-toastify';
import { IActivity } from '../../interfaces/IActivity';
import { RootState } from '../store';
import { auth, db, storage } from '../../services/firebase';
import { api } from '../../services/api-functions';
import { IQuestion } from '../../interfaces/IQuestion';

const COLLECTION_ACTIVITY = 'activities';
// action types
export const actionType = {
  GET_ACTIVITIES_FROM_DB: 'GET_ACTIVITIES_FROM_DB',
  SET_CREATE_ACTIVITY_SUCCESS: 'SET_CREATE_ACTIVITY_SUCCESS',
  SET_DELETE_ACTIVITY_SUCCESS: 'SET_DELETE_ACTIVITY_SUCCESS',
  SET_ACTIVITY_TO_EDIT_SUCCESS: 'SET_ACTIVITY_TO_EDIT_SUCCESS',
  SET_UPDATE_ACTIVITY_SUCCESS: 'SET_UPDATE_ACTIVITY_SUCCESS',
  SET_LOADING_ACTIVITY: 'SET_LOADING_ACTIVITY',
  CREATE_ACTIVITY_ERROR: 'CREATE_ACTIVITY_ERROR',
  ADD_QUESTION_REQUEST: 'ADD_QUESTION_REQUEST',
  ADD_QUESTION_SUCCESS: 'ADD_QUESTION_SUCCESS',
  ADD_QUESTION_ERROR: 'ADD_QUESTION_ERROR',
  GET_QUESTION_REQUEST: 'GET_QUESTION_REQUEST',
  GET_QUESTION_SUCCESS: 'GET_QUESTION_SUCCESS',
  GET_QUESTION_ERROR: 'GET_QUESTION_ERROR',
  UPDATE_QUESTION_REQUEST: 'UPDATE_QUESTION_REQUEST',
  UPDATE_QUESTION_SUCCESS: 'UPDATE_QUESTION_SUCCESS',
  UPDATE_QUESTION_ERROR: 'UPDATE_QUESTION_ERROR',
  CLEAR_FORMDATA: 'CLEAR_FORMDATA',
  UPDATE_ACTIVITY_REQUEST: 'UPDATE_ACTIVITY_REQUEST',
  UPDATE_ACTIVITY_SUCCESS: 'UPDATE_ACTIVITY_SUCCESS',
  UPDATE_ACTIVITY_ERROR: 'UPDATE_ACTIVITY_ERROR',
  SET_ACTIVITIES_FROM_DB: 'SET_ACTIVITIES_FROM_DB',
  UPLOAD_ANSWER_ACTIVITY_REQUEST: 'UPLOAD_ANSWER_ACTIVITY_REQUEST',
  UPLOAD_ANSWER_ACTIVITY_SUCCESS: 'UPLOAD_ANSWER_ACTIVITY_SUCCESS',
  UPLOAD_ANSWER_ACTIVITY_ERROR: 'UPLOAD_ANSWER_ACTIVITY_ERROR',
  GET_ACTIVITY_BY_TASK_REQUEST: 'GET_ACTIVITY_BY_TASK_REQUEST',
  GET_ACTIVITY_BY_TASK_SUCCESS: 'GET_ACTIVITY_BY_TASK_SUCCESS',
  GET_ACTIVITY_BY_TASK_ERROR: 'GET_ACTIVITY_BY_TASK_ERROR',
  SET_ACTIVITIES_BOOK_FROM_DB: 'SET_ACTIVITIES_BOOK_FROM_DB',
  GET_ACTIVITY_BY_BOOK: 'GET_ACTIVITY_BY_BOOK',
};

// Interfaces
export interface ActivityState {
  activities: IActivity[] | null;
  activityById: null;
}

export interface setGetActivitiesSuccess {
  type: typeof actionType.GET_ACTIVITIES_FROM_DB;
  payload: IActivity[];
}

export interface setCreateActivitiesSuccess {
  type: typeof actionType.SET_CREATE_ACTIVITY_SUCCESS;
}

export interface setDeleteActivitySuccess {
  type: typeof actionType.SET_DELETE_ACTIVITY_SUCCESS;
}

export interface setActivityToEditSuccess {
  type: typeof actionType.SET_ACTIVITY_TO_EDIT_SUCCESS;
  payload: IActivity;
}

export interface setCourseUpdateSuccess {
  type: typeof actionType.SET_UPDATE_ACTIVITY_SUCCESS;
}

export interface setLoadingActivity {
  type: typeof actionType.SET_LOADING_ACTIVITY;
  payload: boolean;
}

// Thunk Types
type ActivityActions =
  | setGetActivitiesSuccess
  | setCreateActivitiesSuccess
  | setDeleteActivitySuccess
  | setActivityToEditSuccess
  | setLoadingActivity
  | setCourseUpdateSuccess;

// initial state
const initialState = {
  activities: [],
  activitiesBook: [],
  activityById: {
    id: null,
    name: null,
    maxGrade: null,
    start: null,
    finish: null,
    creation: null,
    embedUrl: null,
    idTeacher: null,
    deleted: null,
  },
  loading: false,
  loadingSub: false,
  questions: [],
  idActivity: '',
  loadingAnswer: false,
  urlActNormalTemp: '',
};

// reducer
export default function activityReducer(
  state = initialState, action: AnyAction,
) : any {
  const { type, payload } = action;

  switch ( type ) {
    case actionType.GET_ACTIVITIES_FROM_DB:
      return {
        ...state,
        activitiesBook: payload,
      };
    case actionType.SET_ACTIVITY_TO_EDIT_SUCCESS:
      return {
        ...state,
        activityById: payload,
      };
    case actionType.SET_LOADING_ACTIVITY:
      return {
        ...state,
        loading: payload,
      };
    case actionType.SET_CREATE_ACTIVITY_SUCCESS:
      return {
        ...state,
        idActivity: payload,
      };
    case actionType.SET_DELETE_ACTIVITY_SUCCESS:
      return state;
    case actionType.ADD_QUESTION_REQUEST:
      return {
        ...state,
        loadingSub: true,
      };
    case actionType.ADD_QUESTION_SUCCESS:
      return {
        ...state,
        loadingSub: false,
      };
    case actionType.ADD_QUESTION_ERROR:
      return {
        ...state,
        loadingSub: false,
        errors: payload,
      };
    case actionType.GET_QUESTION_REQUEST:
      return {
        ...state,
        loadingSub: true,
      };
    case actionType.GET_QUESTION_SUCCESS:
      return {
        ...state,
        loadingSub: false,
        questions: payload,
      };
    case actionType.GET_QUESTION_ERROR:
      return {
        ...state,
        loadingSub: false,
        errors: payload,
      };
    case actionType.UPDATE_QUESTION_REQUEST:
      return {
        ...state,
        loadingSub: true,
      };
    case actionType.UPDATE_QUESTION_SUCCESS:
      return {
        ...state,
        loadingSub: false,
      };
    case actionType.UPDATE_QUESTION_ERROR:
      return {
        ...state,
        loadingSub: false,
        errors: payload,
      };
    case actionType.CLEAR_FORMDATA:
      return {
        ...state,
        idActivity: '',
      };
    case actionType.UPDATE_ACTIVITY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.UPDATE_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionType.UPDATE_ACTIVITY_ERROR:
      return {
        ...state,
        loading: false,
        errors: payload,
      };
    case actionType.SET_ACTIVITIES_FROM_DB:
      return {
        ...state,
        activities: payload,
      };
    case actionType.UPLOAD_ANSWER_ACTIVITY_REQUEST:
      return {
        ...state,
        loadingAnswer: true,
      };
    case actionType.UPLOAD_ANSWER_ACTIVITY_SUCCESS:
      // eslint-disable-next-line no-console
      console.log( `Ok${payload}` );
      return {
        ...state,
        loadingAnswer: false,
        urlActNormalTemp: payload,
      };
    case actionType.UPLOAD_ANSWER_ACTIVITY_ERROR:
      return {
        ...state,
        loadingAnswer: false,
        errors: payload,
      };
    case actionType.GET_ACTIVITY_BY_TASK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.GET_ACTIVITY_BY_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
        activities: payload,
      };
    case actionType.GET_ACTIVITY_BY_TASK_ERROR:
      return {
        ...state,
        loading: false,
        errors: payload,
      };
    case actionType.SET_ACTIVITIES_BOOK_FROM_DB:
      return {
        ...state,
        activitiesBook: payload,
      };
    default:
      return state;
  }
}

// actions creators
export const actions = {
  addQuestionRequest: (): AnyAction => ({
    type: actionType.ADD_QUESTION_REQUEST,
  }),
  addQuestionSuccess: (): AnyAction => ({
    type: actionType.ADD_QUESTION_SUCCESS,
  }),
  addQuestionError: ( payload: string ): AnyAction => ({
    type: actionType.ADD_QUESTION_ERROR,
    payload,
  }),
  getQuestionRequest: (): AnyAction => ({
    type: actionType.GET_QUESTION_REQUEST,
  }),
  getQuestionSuccess: ( payload: any[]): AnyAction => ({
    type: actionType.GET_QUESTION_SUCCESS,
    payload,
  }),
  getQuestionError: ( payload: string ): AnyAction => ({
    type: actionType.GET_QUESTION_ERROR,
    payload,
  }),
  updateQuestionRequest: (): AnyAction => ({
    type: actionType.UPDATE_QUESTION_REQUEST,
  }),
  updateQuestionSuccess: (): AnyAction => ({
    type: actionType.UPDATE_QUESTION_SUCCESS,
  }),
  updateQuestionError: ( payload: string ): AnyAction => ({
    type: actionType.UPDATE_QUESTION_ERROR,
    payload,
  }),
  clearFormData: (): AnyAction => ({
    type: actionType.CLEAR_FORMDATA,
  }),
  createActivityError: ( payload: string ): AnyAction => ({
    type: actionType.CREATE_ACTIVITY_ERROR,
    payload,
  }),
  updateActivityRequest: (): AnyAction => ({
    type: actionType.UPDATE_ACTIVITY_REQUEST,
  }),
  updateActivitySuccess: (): AnyAction => ({
    type: actionType.UPDATE_ACTIVITY_SUCCESS,
  }),
  updateActivityError: ( payload: string ): AnyAction => ({
    type: actionType.UPDATE_ACTIVITY_ERROR,
    payload,
  }),
  setActivities: ( payload: any[]): AnyAction => ({
    type: actionType.SET_ACTIVITIES_FROM_DB,
    payload,
  }),
  setActivitiesBook: ( payload: any[]): AnyAction => ({
    type: actionType.SET_ACTIVITIES_BOOK_FROM_DB,
    payload,
  }),
  uploadAnswerActivityRequest: (): AnyAction => ({
    type: actionType.UPLOAD_ANSWER_ACTIVITY_REQUEST,
  }),
  uploadAnswerActivitySuccess: ( payload: string ): AnyAction => ({
    type: actionType.UPLOAD_ANSWER_ACTIVITY_SUCCESS,
    payload,
  }),
  uploadAnswerActivityError: ( payload: string ): AnyAction => ({
    type: actionType.UPLOAD_ANSWER_ACTIVITY_ERROR,
    payload,
  }),
  getActivitiesByTaskRequest: (): AnyAction => ({
    type: actionType.GET_ACTIVITY_BY_TASK_REQUEST,
  }),
  getActivitiesByTaskSuccess: ( payload: IActivity[]): AnyAction => ({
    type: actionType.GET_ACTIVITY_BY_TASK_SUCCESS,
    payload,
  }),
  getActivitiesByTaskError: ( payload: string ): AnyAction => ({
    type: actionType.GET_ACTIVITY_BY_TASK_ERROR,
    payload,
  }),
  getActivities: (): ThunkAction<
  void, RootState, null, ActivityActions> => async ( dispatch ) => {
    try {
      db.collection( COLLECTION_ACTIVITY )
        .onSnapshot(( querySnapshot ) => {
          const activities: any[] = [];
          querySnapshot.forEach(( activity ) => {
            const data = activity.data() as IActivity;
            if ( !data.deleted && data.isBook ) {
              activities.push({ ...data, id: activity.id });
            }
          });
          const { size } = querySnapshot;
          // eslint-disable-next-line no-console
          console.log( `Hay ${size}` );
          dispatch({
            type: actionType.GET_ACTIVITIES_FROM_DB,
            payload: activities,
          });
        });
    } catch ( err ) {
      // console.error( err );
    }
  },
  getActivity: ( id: string ): ThunkAction<
  void, RootState, null, ActivityActions> => async ( dispatch ) => {
    try {
      dispatch( actions.setLoadingActivity( true ));
      const collection = await db.collection( COLLECTION_ACTIVITY )
        .doc( id ).get();
      if ( collection.exists ) {
        const data = collection.data() as IActivity;
        const activity = { ...data, id };
        dispatch({
          type: actionType.SET_ACTIVITY_TO_EDIT_SUCCESS,
          payload: activity,
        });
      }
      dispatch( actions.setLoadingActivity( false ));
    } catch ( err ) {
      // console.error( err );
    }
  },
  createActivity: ( data: any, guideDocument: File ): ThunkAction<
  void, RootState, null, ActivityActions> => async ( dispatch ) => {
    if ( !auth.currentUser ) {
      return;
    }
    const dataToSave: IActivity = {
      idTeacher: auth.currentUser.uid,
      maxGrade: parseInt( data.maxGrade, 10 ),
      creation: new Date(),
      subtype: data.subtype || '',
      ...data,
    };
    try {
      const activity = await db.collection( COLLECTION_ACTIVITY )
        .add( dataToSave );
      dispatch(
        actions.updateActivity(
          activity.id, dataToSave, guideDocument,
        ),
      );
      dispatch({
        type: actionType.SET_CREATE_ACTIVITY_SUCCESS,
        payload: activity.id,
      });
      toast.success( 'Nueva actividad agregada.' );
    } catch ( err ) {
      // console.error( err );
    }
  },
  updateActivity: (
    id: string,
    data: IActivity,
    guideDocument?: File,
  ): ThunkAction<
  void, RootState, null, ActivityActions> => async ( dispatch ) => {
    try {
      if ( guideDocument ) {
        const storageRef = storage.ref();
        const response = await storageRef
          .child( `guides` )
          .child( `${id}.docx` )
          .put( guideDocument );
        const guideUrl = await response.ref.getDownloadURL();
        Object.assign( data, { guideUrl });
      } else {
        Object.assign( data );
      }
      await db.collection( COLLECTION_ACTIVITY )
        .doc( id ).update( data );
      dispatch({
        type: actionType.SET_UPDATE_ACTIVITY_SUCCESS,
      });
      toast.success( 'Actividad editada correctamente.' );
    } catch ( err ) {
      // console.error( err );
    }
  },
  deleteActivity: ( id: string ): ThunkAction<
  void, RootState, null, ActivityActions> => async ( dispatch ) => {
    try {
      await db
        .collection( COLLECTION_ACTIVITY )
        .doc( id )
        .update({ deleted: true })
        .then(() => {
          dispatch({
            type: actionType.SET_DELETE_ACTIVITY_SUCCESS,
          });
        });
      toast.success( 'Actividad eliminada correctamente.' );
    } catch ( err ) {
      // console.error( err );
    }
  },
  setLoadingActivity: ( state: boolean ): ThunkAction<
  void, RootState, null, ActivityActions> => async ( dispatch ) => {
    dispatch({
      type: actionType.SET_LOADING_ACTIVITY,
      payload: state,
    });
  },
  createQuestionThunk: ( id: string, data: IQuestion ):
  ThunkAction<
  void, RootState, null, AnyAction> => async ( dispatch ) => {
    dispatch( actions.addQuestionRequest());
    try {
      await db.collection( COLLECTION_ACTIVITY )
        .doc( id )
        .collection( 'questions' )
        .add( data );
      dispatch( actions.addQuestionSuccess());
      dispatch( actions.getQuestionsThunk( id, data.type ));
    } catch ( error ) {
      dispatch(
        actions.addQuestionError(
          'No se pudo crear la nueva pregunta',
        ),
      );
    }
  },
  getQuestionsThunk: ( id: string, type: string ): ThunkAction<
  void, RootState, null, ActivityActions> => async ( dispatch ) => {
    try {
      dispatch( actions.getQuestionRequest());
      const questions = await db.collection( COLLECTION_ACTIVITY )
        .doc( id )
        .collection( 'questions' )
        .where( 'type', '==', type )
        .get();
      if ( questions.docs.length > 0 ) {
        const data: any[] = [];
        questions.docs.forEach(( doc ) => {
          data.push({ ...doc.data(), id: doc.id });
        });
        dispatch( actions.getQuestionSuccess( data ));
      } else {
        dispatch( actions.getQuestionSuccess([]));
      }
    } catch ( err ) {
      dispatch( actions.getQuestionError( 'Error al obtener las preguntas' ));
    }
  },
  updateQuestionsThunk: ( idDoc: string, dataSub: IQuestion ): ThunkAction<
  void, RootState, null, ActivityActions> => async ( dispatch ) => {
    try {
      dispatch( actions.updateQuestionRequest());
      await db.collection( COLLECTION_ACTIVITY )
        .doc( idDoc )
        .collection( 'questions' )
        .doc( dataSub.id )
        .update( dataSub );
      dispatch( actions.updateQuestionSuccess());
      dispatch( actions.getQuestionsThunk( idDoc, dataSub.type ));
    } catch ( err ) {
      dispatch(
        actions.updateQuestionError( 'Error al actualizar la pregunta' ),
      );
    }
  },
  deleteQuestionThunk: (
    idDoc: string, idSubDoc: string, type: string,
  ): ThunkAction<
  void, RootState, null, ActivityActions> => async ( dispatch ) => {
    try {
      dispatch( actions.updateQuestionRequest());
      await db.collection( COLLECTION_ACTIVITY )
        .doc( idDoc )
        .collection( 'questions' )
        .doc( idSubDoc )
        .delete();
      dispatch( actions.updateQuestionSuccess());
      dispatch( actions.getQuestionsThunk( idDoc, type ));
    } catch ( err ) {
      dispatch(
        actions.updateQuestionError( 'Error al elimiar la pregunta' ),
      );
    }
  },
  addTaskIdThunk: (
    activities: string[], idTask: string, add: boolean,
  ): ThunkAction<
  void, RootState, null, ActivityActions> => async ( dispatch ) => {
    dispatch( actions.getQuestionRequest());
    try {
      await api.post(
        '/api/update-tareas-in-activity', {
          activities, idTask, add,
        },
      );
      dispatch( actions.updateActivitySuccess());
    } catch ( err ) {
      dispatch(
        actions.updateActivityError( 'Error al añadir el id de tarea' ),
      );
    }
  },
  uploadAnswerFileThunk: (
    idAct: string, file: File,
  ): ThunkAction<
  void, RootState, null, ActivityActions> => async ( dispatch ) => {
    dispatch( actions.uploadAnswerActivityRequest());
    try {
      if ( file ) {
        // eslint-disable-next-line no-console
        console.log( idAct );
        const storageRef = storage.ref();
        const response = await storageRef
          .child( `guides` )
          .child( `${idAct}-answer.docx` )
          .put( file );
        const url = await response.ref.getDownloadURL();
        // eslint-disable-next-line no-console
        console.log( url );
        dispatch( actions.uploadAnswerActivitySuccess( url ));
        return;
      }
      // eslint-disable-next-line no-console
      console.log( 'salio' );
      dispatch(
        actions.uploadAnswerActivityError(
          'No se pudo subir el archivo',
        ),
      );
    } catch ( err ) {
      // eslint-disable-next-line no-console
      console.log( 'error' );
      dispatch(
        actions.uploadAnswerActivityError(
          'Error al subir el archivo de respuesta',
        ),
      );
    }
  },
  getActivitiesByTask: ( taskId: string ): ThunkAction<
  void, RootState, null, ActivityActions> => async ( dispatch ) => {
    dispatch( actions.getActivitiesByTaskRequest());
    try {
      const data = await db.collection( COLLECTION_ACTIVITY )
        .where( 'taskId', '==', taskId )
        .orderBy( 'name', 'asc' )
        .get();
      const activities: IActivity[] = [];
      if ( !data.empty ) {
        const { docs } = data;
        docs.forEach(( doc ) => {
          const item = doc.data() as IActivity;
          item.id = doc.id;
          if ( !item.deleted ) {
            activities.push({ ...item });
          }
        });
        dispatch( actions.getActivitiesByTaskSuccess( activities ));
      } else {
        dispatch( actions.getActivitiesByTaskSuccess( activities ));
      }
    } catch ( err ) {
      // eslint-disable-next-line no-console
      console.log( err );
      dispatch( actions.getActivitiesByTaskError(
        'Ocurrio un error al obtener las actividades.',
      ));
    }
  },
  getActivitiesByBookUnit: ( unitId: string ): ThunkAction<
  void, RootState, null, ActivityActions> => async ( dispatch ) => {
    dispatch( actions.getActivitiesByTaskRequest());
    try {
      const data = await db.collection( COLLECTION_ACTIVITY )
        .where( 'unitId', '==', unitId )
        // .orderBy( 'name', 'desc' )
        .get();
      const activities: IActivity[] = [];
      if ( !data.empty ) {
        const { docs } = data;
        docs.forEach(( doc ) => {
          const item = doc.data() as IActivity;
          item.id = doc.id;
          if ( !item.deleted ) {
            activities.push({ ...item });
          }
        });
        dispatch({
          type: actionType.GET_ACTIVITIES_FROM_DB,
          payload: activities,
        });
      } else {
        dispatch({
          type: actionType.GET_ACTIVITIES_FROM_DB,
          payload: activities,
        });
      }
    } catch ( err ) {
      // eslint-disable-next-line no-console
      console.log( err );
    }
  },
};
