import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { formValueSelector, InjectedFormProps } from 'redux-form';
import { RootState } from '../../../redux/store';

// components
import ActivityForm, {
  subTypes,
} from '../../../components/ActivityForm/ActivityForm';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { actions } from '../../../redux/ducks/ActivityDucks';
// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import Spinner from '../../../components/Spinner/Spinner';
import { IActivity } from '../../../interfaces/IActivity';
// eslint-disable-next-line max-len
import ListQuestions from '../../../components/ListQuestionsIntegrate/Complete/ListQuestions';
import LabelInfo from '../../../components/LabelInfo/LabelInfo';
import DialogConfirm from '../../../components/DialogConfirm/DialogConfirm';
// eslint-disable-next-line max-len
import ListQuestionsDnD from '../../../components/ListQuestionsIntegrate/DragAndDrop/ListQuestionsDnD';
import { IQuestion } from '../../../interfaces/IQuestion';
import Card from '../../../components/Card/Card';
import { getRoute } from '../../../helpers/get-user-by-type';
// eslint-disable-next-line max-len
import ListOpenQuestion from '../../../components/ListQuestionsIntegrate/OpenQuestion/ListOpenQuestion';

interface Params {
  id: string;
}

const ActivityEdit: React.FC<InjectedFormProps> = ( ) => {
  const dispatch = useDispatch();
  const params = useParams<Params>();

  const selector = formValueSelector( 'activity' );
  const tasks = useSelector(( state: RootState ) => selector( state, 'tasks' ));
  const subTypeActivity = useSelector(
    ( state: RootState ) => selector( state, 'subtype' ),
  );
  const {
    userData,
  } = useSelector(( state: RootState ) => state.login );
  const {
    activityById, loading, questions, loadingSub,
  } = useSelector(
    ( state: RootState ) => state.activity,
  );
  const [open, setOpen] = useState( false );
  const [idSubDoc, setIdSubDoc] = useState( '' );
  const [openInfo, setOpenInfo] = useState<boolean>( tasks?.length > 0 );

  const handleSubmit = ( values: any ): void => {
    const { guideDocument, ...data } = values;
    const activity = { ...data } as IActivity;
    activity.isBook = false;
    if ( !String( guideDocument ).includes( 'https' )) {
      dispatch( actions.updateActivity( params.id, activity, guideDocument ));
    } else {
      dispatch( actions.updateActivity( params.id, data, undefined ));
    }
    // history.push( '/docente/actividades' );
  };

  useEffect(() => {
    if ( params.id ) {
      dispatch( actions.getActivity( params.id ));
      dispatch( actions.clearFormData());
    }
  }, []);

  useEffect(() => {
    if ( params.id ) {
      dispatch( actions.getQuestionsThunk( params.id, subTypeActivity ));
    }
  }, [subTypeActivity]);

  useEffect(() => {
    if ( activityById?.tasks?.length > 0 ) {
      // setOpenInfo( true );
    }
  }, [activityById]);

  const handleCreateQuestion = ( data: IQuestion ): void => {
    dispatch( actions.createQuestionThunk( params.id, data ));
  };

  const handleUpdateQuestion = ( data: IQuestion ): void => {
    dispatch( actions.updateQuestionsThunk( params.id, data ));
  };

  const handleConfirmDelete = ( id: string ): void => {
    // if ( tasks && tasks.length > 0 ) {
    //   setOpenInfo( true );
    //   return;
    // }
    setOpen( true );
    setIdSubDoc( id );
  };

  const handleDelete = ( confirm: boolean ): void => {
    if ( confirm ) {
      dispatch( actions.deleteQuestionThunk(
        params.id, idSubDoc, subTypeActivity,
      ));
    }
    setOpen( false );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <ContainerBreadcrumbs>
            <Breadcrumbs
              itemsLinks={[
                {
                  name: 'Actividades',
                  url: `${getRoute( userData.type )}/actividades`,
                }]}
              itemsText={['Editar Actividad']}
            />
          </ContainerBreadcrumbs>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          { !loading
            ? (
              <ActivityForm
                onSubmit={handleSubmit}
                initialValues={activityById}
              />
            )
            : (
              <Spinner />
            )}
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <div>
            {subTypeActivity === subTypes[0].id && (
              !loadingSub
                ? (
                  <div>
                    <LabelInfo label="Ejercicios para completar" />
                    <ListQuestions
                      handleSave={handleCreateQuestion}
                      questions={questions}
                      handleUpdate={handleUpdateQuestion}
                      handleDelete={handleConfirmDelete}
                    />
                  </div>
                )
                : (
                  <Spinner />
                ))}
            {subTypeActivity === subTypes[1].id && (
              !loadingSub
                ? (
                  <div>
                    <LabelInfo label="Ejercicios para arrastrar y completar" />
                    <ListQuestionsDnD
                      handleSave={handleCreateQuestion}
                      questions={questions}
                      handleUpdate={handleUpdateQuestion}
                      handleDelete={handleConfirmDelete}
                    />
                  </div>
                )
                : (
                  <Spinner />
                ))}
            {subTypeActivity === subTypes[2].id && (
              !loadingSub
                ? (
                  <div>
                    <LabelInfo label="Ejercicios para preguntas abiertas" />
                    <ListOpenQuestion
                      handleSave={handleCreateQuestion}
                      questions={questions}
                      handleUpdate={handleUpdateQuestion}
                      handleDelete={handleConfirmDelete}
                    />
                  </div>
                )
                : (
                  <Spinner />
                ))}
          </div>
        </Card>
      </Grid>
      <DialogConfirm
        open={open}
        title="¿ Desea eliminar el item de ejercicio ?"
        onClose={handleDelete}
      />
      {!loadingSub && tasks?.length > 0 && (
        <DialogConfirm
          title={`No se puede editar ya que la \
        actividad esta asignada en varias tareas.`}
          open={openInfo}
          onClose={() => setOpenInfo( false )}
        />
      )}
    </Grid>
  );
};

export default ActivityEdit;
