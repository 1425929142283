import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles(( theme: Theme ) => ({
  large: {
    width: theme.spacing( 20 ),
    // height: theme.spacing( 20 ),
  },
}));

const ContainerImageForm: React.FC = ( props: any ) => {
  const classes = useStyles();
  const { children } = props;

  return (
    <div className={classes.large}>
      {children}
    </div>
  );
};

export default ContainerImageForm;
