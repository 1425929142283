import React from 'react';
import { makeStyles } from '@material-ui/core';
import FormTime from './FormTime';

const useStyles = makeStyles(() => ({
  container: {
    padding: 10,
  },
}));

const daysDefault = [
  { key: 'monday', name: 'Lunes' },
  { key: 'tuesday', name: 'Martes' },
  { key: 'wednesday', name: 'Miercoles' },
  { key: 'thursday', name: 'Jueves' },
  { key: 'friday', name: 'Viernes' },
];

interface IScheduleProps {
  // eslint-disable-next-line react/require-default-props
  days?: any[];
  onSave: any;
  // eslint-disable-next-line react/require-default-props
  data?: any;
  // eslint-disable-next-line react/require-default-props,react/no-unused-prop-types
  loading?: boolean;
}

const Schedule: React.FC<IScheduleProps> = ( props ) => {
  const classes = useStyles();
  const {
    days = daysDefault, onSave, data,
  } = props;

  const getDataByDay = ( key: number ): any => (
    ( data ) ? { ...data[key] } : {}
  );

  return (
    <div className={classes.container}>
      {days.map(( item: any, index: number ) => (
        <div key={index.toString()}>
          <FormTime
            label={item.name}
            onSave={onSave}
            index={index}
            dataHours={getDataByDay( item.key )}
          />
        </div>
      ))}
    </div>
  );
};

export default Schedule;
