import { db } from './firebase';
import { ICourse } from '../interfaces/ICourse';

export const getCourseById = async ( id: string ): Promise<any> => {
  try {
    const collection = await db.collection( 'courses' )
      .doc( id ).get();
    let course = {};
    if ( collection.exists ) {
      const data = collection.data() as ICourse;
      course = { ...data, id };
    }
    return course;
  } catch ( error ) {
    // eslint-disable-next-line no-console
    console.log( error );
    return {};
  }
};
