import React from 'react';

// Material Ui
import {
  Card,
  CardActions,
  makeStyles,
  Grid,
  Box,
  Fab,
  IconButton,
  CardContent,
} from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

// Redux
import * as IconM from '@material-ui/icons/';
import { ISubject } from '../../interfaces/ISubject';
import ToolTip from '../ToolTip/ToolTip';

interface ISubjectClassUrlItemTeacherProps {
  subject: ISubject;
  handleOpenChangeUrl: () => void;
}

const useStyles = makeStyles({
  shadow: {
    boxShadow: `rgb(0 0 0 / 20%) 0px 10px 10px -4px, 
    rgb(0 0 0 / 14%) 0px 3px 4px 0px, 
    rgb(0 0 0 / 12%) 0px 1px 8px 0px`,
  },
  cardActionsTeacher: {
    padding: '0 2em 1em 2em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    minHeight: '100%',
  },
  playButton: {
    color: '#fff',
  },
  teacherText: {
    margin: '0',
    fontWeight: 'bold',
  },
  playButtonText: {
    fontWeight: 'bold',
    marginBottom: '0',
  },
  large: {
    width: '5.6em',
    height: '5.6em',
  },
  avatar: {
    width: '100%',
    height: '100%',
  },
  CardContent: {
    position: 'relative',
  },
});

const SubjectClassUrlItemTeacher: React.FC<ISubjectClassUrlItemTeacherProps> = ( props ) => {
  const {
    subject,
    handleOpenChangeUrl,
  } = props;
  const classes = useStyles();
  return (
    <Grid item xs={12} md={3} lg={2}>
      <Card className={`${classes.shadow} ${classes.card}`}>
        <CardContent className={classes.CardContent}>
          <Box position="absolute" top="0" right="0">
            <ToolTip title="Editar Url de la clase">
              <IconButton onClick={handleOpenChangeUrl}>
                <IconM.Edit />
              </IconButton>
            </ToolTip>
          </Box>
        </CardContent>
        <CardActions className={classes.cardActionsTeacher}>
          <Box display="block" textAlign="center">
            <a
              href={subject.urlZoom}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ToolTip
                title={
                  subject.urlZoom ? 'Ir a Zoom!' : 'No hay link a la sala'
                }
              >
                <Fab
                  className={classes.playButton}
                  color="secondary"
                  aria-label="goToUrl"
                  disabled={!subject.urlZoom}
                >
                  <PlayArrowIcon />
                </Fab>
              </ToolTip>
            </a>
            <p className={classes.playButtonText}>Enlace de la clase</p>
          </Box>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default SubjectClassUrlItemTeacher;
