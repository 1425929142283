import esLocale from 'date-fns/locale/es';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(( ) => ({
  input: {
    borderRadius: 5,
    // border: '2px solid #ddd',
    // padding: '10px 15px',
  },
}));

type typeSize = 'small' | 'medium';
type typeVariant = 'dialog' | 'inline' | 'static';

interface IInputTimeProps {
  // eslint-disable-next-line react/require-default-props
  label?: string;
  // eslint-disable-next-line react/require-default-props
  variant?: typeVariant;
  // eslint-disable-next-line react/require-default-props
  disableToolbar?: boolean;
  // eslint-disable-next-line react/require-default-props
  size?: typeSize;
  // eslint-disable-next-line react/require-default-props
  onChange?: any;
  // eslint-disable-next-line react/require-default-props
  index?: number;
  name: string;
  value: any;
}

const InputTime: React.FC<IInputTimeProps> = ( props ) => {
  const classes = useStyles();
  const {
    variant, disableToolbar, size, name, index, onChange, value, label,
  } = props;

  const handleDateChange = ( date: any ): void => {
    onChange( index, date, name );
  };

  return (
    <div className={classes.input}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        <KeyboardTimePicker
          name={name}
          value={value ? new Date( value ) : null}
          onChange={handleDateChange}
          disableToolbar={disableToolbar || false}
          variant={variant || 'dialog'}
          margin="dense"
          size={size || 'small'}
          placeholder={label}
          label={label}
          inputVariant="outlined"
          error={false}
          helperText={null}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          fullWidth
          cancelLabel="Cancelar"
          okLabel="Aceptar"
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default InputTime;
