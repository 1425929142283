import React from 'react';

// Material Ui
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  makeStyles,
  Box,
  CardMedia,
  CardHeader,
  Fab,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

// Redux
import { useHistory } from 'react-router';
import { IBook } from '../../interfaces/IBook';
import { RootState } from '../../redux/store';

interface IBookProps {
  book: IBook;
}

const useStyles = makeStyles(( theme ) => ({
  title: {
    fontSize: 14,
    fontWeight: 600,
  },
  imgConatiner: {
    minHeight: 270,
    width: '100%',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down( 538.98 )]: {
      minHeight: '0!important',
    },
  },
  cardActions: {
    display: `flex`, justifyContent: `center`,
  },
  shadow: {
    boxShadow: `rgb(0 0 0 / 20%) 0px 10px 10px -4px, 
    rgb(0 0 0 / 14%) 0px 3px 4px 0px, 
    rgb(0 0 0 / 12%) 0px 1px 8px 0px`,
  },
  imgBook: {
    width: '70%',
    height: 'auto',
    minHeight: 200,
    maxHeight: '250px',
  },
  imgBookOnlyRead: {
    width: '80%',
    height: 'auto',
    maxHeight: '300px',
  },
  cardContent: {
    padding: '0 2em 0 2em',
  },
  cardHeader: {
    padding: '1em 2em 0 2em',
  },
  card: {
    minHeight: '100%',
    borderRadius: 10,
  },
  playButton: {
    backgroundColor: '#FECA19',
    color: '#fff',
  },
}));

const BookItem: React.FC<IBookProps> = ( props ) => {
  const { book } = props;
  const classes = useStyles();
  const history = useHistory();
  const { userData: { type } } = useSelector(
    ( state: RootState ) => state.login,
  );

  const getRoute = (): string | null => {
    let route: string | null;
    switch ( type ) {
      case 1:
        route = 'admin';
        break;
      case 2:
        route = 'docente';
        break;
      case 3:
        route = 'estudiante';
        break;
      default:
        route = null;
        break;
    }
    return route;
  };

  return (
    <Card className={`${classes.shadow} ${classes.card}`}>
      <CardHeader
        className={classes.cardHeader}
        title={(
          <Box display="flex" justifyContent="flex-end">
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              {book.onlyRead && 'Solo lectura'}
            </Typography>
          </Box>
        )}
      />
      <CardMedia>
        <div className={classes.imgConatiner}>
          {book.onlyRead ? (
            <img
              src={book.photo}
              alt={book.name}
              className={classes.imgBookOnlyRead}
            />
          )
            : (
              <img
                src={book.photo}
                alt={book.name}
                className={classes.imgBook}
              />
            )}
        </div>
      </CardMedia>
      <CardContent className={classes.cardContent}>
        <Typography
          className={classes.title}
          color="textPrimary"
        >
          {book.name}
        </Typography>
        <Typography
          className={classes.title}
          color="textSecondary"
        >
          {book.editorial}
        </Typography>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Box
          display="flex"
          justifyContent="space-between"
          textAlign="center"
          mb="1em"
        >
          <Fab
            className={classes.playButton}
            aria-label="add"
            onClick={
              () => history.push( `/${getRoute()}/libros/ver/${book.id}` )
            }
          >
            <PlayArrowIcon />
          </Fab>
        </Box>
      </CardActions>
    </Card>
  );
};

export default BookItem;
