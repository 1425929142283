import React, { useState, useEffect, ChangeEvent } from 'react';
import { TextField, Paper } from '@material-ui/core';
import useStyles from './Styles';

interface IProps {
  idAct: string;
  name: string;
  rubric: string;
  calification: string;
  setCalification: any;
}

const RubricItem:React.FC<IProps> = ( props ) => {
  const classes = useStyles();
  const {
    name, rubric,
    calification,
    setCalification,
    idAct,
  } = props;
  const [value, setValue] = useState<string>( '' );

  useEffect(() => {
    setValue( calification );
  }, [calification]);

  const onChange = ( e: ChangeEvent<HTMLInputElement> ): void => {
    if ( e.target.value !== '' ) {
      setCalification( idAct, parseFloat( e.target.value ));
      setValue( e.target.value );
    } else {
      setCalification( idAct, 0 );
      setValue( '0' );
    }
  };

  return (
    <div className={classes.itemRubric}>
      <span className={classes.titleItem}>
        {name}
      </span>
      <div className={classes.row}>
        <span className={classes.rubric}>
          {rubric}
        </span>
        <Paper className={classes.calification} elevation={3}>
          <TextField
            value={value}
            variant="outlined"
            size="small"
            onChange={onChange}
            className={classes.input}
          />
        </Paper>
      </div>
    </div>
  );
};

export default RubricItem;
