import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(( theme ) => ({
  shadow: {
    boxShadow: `rgb(0 0 0 / 20%) 0px 10px 10px -4px, 
    rgb(0 0 0 / 14%) 0px 3px 4px 0px, 
    rgb(0 0 0 / 12%) 0px 1px 8px 0px`,
  },
  card: {
    borderRadius: 10,
    width: '100%',
    marginBottom: '1em',
  },
  playButton: {
    backgroundColor: '#FECA19',
    color: '#fff',
  },
  exclamationText: {
    margin: '0',
    fontWeight: 'bold',
  },
  svg: {
    maxWidth: '2em',
    minWidth: '2em',
  },
  cardContent: {
    paddingBottom: '0!important',
  },
  textTruncate: {
    display: 'block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  subjectName: {
    marginTop: '0!important',
    [theme.breakpoints.down( 750.98 )]: {
      maxWidth: '6em!important',
    },
  },
}));

export default useStyles;
