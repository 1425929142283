import React from 'react';
import { Grid } from '@material-ui/core';
import InputToUpload from '../FileInput/InputToUpload';
import ButtonProgress from '../ButtonProgress/ButtonProgress';

interface IIntegrationProps {
  handleSetFile: any;
  uploadSuccess: boolean;
  loading: boolean;
  handleUpload: any;
}

const IntegrationUpload: React.FC<IIntegrationProps> = ( props ) => {
  const {
    handleSetFile,
    uploadSuccess,
    loading,
    handleUpload,
  } = props;

  return (
    <Grid
      container
      justify="center"
      spacing={2}
      style={{ alignItems: 'center' }}
    >
      <Grid item>
        <InputToUpload
          title="Eleja un archivo de excel"
          setFile={handleSetFile}
        />
      </Grid>
      <Grid item>
        <ButtonProgress
          title={uploadSuccess ? '¡ Éxito !' : 'Cargar datos'}
          loading={loading}
          success={uploadSuccess}
          onClick={handleUpload}
        />
      </Grid>
    </Grid>
  );
};

export default IntegrationUpload;
