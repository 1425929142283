import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import { RootState } from '../../../redux/store';
import TableTask from '../../../components/TableTask/TableTask';
import {
  getTasksAllThunk,
  taskDeleteThunk,
  getTaskByIdThunk,
  updateTaskActivitiesThunk,
} from '../../../redux/ducks/TaskDucks';
import DialogConfirm from '../../../components/DialogConfirm/DialogConfirm';
// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { getSubjectsAllThunk } from '../../../redux/ducks/SubjectDucks';
import Dialog from '../../../components/Dialog/Dialog';
// eslint-disable-next-line max-len
import TransferListAny from '../../../components/TransferList/TransferListAny';
import { actions } from '../../../redux/ducks/ActivityDucks';
import Card from '../../../components/Card/Card';

const TasksList: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { tasks, formData, loading } = useSelector(
    ( state: RootState ) => state.task,
  );
  const { activities } = useSelector(
    ( state: RootState ) => state.activity,
  );
  const [open, setOpen] = useState( false );
  const [idDoc, setIdDoc] = useState( '' );
  const [openAddActivity, setOpenAddActivity] = useState( false );
  const [closedTransfer, setClosedTransfer] = useState( false );

  useEffect(() => {
    dispatch( getTasksAllThunk());
    dispatch( getSubjectsAllThunk());
    // dispatch( actions.getActivities());
  }, []);

  useEffect(() => {
    dispatch( getTaskByIdThunk( idDoc ));
  }, [idDoc]);

  const handleOpenConfirm = ( id: string ): void => {
    setIdDoc( id );
    setOpen( true );
  };

  const handleDelete = ( confirm: boolean ): void => {
    if ( confirm ) {
      dispatch( taskDeleteThunk( idDoc ));
    }
    setOpen( false );
  };

  const handleNew = (): void => {
    history.push( `/docente/tareas/agregar` );
  };

  const handleEdit = ( id: string ): void => {
    history.push( `/docente/tareas/editar/${id}` );
  };

  const handleShow = ( id: string ): void => {
    history.push( `/docente/tareas/ver/${id}` );
  };

  const handleShowOpenAddBook = ( id: string ): void => {
    setIdDoc( id );
    setOpenAddActivity( true );
    setClosedTransfer( false );
  };

  const handleSaveData = (
    activitiesId: string[], lastIds?: string[],
  ): void => {
    dispatch( updateTaskActivitiesThunk( idDoc, activitiesId ));
    dispatch( getTaskByIdThunk( idDoc ));
    if ( lastIds ) {
      dispatch( actions.addTaskIdThunk( lastIds, idDoc, false ));
      return;
    }
    if ( activitiesId.length > 0 ) {
      dispatch( actions.addTaskIdThunk( activitiesId, idDoc, true ));
    }
  };

  const closeTransferList = (): void => {
    setOpenAddActivity( false );
    setClosedTransfer( true );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <ContainerBreadcrumbs>
            <Breadcrumbs
              back={false}
              itemsLinks={[{ name: 'Tareas', url: 'docente/tareas' }]}
              itemsText={['Lista de tareas']}
            />
          </ContainerBreadcrumbs>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <TableTask
            data={tasks}
            handleNew={handleNew}
            handleShow={handleShow}
            handleDelete={handleOpenConfirm}
            handleEdit={handleEdit}
            handleOpenAdd={handleShowOpenAddBook}
          />
        </Card>
      </Grid>
      <Dialog
        open={openAddActivity}
        title="Agregar una actividad"
        onClose={() => closeTransferList()}
        size="md"
      >
        {formData && (
          <TransferListAny
            itemsRIGHT={formData.activities}
            itemsLEFT={activities}
            handleSaveData={handleSaveData}
            handleDeleteData={handleSaveData}
            loading={loading}
            closedTransfer={closedTransfer}
          />
        )}
      </Dialog>
      <DialogConfirm
        open={open}
        title="¿ Desea eliminar la tarea ?"
        onClose={handleDelete}
      />
    </Grid>
  );
};
export default TasksList;
