import React from 'react';
import ToolTipMaterial from '@material-ui/core/Tooltip';

type TypePlacement = 'top' | 'bottom' | 'left' | 'right';

interface IToolTipProps {
  children: JSX.Element;
  title: string;
  // eslint-disable-next-line react/require-default-props
  placement?: TypePlacement;
}

const ToolTip: React.FC<IToolTipProps> = ( props ) => {
  const { children, title, placement = 'top' } = props;

  return (
    <ToolTipMaterial
      title={title}
      placement={placement}
      arrow
    >
      <div>
        { children }
      </div>
    </ToolTipMaterial>
  );
};

export default ToolTip;
