import React, { useEffect } from 'react';
// The carousel component
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Navigation,
} from 'swiper/core';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/swiper.min.css';
import './Swiper.css';
// Interface
import { useDispatch, useSelector } from 'react-redux';
// Redux
import {
  Box, Grid, makeStyles,
} from '@material-ui/core';
import { RootState } from '../../../../redux/store';
import {
  getSubjectsByTeacherIdThunk,
} from '../../../../redux/ducks/SubjectDucks';
import {
  actions as actionsBooks,
} from '../../../../redux/ducks/BookDucks';
// Components
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import SubjectGradeItemTeacher from '../../../../components/SubjectGradeItemTeacher/SubjectGradeItemTeacher';
import BookItem from '../../../../components/BookItem/BookItem';

// Interface
import { IBook } from '../../../../interfaces/IBook';
import Spinner from '../../../../components/Spinner/Spinner';
import MessageCard from '../../../../components/MessageCard/MessageCard';
import { ISubject } from '../../../../interfaces/ISubject';

SwiperCore.use([Navigation]);

const useStyles = makeStyles({
  center: {
    marginTop: '2em',
    marginBottom: '8em',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
});

const TeacherSubjectView: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { userData } = useSelector((
    state: RootState,
  ) => state.login );

  const {
    subjects,
    loading: loadingSubjects,
  } = useSelector(( state: RootState ) => state.subject );

  const {
    booksCourse: books,
    loading: loadingBooks,
  } = useSelector(( state: RootState ) => state.book );

  useEffect(() => {
    // dispatch( actionsTeachers.getTeachers());
    dispatch( getSubjectsByTeacherIdThunk( userData.id ));
  }, []);

  useEffect(() => {
    if ( subjects.length > 0 ) {
      getBooksByCourse();
    }
  }, [subjects]);

  const getBooksByCourse = (): void => {
    const arrayList = subjects.reduce(
      ( acc: string[], el: ISubject ) => acc.concat(
        el.books ? el.books : [''],
      ), [],
    );
    const bookList = arrayList.filter(( x ) => x !== '' );

    const listAux: string[] = [];
    for ( let i = 0; i < bookList.length; i++ ) {
      if ( !listAux.includes( bookList[i])) {
        listAux.push( bookList[i]);
      }
    }

    dispatch( actionsBooks.getBooksByArrayId( listAux ));
  };

  return (
    <>
      <ContainerBreadcrumbs>
        <Breadcrumbs
          back={false}
          itemsLinks={[{ name: 'Inicio', url: 'docente/inicio' }]}
          itemsText={['Docente']}
        />
      </ContainerBreadcrumbs>
      {loadingSubjects ? (
        <div className={classes.center}>
          <Spinner />
        </div>
      ) : (
        <Grid
          container
          direction="row"
          justify="center"
        >
          <Grid
            item
            xs={12}
            id="subjectTeacher"
            style={{ marginBottom: subjects.length > 0 ? '0' : '9em' }}
          >
            {subjects && subjects.length > 0 ? (
              <Swiper
                slidesPerView={2}
                slidesPerColumn={3}
                spaceBetween={15}
                navigation
                grabCursor
                breakpoints={{
                  400: {
                    slidesPerView: 1,
                    slidesPerColumn: 3,
                  },
                  800: {
                    slidesPerView: 2,
                    slidesPerColumn: 3,
                  },
                }}
              >
                {subjects.map(( subject, i: number ) => (
                  <SwiperSlide key={i.toString()}>
                    <SubjectGradeItemTeacher
                      grades={false}
                      subject={subject}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <MessageCard>
                <h3>No hay materias asignadas.</h3>
              </MessageCard>
            )}
          </Grid>
          <Grid item xs={12} style={{ marginTop: books.length > 0 ? '-8em' : '0' }}>
            <Box
              fontWeight="fontWeightBold"
              fontSize={25}
              pt={3}
              pb={3}
            >
              Libros
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ padding: '0 1em 0 1em' }}
            id="bookTeacher"
          >
            <Swiper
              spaceBetween={10}
              slidesPerView={5}
              navigation
              grabCursor
              breakpoints={{
                400: {
                  slidesPerView: 2,
                },
                800: {
                  slidesPerView: 3,
                },
                1024: {
                  slidesPerView: 4,
                },
                1300: {
                  slidesPerView: 5,
                },
              }}
            >
              {loadingBooks ? (
                <Spinner />
              ) : (
                <>
                  {books && books.map(( book: IBook, i: number ) => (
                    <SwiperSlide key={i.toString()}>
                      <BookItem book={book} />
                    </SwiperSlide>
                  ))}
                </>
              )}
            </Swiper>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default TeacherSubjectView;
