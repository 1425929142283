import React from 'react';
import { TableBody } from '@material-ui/core';

interface IBodyProps {
  children: JSX.Element | JSX.Element[];
}

const BodyTable: React.FC<IBodyProps> = ( props ) => {
  const { children } = props;

  return (
    <TableBody>
      { children }
    </TableBody>
  );
};

export default BodyTable;
