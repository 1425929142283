import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  itemSubject: {
    marginLeft: '10%',
    marginRight: '10%',
  },
  center: {
    marginTop: '2em',
    marginBottom: '8em',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
  },
});
