import React from 'react';
import { Grid } from '@material-ui/core';
import {
  reduxForm, InjectedFormProps, Field, formValueSelector,
} from 'redux-form';
import { useSelector } from 'react-redux';
import {
  isImage,
  maxLength30,
  maxLength500,
  minLength5,
  required,
  urlValidator,
} from '../../helpers/validate-forms';
import Button from '../Button/Button';
import Checkbox from '../Checkbox/Checkbox';
import Input from '../Input/Input';
import FileInput from '../FileInput/FileInput';
import ContainerForm from '../ContainerForm/ContainerForm';
import FormRedux from '../FormRedux/FormRedux';
import ContainerInputs from '../ContainerInputs/ContainerInputs';
import { RootState } from '../../redux/store';
import ImageForm from '../ImageForm/ImageForm';
import Image from '../Image/Image';
import BookDefault from '../../assets/img/BookDefault.jpg';
import CenterInLine from '../Center/CenterInLine';
import ContainerImageForm from '../ContainerImageForm/ContainerImageForm';

const BookForm: React.FC<InjectedFormProps> = ( props ) => {
  const selector = formValueSelector( 'book' );
  const photo = useSelector(( state: RootState ) => selector( state, 'photo' ));
  const {
    handleSubmit,
    submitting,
  } = props;

  return (
    <ContainerForm>
      <Grid item xs={12} sm={8}>
        <FormRedux onSubmit={handleSubmit}>
          <ContainerInputs>
            <Grid item xs={12} sm={6}>
              <Field
                name="name"
                type="text"
                label="Nombre"
                component={Input}
                placeholder="Ingrese un nombre"
                validate={[required, minLength5, maxLength500]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="description"
                type="text"
                label="Descripción"
                component={Input}
                placeholder="Ingrese una descripción"
                validate={[required, minLength5, maxLength500]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="mainUrl"
                type="text"
                label="Url para embeber"
                component={Input}
                placeholder="Ingrese una url para embeber"
                validate={[required, urlValidator]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="editorial"
                type="text"
                label="Editorial"
                component={Input}
                placeholder="Ingrese editoríal"
                validate={[required, minLength5, maxLength30]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="photo"
                type="file"
                label="Elija la imagen"
                component={FileInput}
                validate={[required, isImage]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="onlyRead"
                component={Checkbox}
                title="Solo lectura"
                type="checkbox"
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" disabled={submitting}>
                Enviar
              </Button>
            </Grid>
          </ContainerInputs>
        </FormRedux>
      </Grid>
      <Grid item xs={12} sm={4}>
        <CenterInLine>
          <ContainerImageForm>
            {photo
              ? <ImageForm photo={photo} />
              : <Image src={BookDefault} />}
          </ContainerImageForm>
        </CenterInLine>
      </Grid>
    </ContainerForm>
  );
};

export default reduxForm({
  form: 'book',
  enableReinitialize: true,
})( BookForm );
