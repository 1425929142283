import React, { Fragment, useState } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import * as IconM from '@material-ui/icons/';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import HeaderTable from '../Table/HeaderTable/HeaderTable';
import ContainerTable from '../Table/ContainerTable/ContainerTable';
import BodyTable from '../Table/BodyTable/BodyTable';
import RowHeaderTable from '../Table/RowHeaderTable/RowHeaderTable';
import Table from '../Table/Table';
import { ITask } from '../../interfaces/ITask';
import ToolTip from '../ToolTip/ToolTip';
import CenterInLine from '../Center/CenterInLine';
import { timeStampToStringView } from '../../helpers/convert-to-timestamp';

interface ITableProps{
  data: ITask[];
  handleEdit: any;
  handleDelete: any;
  handleNew: any;
  // eslint-disable-next-line react/require-default-props,react/no-unused-prop-types
  handleUpload?: any;
  handleShow: any;
  handleOpenAdd: any;
}

const TableTask: React.FC<ITableProps> = ( props ) => {
  // eslint-disable-next-line no-unused-vars
  const [page, setPage] = useState( 0 );
  // eslint-disable-next-line no-unused-vars
  const [rowsPerPage, setRowsPerPage] = useState( 5 );
  const {
    data, handleDelete, handleEdit, handleNew, handleShow, handleOpenAdd,
  } = props;

  // const handleChangePage = ( event: any, newPage: number ): void => {
  //   setPage( newPage );
  // };

  // const handleChangeRowsPerPage = ( event: any ): void => {
  //   setRowsPerPage( parseInt( event.target.value, 10 ));
  //   setPage( 0 );
  // };

  return (
    <>
      <CenterInLine isRow justify="flex-start">
        <ToolTip title="Agregar tarea" placement="left">
          <ButtonIcon handleClick={handleNew}>
            <IconM.Add />
          </ButtonIcon>
        </ToolTip>
        <span>Agregar nueva tarea</span>
      </CenterInLine>
      <ContainerTable>
        <Table>
          <HeaderTable>
            <RowHeaderTable>
              <TableCell align="center">N°</TableCell>
              <TableCell align="center">Title</TableCell>
              <TableCell align="center">Descripción</TableCell>
              <TableCell align="center">Fecha Inicio</TableCell>
              <TableCell align="center">Fecha Fin</TableCell>
              <TableCell align="center">Acciones</TableCell>
              <TableCell align="center">Agregar</TableCell>
            </RowHeaderTable>
          </HeaderTable>
          <BodyTable>
            {( rowsPerPage > 0
              ? data.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
              )
              : data
            ).map(( row: ITask, index: number ) => (
              <TableRow key={index.toString()}>
                <TableCell align="center" component="th" scope="row">
                  {( index + 1 )}
                </TableCell>
                <TableCell align="center">{row.title}</TableCell>
                <TableCell align="center">{row.description}</TableCell>
                <TableCell align="center">
                  {timeStampToStringView( row.dateStart.seconds )}
                </TableCell>
                <TableCell align="center">
                  {timeStampToStringView( row.dateEnd.seconds )}
                </TableCell>
                <TableCell align="center">
                  <CenterInLine isRow>
                    <ToolTip title="Mostrar detalle de tarea">
                      <ButtonIcon handleClick={() => handleShow( row.id )}>
                        <IconM.Visibility />
                      </ButtonIcon>
                    </ToolTip>
                    <ToolTip title="Editar tarea">
                      <ButtonIcon handleClick={() => handleEdit( row.id )}>
                        <IconM.Edit />
                      </ButtonIcon>
                    </ToolTip>
                    <ToolTip title="Eliminar tarea">
                      <ButtonIcon handleClick={() => handleDelete( row.id )}>
                        <IconM.Delete />
                      </ButtonIcon>
                    </ToolTip>
                  </CenterInLine>
                </TableCell>
                <TableCell align="center">
                  <ToolTip title="Agregar actividad">
                    <ButtonIcon handleClick={() => handleOpenAdd( row.id )}>
                      <IconM.PlaylistAdd />
                    </ButtonIcon>
                  </ToolTip>
                </TableCell>
              </TableRow>
            ))}
          </BodyTable>
        </Table>
      </ContainerTable>
    </>
  );
};

export default TableTask;
