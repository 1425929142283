import { ILinks } from '../../interfaces/ILinks';
import bookIcon from '../../assets/svg/bookIcon.svg';
import teacherIcon from '../../assets/svg/teacherIcon.svg';
import studentIcon from '../../assets/svg/studentIcon.svg';
import courseIcon from '../../assets/svg/courseIcon.svg';
import activityIcon from '../../assets/svg/activityIcon.svg';
import musetaIcon from '../../assets/svg/musetaIcon.svg';
import gradeIcon from '../../assets/svg/gradeIcon.svg';
import calendarIcon from '../../assets/svg/calendarIcon.svg';

export const MENU: ILinks[] = [
  {
    name: 'Libros',
    link: '/admin/libros',
    icon: bookIcon,
    typeUser: 1,
  },
  {
    name: 'Unidades',
    link: '/admin/unidades',
    icon: bookIcon,
    typeUser: 1,
  },
  {
    name: 'Actividades',
    link: '/admin/actividades',
    icon: activityIcon,
    typeUser: 1,
  },
  {
    name: 'Cursos',
    link: '/admin/cursos',
    icon: courseIcon,
    typeUser: 1,
  },
  {
    name: 'Docentes',
    link: '/admin/docentes',
    icon: teacherIcon,
    typeUser: 1,
  },
  {
    name: 'Materias',
    link: '/admin/materias',
    icon: teacherIcon,
    typeUser: 1,
  },
  {
    name: 'Estudiantes',
    link: '/admin/estudiantes',
    icon: studentIcon,
    typeUser: 1,
  },
  {
    name: 'Clases',
    link: '/docente/materias/clases',
    icon: musetaIcon,
    typeUser: 2,
  },
  {
    name: 'Libros',
    link: '/docente/libros',
    icon: bookIcon,
    typeUser: 2,
  },
  {
    name: 'Calendario',
    link: '/',
    icon: calendarIcon,
    typeUser: 0,
  },
  {
    name: 'Calificaciones',
    link: '/docente/calificaciones',
    icon: gradeIcon,
    typeUser: 2,
  },
  {
    name: 'Clases',
    link: '/estudiante/mismaterias',
    icon: musetaIcon,
    typeUser: 3,
  },
  {
    name: 'Libros',
    link: '/estudiante/libros',
    icon: bookIcon,
    typeUser: 3,
  },
  {
    name: 'Calendario',
    link: '/estudiante/calendario',
    icon: calendarIcon,
    typeUser: 3,
  },
  {
    name: 'Calificaciones',
    link: '/estudiante/calificaciones',
    icon: gradeIcon,
    typeUser: 3,
  },
];
