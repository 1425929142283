import React, { useState } from 'react';
import {
  DataGrid,
} from '@material-ui/data-grid';
import useStyles from './Styles';

interface ITableProps {
  // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
  children?: JSX.Element | JSX.Element[];
  // eslint-disable-next-line react/require-default-props
  data?: any[];
  // eslint-disable-next-line react/require-default-props
  columns?: any[];
}

const Table: React.FC<ITableProps> = ( props ) => {
  const classes = useStyles();
  const { data, columns } = props;
  const [page, setPage] = useState( 0 );
  const [rowsPerPage, setRowsPerPage] = useState( 5 );

  const handleChangePage = ( newPage: number ): void => {
    setPage( newPage );
  };

  const handleChangeRowsPerPage = ( pageSize: number ): void => {
    setRowsPerPage( pageSize );
    setPage( 0 );
  };

  return (
    <>
      <div className={classes.root}>
        <DataGrid
          className={classes.datagrid}
          // data
          rows={data || []}
          columns={columns || []}
          // control number items
          pageSize={rowsPerPage}
          page={page}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          onPageChange={handleChangePage}
          onPageSizeChange={handleChangeRowsPerPage}
          // actions in cells
          disableSelectionOnClick
          // size table
          autoHeight
          // loading
          loading={data?.length === 0}
        />
      </div>
    </>
  );
};

export default Table;
