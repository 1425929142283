import React, { useEffect } from 'react';
// The carousel component
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import './Swiper.css';
// Interface
import { useDispatch, useSelector } from 'react-redux';
// Redux
import {
  Box, Grid,
} from '@material-ui/core';
import { RootState } from '../../../../redux/store';
import {
  getSubjectsByCourseIdThunk,
} from '../../../../redux/ducks/SubjectDucks';
import {
  actions as actionsTeachers,
} from '../../../../redux/ducks/TeacherDucks';
// Components
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import SubjectItem from '../../../../components/SubjectStudentItem/SubjectStudentItem';
import BookItem from '../../../../components/BookItem/BookItem';

// Interface
import { IBook } from '../../../../interfaces/IBook';
import OnlyCalendar from '../../../../components/Calendar/Calendar';
import MessageCard from '../../../../components/MessageCard/MessageCard';
import Spinner from '../../../../components/Spinner/Spinner';

const StudentSubjectView: React.FC = () => {
  const dispatch = useDispatch();
  const { userData } = useSelector((
    state: RootState,
  ) => state.login );

  const { subjects, loading } = useSelector(
    ( state: RootState ) => state.subject,
  );

  const { booksCourse: books } = useSelector(
    ( state: RootState ) => state.book,
  );

  useEffect(() => {
    dispatch( actionsTeachers.getTeachers());
    dispatch( getSubjectsByCourseIdThunk( userData.courseId ));
  }, []);

  return (
    <>
      <ContainerBreadcrumbs>
        <Breadcrumbs
          back={false}
          itemsLinks={[{ name: 'Materias', url: 'estudiante/materias' }]}
          itemsText={['Todas tus materias']}
        />
      </ContainerBreadcrumbs>
      <Grid
        container
        direction="row"
        justify="center"
      >
        {loading ? (
          <Spinner />
        ) : (
          <>
            <Grid item xs={12}>
              {subjects && subjects.length > 0 ? (
                <>
                  {subjects && (
                    <Swiper
                      spaceBetween={15}
                      slidesPerView={3}
                      breakpoints={{
                        400: {
                          slidesPerView: 1,
                          spaceBetween: 20,
                        },
                        768: {
                          slidesPerView: 2,
                          spaceBetween: 40,
                        },
                        1024: {
                          slidesPerView: 3,
                          spaceBetween: 50,
                        },
                      }}
                    >
                      {subjects.map(( subject, i: number ) => (
                        <SwiperSlide key={i.toString()}>
                          <SubjectItem subject={subject} />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  )}
                </>
              ) : (
                <MessageCard>
                  <h3>No hay materias asignadas.</h3>
                </MessageCard>
              )}
            </Grid>
            <Grid item xs={12}>
              <Box
                fontWeight="fontWeightBold"
                fontSize={25}
                pt={3}
                pb={3}
              >
                Libros
              </Box>
            </Grid>
            <Grid item xs={12} lg={7} style={{ padding: '0 1em 0 1em' }}>
              {books && (
                <Swiper
                  spaceBetween={10}
                  slidesPerView={3}
                  breakpoints={{
                    400: {
                      slidesPerView: 2,
                      spaceBetween: 7,
                    },
                    800: {
                      slidesPerView: 3,
                      spaceBetween: 7,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 7,
                    },
                  }}
                >
                  {books.map(( book: IBook, i: number ) => (
                    <SwiperSlide key={i.toString()}>
                      <BookItem book={book} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
            </Grid>
            <Grid item xs={12} md={7} lg={5}>
              <OnlyCalendar />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default StudentSubjectView;
