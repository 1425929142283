import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Divider, Grid } from '@material-ui/core';
import * as IconM from '@material-ui/icons/';
import { useHistory } from 'react-router';
// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import { RootState } from '../../../redux/store';
import {
  getTaskByIdThunk,
  createGradeThunk,
  actions as actionsTask,
} from '../../../redux/ducks/TaskDucks';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import ActivityItem from './components/ActivityItem/ActivityItem';
import Card from '../../../components/Card/Card';
import RubricEvaluation from './components/RubricEvaluation/RubricEvaluation';
import { getActivitiesById } from '../../../services/Activities.Services';
import { IActivity } from '../../../interfaces/IActivity';
import { actions } from '../../../redux/ducks/ActivityDucks';
import useStyles from './Styles';
import ButtonIcon from '../../../components/ButtonIcon/ButtonIcon';
import { stringToTimeStamp } from '../../../helpers/convert-to-timestamp';
import { auth } from '../../../services/firebase';
import { ITask } from '../../../interfaces/ITask';
import Dialog from '../../../components/Dialog/Dialog';

interface Params {
  id: string;
}

const Homework: React.FC = ( ) => {
  const classes = useStyles();
  const params = useParams<Params>();
  const dispatch = useDispatch();
  const history = useHistory();
  const [calification = 0, setCalification] = useState<number>( 0 );
  const [calificationList, setCalificationList] = useState<IActivity[]>([]);
  const [open, setOpen] = useState( false );
  const [messageDialog, setMessageDialog] = useState<string>( '' );
  const [titleDialog, setTitleDialog] = useState( '' );

  const { activities } = useSelector(
    ( state: RootState ) => state.activity,
  );

  const { formData } = useSelector(
    ( state: RootState ) => state.task,
  );

  useEffect(() => {
    dispatch( actions.setActivities([]));
    dispatch( actionsTask.setInicitalFormTask());
    if ( params.id ) {
      dispatch( getTaskByIdThunk( params.id ));
    }
    return () => {
      dispatch( actionsTask.setInicitalFormTask());
    };
  }, []);

  useEffect(() => {
    if ( formData?.activities ) {
      handleGetData();
    }
  }, [formData]);

  const handleGetTotal = (): void => {
    let total = 0;
    activities.forEach(( item: IActivity ) => {
      total += item.calification || 0;
    });
    setCalification( total );
  };

  const handleGetData = async (): Promise<void> => {
    const actTotals = formData.activities.concat( formData.activitiesBook );
    const actList = await getActivitiesById( actTotals );
    dispatch( actions.setActivities( actList ));
    const actListAux = await getActivitiesById( actTotals );
    setCalificationList( actListAux );
  };

  const handleSetCalification = (
    id: string, total?: number,
    answer?: { id: string, answer: string },
    urlActNormal?: string,
  ): void => {
    const actList = activities;
    const act = actList.find(( x: IActivity ) => x.id === id );
    const answerList: any[] = act.answers || [];
    if ( answer ) {
      const answerIndex = answerList.findIndex(( x ) => x.id === answer.id );
      if ( answerIndex !== -1 ) {
        answerList.splice( answerIndex, 1 );
      }
      answerList.push( answer );
    }
    Object.assign( act, {
      calification: total,
      answers: answerList,
      answerUrl: urlActNormal,
    });
    dispatch( actions.setActivities( actList ));
  };

  const handleSaveCalification = (): void => {
    if ( activities.length === 0 ) {
      const title = `No existen actividades`;
      const msg = `La tarea no tiene actividades asignadas.`;
      handleOpenDialog( title, msg );
      return;
    }
    setCalificationList( activities );
    handleGetTotal();
    const cal: any[] = [];
    activities.forEach(( act: IActivity ) => {
      cal.push({
        id: act.id,
        calification: act.calification,
        answers: act.answers || [],
        urlDocAnswer: act.answerUrl || '',
      });
    });
    if ( auth.currentUser ) {
      const { uid } = auth.currentUser;
      const note = {
        idStudent: uid,
        noteFinal: calification,
        subNotes: cal,
        fecha: stringToTimeStamp( new Date().toISOString()),
      };
      const { students } = formData;
      const task = formData as ITask;
      if ( !students.includes( uid )) {
        task.students.push( uid );
        dispatch( createGradeThunk( formData.id, note, task, uid ));
        history.goBack();
      } else {
        const title = `La tarea ya esta realizada`;
        const msg = `Usted ya realizo esta tarea y 
        no puede volver a enviarla.`;
        handleOpenDialog( title, msg );
      }
    }
  };

  const handleOpenDialog = ( title: string, msg: string ): void => {
    setOpen( !open );
    setMessageDialog( msg );
    setTitleDialog( title );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <div className={classes.card}>
            <ContainerBreadcrumbs>
              <Breadcrumbs
                itemsLinks={[{ name: 'Tarea', url: '/' }]}
                itemsText={['Tarea']}
              />
            </ContainerBreadcrumbs>
            <div className={classes.send}>
              <ButtonIcon handleClick={handleSaveCalification}>
                <IconM.PlayArrow />
              </ButtonIcon>
              <span>Enviar</span>
            </div>
          </div>
        </Card>
      </Grid>
      <Grid item container xs={12} sm={8}>
        <Grid item xs={12}>
          <Card>
            {activities
            && activities.map(( item: any, i: number ) => (
              <div key={i.toString()}>
                <ActivityItem
                  id={item.id}
                  data={item}
                  setCalification={handleSetCalification}
                />
                <Divider />
              </div>
            ))}
          </Card>
        </Grid>
      </Grid>
      <Grid item container xs={12} sm={4}>
        <RubricEvaluation
          data={calificationList}
          calification={calification}
        />
      </Grid>
      <Dialog
        open={open}
        title={titleDialog}
        onClose={handleOpenDialog}
        size="xs"
      >
        <Box
          flexDirection="column"
          display="flex"
          gridRowGap={10}
        >
          <span>
            {messageDialog}
          </span>
        </Box>
      </Dialog>
    </Grid>
  );
};

export default Homework;
