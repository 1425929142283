import React, { useState, useEffect } from 'react';
import { IBook } from '../../../../interfaces/IBook';

import { getBookById } from '../../../../services/Book.Services';

interface IBookIdProps{
  bookId: any;
}

const BookName: React.FC<IBookIdProps> = ( props ) => {
  const { bookId } = props;

  const [book, setBook] = useState<IBook>();
  const handleGetBookById = async (): Promise<void> => {
    const resBook = await getBookById( bookId );
    setBook( resBook );
  };

  useEffect(() => {
    let isSuscribed = false;
    if ( !isSuscribed ) {
      handleGetBookById();
    }
    return () => {
      isSuscribed = true;
    };
  }, [props]);

  return (
    <span>{book ? book.name : '..........'}</span>
  );
};

export default BookName;
