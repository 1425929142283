import React from 'react';
import LabelInfo from '../LabelInfo/LabelInfo';
import Link from '../Link/Link';

interface IIntegrateLabelLinkProps {
  // eslint-disable-next-line react/require-default-props
  label?: string;
  // eslint-disable-next-line react/require-default-props
  text?: string;
  // eslint-disable-next-line react/require-default-props
  href?: string;
  title: string;
}

const IntegrateLabelLink: React.FC<IIntegrateLabelLinkProps> = ( props ) => {
  const {
    label = '', text = 'N/A', href, title = '',
  } = props;
  return (
    <div>
      <LabelInfo
        label={label}
        text={text}
      />
      <Link
        href={href}
        title={title}
      />
    </div>
  );
};

export default IntegrateLabelLink;
