import React from 'react';
import { useHistory } from 'react-router-dom';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { InjectedFormProps } from 'redux-form';
import { Grid } from '@material-ui/core';
import { RootState } from '../../../redux/store';

// components
import UnitForm from '../../../components/UnitForm/UnitForm';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { actions } from '../../../redux/ducks/UnitDucks';
// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';

// Interfaz
import { IUnit } from '../../../interfaces/IUnit';
import { IBook } from '../../../interfaces/IBook';
import Card from '../../../components/Card/Card';

const UnitCreate: React.FC<InjectedFormProps> = ( ) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const booksInStore: IBook[] = useSelector(
    ( state: RootState ) => state.book.books,
  );

  const handleSubmit = ( values: any ): void => {
    const book: any = booksInStore.find(
      ( item: IBook ) => item.id === values.bookId,
    );
    const { ...data } = values;
    const unit: IUnit = { ...data };
    dispatch( actions.createUnit( unit, book ));
    return history.push( '/admin/unidades' );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <ContainerBreadcrumbs>
            <Breadcrumbs
              itemsLinks={[{ name: 'Unidades', url: 'admin/unidades' }]}
              itemsText={['Crear unidad']}
            />
          </ContainerBreadcrumbs>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <UnitForm onSubmit={handleSubmit} />
        </Card>
      </Grid>
    </Grid>
  );
};

export default UnitCreate;
