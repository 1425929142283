import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import Breadcrums from '../../../components/Breadcrumbs/Breadcrumbs';
import { RootState } from '../../../redux/store';
import LabelInfo from '../../../components/LabelInfo/LabelInfo';
import { getSubjectByIdThunk } from '../../../redux/ducks/SubjectDucks';
import { actions } from '../../../redux/ducks/CourseDucks';
import { ITeacher } from '../../../interfaces/ITeacher';
import Card from '../../../components/Card/Card';

interface IParams {
  id: string;
}

const SubjectView: React.FC = () => {
  const dispatch = useDispatch();
  const { formData, loading } = useSelector((
    state: RootState,
  ) => state.subject );
  const { courseById: course } = useSelector((
    state: RootState,
  ) => state.course );
  const { teachers } = useSelector(( state: RootState ) => state.teacher );
  const { books } = useSelector(( state: RootState ) => state.book );
  const { id } = useParams<IParams>();

  useEffect(() => {
    if ( id ) {
      dispatch( getSubjectByIdThunk( id ));
    }
  }, []);

  useEffect(() => {
    if ( formData ) {
      dispatch( actions.getCourse( formData.courseId ));
    }
  }, [formData]);

  const handleGetNameTeacher = ( idDoc: string ): string => {
    const userName = teachers.find(( x: ITeacher ) => x.id === idDoc );
    return userName ? `${userName.name} ${userName.surname}` : '';
  };

  const handleGetNameBook = ( idDoc: string ): string => {
    const book = books.find(( x: ITeacher ) => x.id === idDoc );
    return book ? `${book.name}` : '';
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <ContainerBreadcrumbs>
            <Breadcrums
              itemsLinks={[{ name: 'Materias', url: 'admin/materias' }]}
              itemsText={['Información de la materia']}
            />
          </ContainerBreadcrumbs>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          {!loading && formData && (
            <Grid container>
              <Grid item xs={12} sm={8}>
                <Grid container>
                  <Grid item xs={12} sm={6} md={6}>
                    <LabelInfo
                      label="Nombre"
                      text={formData.name}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <LabelInfo
                      label="Descripción"
                      text={formData.description}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <LabelInfo
                      label="Docente"
                      text={handleGetNameTeacher( formData.teacherId )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <LabelInfo
                      label="Curso"
                      text={course.name}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <LabelInfo
                      label="Enlace para Zoom"
                      text={formData.urlZoom}
                      noWrap
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <LabelInfo
                  label="Libros utilizados"
                  text=""
                  noWrap
                />
                {formData.books
                  ? formData.books.map(( item: string, index: number ) => (
                    <div key={index.toString()}>
                      <Link to={`/admin/libros/ver/${item}`}>
                        <LabelInfo
                          text={handleGetNameBook( item )}
                        />
                      </Link>
                    </div>
                  ))
                  : (
                    <LabelInfo
                      text="No existen datos"
                    />
                  )}
              </Grid>
            </Grid>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default SubjectView;
