import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { InjectedFormProps } from 'redux-form';
// components
import CourseForm from '../../../components/CourseForm/CourseForm';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { actions } from '../../../redux/ducks/CourseDucks';
import { RootState } from '../../../redux/store';
// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import Spinner from '../../../components/Spinner/Spinner';
import Card from '../../../components/Card/Card';

interface Params {
  id: string;
}

const CourseEdit: React.FC<InjectedFormProps> = () => {
  const dispatch = useDispatch();
  const params = useParams<Params>();
  const history = useHistory();

  const { courseById, loading } = useSelector(
    ( state: RootState ) => state.course,
  );

  const handleSubmit = ( values: any ): void => {
    dispatch( actions.updateCourse( params.id, values ));
    history.push( '/admin/cursos' );
  };

  useEffect(() => {
    if ( params.id ) {
      dispatch( actions.getCourse( params.id ));
    }
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <ContainerBreadcrumbs>
            <Breadcrumbs
              itemsLinks={[{ name: 'Cursos', url: 'admin/cursos' }]}
              itemsText={['Editar Curso']}
            />
          </ContainerBreadcrumbs>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          { !loading
            ? <CourseForm onSubmit={handleSubmit} initialValues={courseById} />
            : (
              <Spinner />
            )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default CourseEdit;
