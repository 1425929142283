import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles(( theme: Theme ) => ({
  errors: {
    display: 'block',
    marginBottom: 10,
    color: theme.palette.error.main,
  },
}));

interface IErrorInputProps {
  // eslint-disable-next-line react/require-default-props
  error?: string;
  // eslint-disable-next-line react/require-default-props
  warning?: string;
}

const ErrorInput: React.FC<IErrorInputProps> = ( props ) => {
  const classes = useStyles();
  const { error = '', warning = '' } = props;

  return (
    <div>
      {( error && <span className={classes.errors}>{error}</span> )
      || ( warning && <span className={classes.errors}>{warning}</span> )}
    </div>
  );
};

export default ErrorInput;
