import React from 'react';
import Image from '../Image/Image';

interface IImageFormProps {
  photo: File | string;
}

const ImageForm: React.FC<IImageFormProps> = ( props ) => {
  const { photo } = props;

  const handleGetPhoto = ( image: any ): string => (
    !String( image ).includes( 'https' )
      ? URL.createObjectURL( image )
      : image
  );

  return (
    <Image src={handleGetPhoto( photo )} />
  );
};

export default ImageForm;
