import React, { useEffect } from 'react';
// Material Ui
import {
  Box,
  Card, CardContent, makeStyles,
} from '@material-ui/core';
// The Calendar Component
import Calendar from 'react-calendar';
// Interface
import { useDispatch, useSelector } from 'react-redux';
import { ITask } from '../../interfaces/ITask';
// Redux
import { RootState } from '../../redux/store';
import {
  getAllTaskByDayInCalendarThunk,
  getAllTaskByFirstDayCalendarThunk,
} from '../../redux/ducks/TaskDucks';
// CSS
import './Calendar.css';

const useStyles = makeStyles(() => ({
  cardContent: {
    overflow: 'auto',
    display: 'flex',
  },
  dayBackground: {
    textAlign: 'center',
    background: '#26ccff',
    fontSize: '3em',
    borderRadius: '0.1em',
    margin: '0 0.5em 0 0',
    color: 'white',
  },
  marginYear: {
    margin: '0 0.5em 0 0',
  },
}));

interface IDyas {
  day: string;
  month: string;
}

const OnlyCalendar: React.FC = () => {
  const classes = useStyles();
  const [value, onChange] = React.useState( new Date());
  const [days] = React.useState<any>([]);
  const dispatch = useDispatch();

  const tasks: ITask[] = useSelector(
    ( state: RootState ) => state.task.tasks,
  );

  const userData = useSelector(( state: RootState ) => state.login.userData );

  const getElementsByText = (): void => {
    days.forEach(( str: IDyas ) => {
      Array.prototype.slice.call(
        document.getElementsByTagName( 'abbr' ),
      )
        .filter(( el ) => el.textContent.trim() === str.day.trim());
      document.querySelector( `abbr[aria-label="${str.month}"]` )
        ?.parentElement?.classList.add( 'bg-tasks' );
    });
  };

  const clickedDay = ( data: Date ): void => {
    getElementsByText();
    dispatch(
      getAllTaskByDayInCalendarThunk( data, userData.id, userData.courseId ),
    );
  };

  useEffect(() => {
    dispatch( getAllTaskByFirstDayCalendarThunk( userData.courseId ));
  }, []);

  useEffect(() => {
    tasks.forEach(( item: ITask ) => {
      const date = item.dateStart.toDate();
      const dateFormat = new Date( date );
      const month = dateFormat.toLocaleString( 'es', { month: 'long' });
      const day = {
        month: `${dateFormat.getDate()} de ${month} de ${dateFormat.getFullYear()}`,
        day: `${dateFormat.getDate()}`,
      };
      days.push( day );
    });
    getElementsByText();
  }, [tasks]);

  return (
    <>
      {tasks && (
        <Card>
          <CardContent className={classes.cardContent}>
            <Box>
              <h1 className={classes.dayBackground}>
                {value.getDate() < 10 ? `0${value.getDate()}` : value.getDate()}
              </h1>
              <h1 className={classes.marginYear}>{value.getFullYear()}</h1>
            </Box>
            <Calendar
              onChange={onChange}
              value={value}
              showNavigation={false}
              locale="es-EC"
              calendarType="US"
              onClickDay={( e ) => clickedDay( e )}
            />
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default OnlyCalendar;
