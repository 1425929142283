import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useDispatch, useSelector } from 'react-redux';
import UserDefault from '../../assets/img/user-photo-default.png';
import Schedule from './components/Schedule/Schedule';
import { RootState } from '../../redux/store';
import { getSubjectsByCourseIdThunk } from '../../redux/ducks/SubjectDucks';
import { IStudent } from '../../interfaces/IStudent';
import Dialog from '../Dialog/Dialog';
import PasswordForm from '../PasswordForm/PasswordForm';
import { size5MB } from '../../helpers/validate-forms';
import { reAutheticateThunk, uploadPhotoFileThunk } from '../../redux/ducks/LoginDucks';
import PhotoUpload from '../FileInput/PhotoUpload';
import { auth } from '../../services/firebase';
import Button from '../Button/Button';

const useStyles = makeStyles(( theme ) => ({
  avatar: {
    width: '5.6em',
    height: '5.6em',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  boldText: {
    margin: '0',
    fontWeight: 'bold',
  },
  cardActions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textTruncate: {
    display: 'block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  margin0: {
    margin: 0,
  },
  shadow: {
    boxShadow: `rgb(0 0 0 / 20%) 0px 10px 10px -4px, 
    rgb(0 0 0 / 14%) 0px 3px 4px 0px, 
    rgb(0 0 0 / 12%) 0px 1px 8px 0px`,
  },
  cardContent: {
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down( 538.98 )]: {
      display: 'block',
    },
  },
}));

interface IProfileDetailProps {
  user: IStudent;
}

const typesFile = ['png', 'jpeg', 'jpg'];

const ProfileDetail : React.FC<IProfileDetailProps> = ( props ) => {
  const classes = useStyles();
  const {
    user,
  } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = useState( false );
  const [openPhoto, setOpenPhoto] = useState( false );
  const [titleDialog, setTitleDialog] = useState<string>( '' );
  const [messageDialog, setMessageDialog] = useState<string>( '' );
  const { subjects } = useSelector(
    ( state: RootState ) => state.subject,
  );
  const handleOpen = (): void => {
    setOpen( !open );
  };

  const handleSubmit = ( values: any ): void => {
    dispatch( reAutheticateThunk(
      values.currentPassword,
      values.newPassword,
    ));
    handleOpen();
  };
  const handleGetFile = ( file: File ): void => {
    const id = auth.currentUser?.uid;
    if ( id ) {
      if ( size5MB( file.size )) {
        const array = file.name.split( '.' );
        const format = array[array.length - 1];
        if ( typesFile.includes( format )) {
          dispatch( uploadPhotoFileThunk( id, file, user.type, user ));
        } else {
          const title = `El formato ( ${format} ) no esta permitido`;
          const msg = `El archivo tiene el formato ( ${format} ) 
          que no es permitido, los formatos aceptados son docx y pdf.`;
          handleOpenDialog( title, msg );
        }
      } else {
        const title = `Tamaño de archivo superior a 5MB`;
        const msg = `El tamaño de archivo elegido supera
        el tamaño maximo que es de 5MB, por favor
        eliga otro archivo o comentelo con su docente.`;
        handleOpenDialog( title, msg );
      }
    }
  };
  const handleOpenDialog = ( title: string, msg: string ): void => {
    setOpenPhoto( !openPhoto );
    setMessageDialog( msg );
    setTitleDialog( title );
  };
  useEffect(() => {
    let isMounted = true;
    if ( isMounted ) {
      if ( user.courseId ) {
        dispatch( getSubjectsByCourseIdThunk( user.courseId ));
      }
    }
    return () => { isMounted = false; };
  }, []);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} md={user.type === 3 ? 5 : 12}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={user.type === 3 ? 12 : 6}>
              <Card className={classes.shadow}>
                <CardContent>
                  <Box display="flex" justifyContent="center">
                    <Box position="relative">
                      <Box
                        position="absolute"
                        bottom="0"
                        right="0"
                        zIndex="111"
                      >
                        <PhotoUpload setFile={handleGetFile} />
                      </Box>
                      <Avatar
                        className={classes.avatar}
                        src={user.photo ? user.photo : UserDefault}
                      />
                    </Box>
                  </Box>
                  <Box display="block" textAlign="center" mt={2}>
                    <p className={classes.boldText}>
                      <span>
                        {user.name ? user.name : 'N/A'}
                        {' '}
                        {user.surname ? user.surname : 'N/A'}
                      </span>
                    </p>
                  </Box>
                </CardContent>
                <CardActions className={classes.cardActions}>
                  <Button
                    color="primary"
                    onClick={
                      () => handleOpen()
                    }
                  >
                    Cambiar contraseña
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} sm={user.type === 3 ? 12 : 6}>
              <Card className={classes.shadow}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <h3>Datos personales</h3>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <p className={classes.margin0}>
                        <span className={classes.boldText}>Nombres: </span>
                        <span className={classes.textTruncate}>
                          {user.name}
                        </span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <p className={classes.margin0}>
                        <span className={classes.boldText}>Apellidos: </span>
                        <span>{user.surname ? user.surname : 'N/A'}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <p className={classes.margin0}>
                        <span className={classes.boldText}>E-Mail: </span>
                        <span className={classes.textTruncate}>
                          {user.email}
                        </span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <p className={classes.margin0}>
                        <span className={classes.boldText}>
                          Representante:
                        </span>
                        <span>
                          {user.representative ? user.representative : 'N/A'}
                        </span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <p className={classes.margin0}>
                        <span className={classes.boldText}>Nro. teléfono </span>
                        <span>{user.phone ? user.phone : 'N/A'}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <p className={classes.margin0}>
                        <span className={classes.boldText}>Nro. Cédula: </span>
                        <span>
                          {user.identification ? user.identification : 'N/A'}
                        </span>
                      </p>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        {user.type === 3 && (
          <Grid item xs={12} md={7}>
            <Card className={classes.shadow}>
              <CardContent className={classes.cardContent}>
                <Schedule subjects={subjects} />
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
      <Dialog
        open={open}
        title="Cambiar contraseña"
        onClose={handleOpen}
        size="xs"
      >
        <PasswordForm
          onSubmit={handleSubmit}
        />
      </Dialog>
      <Dialog
        open={openPhoto}
        title={titleDialog}
        onClose={() => handleOpenDialog( '', '' )}
        size="xs"
      >
        <Box
          flexDirection="column"
          display="flex"
          gridRowGap={10}
        >
          <span>
            {messageDialog}
          </span>
        </Box>
      </Dialog>
    </>
  );
};

export default ProfileDetail;
