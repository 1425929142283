import React, { useEffect } from 'react';

// Material Ui
import {
  Card,
  CardActions,
  makeStyles,
  Grid,
  Box,
  Fab,
} from '@material-ui/core';

// Redux
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { ISubject } from '../../interfaces/ISubject';
import { actions } from '../../redux/ducks/TeacherDucks';

// Icons
import gradeIcon from '../../assets/svg/gradeIcon.svg';
import bookIcon from '../../assets/svg/bookIcon.svg';
import happyFace from '../../assets/svg/happyFace.svg';
import { IUser } from '../../interfaces/IUser';
import ToolTip from '../ToolTip/ToolTip';

interface ISubjectClassTaskItemTeacherProps {
  subject: ISubject | any;
  students: IUser[];
}

const useStyles = makeStyles(( theme: any ) => ({
  shadow: {
    boxShadow: `rgb(0 0 0 / 20%) 0px 10px 10px -4px, 
    rgb(0 0 0 / 14%) 0px 3px 4px 0px, 
    rgb(0 0 0 / 12%) 0px 1px 8px 0px`,
  },
  cardActions: {
    padding: '1em 2em 1em 2em',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  card: {
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  margin: {
    marginRight: '1em',
  },
  svg: {
    maxWidth: '2em',
  },
  happySvg: {
    width: '3.5em',
  },
  exclamationText: {
    margin: '0',
    fontWeight: 'bold',
  },
  containerTask: {
    [theme.breakpoints.down( 'md' )]: {
      textAlign: 'center',
      marginBottom: '1em',
    },
  },
}));

const SubjectClassTaskItemTeacher: React.FC<ISubjectClassTaskItemTeacherProps> = ( props ) => {
  const {
    subject,
    students,
  } = props;

  const classes = useStyles();

  const dispatch = useDispatch();

  const history = useHistory();

  const handleNavigateTo = ( route: string ): void => {
    if ( route ) {
      history.push( route );
    }
  };

  useEffect(() => {
    dispatch( actions.getTeacher( subject.teacherId ));
  }, []);

  return (
    <Grid item xs={12} md={7} lg={4}>
      <Card className={`${classes.shadow} ${classes.card}`}>
        <CardActions className={classes.cardActions}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <Box
                className={classes.containerTask}
                display="flex"
                justifyContent="space-between"
                mt="1em"
              >
                <ToolTip title="Todas las tareas">
                  <Fab
                    className={classes.margin}
                    aria-label="calificaciones"
                    onClick={
                      () => handleNavigateTo(
                        subject.books && subject.books.length > 0 ? `/docente/tareas/detalle/${subject.id}` : '',
                      )
                    }
                  >
                    <img className={classes.svg} src={gradeIcon} alt="icon" />
                  </Fab>
                </ToolTip>
                <ToolTip title="¡Libro de la clase!">
                  <Fab
                    aria-label="book"
                    onClick={
                      () => handleNavigateTo(
                        subject.books && subject.books.length > 0 ? `/docente/libros/ver/${subject.books[0]}` : '',
                      )
                    }
                  >
                    <img className={classes.svg} src={bookIcon} alt="icon" />
                  </Fab>

                </ToolTip>
                <ToolTip title="Total de estudiantes">
                  <Box display="block" textAlign="center" ml={2} mr={2}>
                    <img className={classes.happySvg} src={happyFace} alt="" />
                    <p className={classes.exclamationText}>
                      {students && students.length}
                    </p>
                  </Box>
                </ToolTip>
              </Box>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default SubjectClassTaskItemTeacher;
