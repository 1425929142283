import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 10,
    // border: '1px solid #000',
    padding: 5,
  },
  contentResponse: ( correct ) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    columnGap: 10,
    rowGap: 10,
    fontWeight: 600,
    color: correct ? '#22AD10' : '#CA3232',
  }),
  response: ( correct ) => ({
    backgroundColor: '#fff',
    border: correct ? '2px solid #22AD10' : '2px solid #CA3232',
    borderRadius: 5,
    padding: '5px 10px',
    color: correct ? '#22AD10' : '#CA3232',
  }),
  sentenceItem: {
    marginRight: 5,
  },
  input: {
    backgroundColor: '#fff',
  },
}));

export default useStyles;
