import React, { useState, ChangeEvent } from 'react';
import { Paper, TextField } from '@material-ui/core';
import useStyles from './Styles';
import { IActivity } from '../../../../../../interfaces/IActivity';
import RubricItem from '../RubricItem/RubricItem';

interface IProps {
  // eslint-disable-next-line react/require-default-props
  data?: IActivity[];
  calification: number;
  dataNote: any;
  setCalification: any;
  // eslint-disable-next-line react/no-unused-prop-types
  onCalcTotal: any;
  onAddComment: any;
}

const RubricEvaluation: React.FC<IProps> = ( props ) => {
  const classes = useStyles();
  const {
    calification,
    data = [],
    dataNote,
    setCalification,
    onAddComment,
  } = props;

  const [value, setValue] = useState<string>( dataNote.comment );

  const onChange = ( e: ChangeEvent<HTMLInputElement> ): void => {
    setValue( e.target.value );
    onAddComment( e.target.value );
  };

  const getCalification = ( id: string ): string => {
    const { subNotes } = dataNote;
    const act = subNotes.find(( x: any ) => x.id === id );
    return act ? act.calification.toString() : '0';
  };

  return (
    <div>
      <Paper className={classes.root} elevation={3}>
        <span className={classes.title}>Rubrica de evaluación</span>
        {
          data.map(( item: IActivity, i: number ) => (
            <div key={i.toString()} className={classes.itemRubric}>
              <RubricItem
                idAct={item.id || ''}
                name={item.name}
                rubric={item.rubric}
                calification={getCalification( item.id || '' )}
                setCalification={setCalification}
              />
            </div>
          ))
        }
        <Paper>
          <TextField
            value={value}
            onChange={onChange}
            label="Comentario"
            placeholder="Ingrese un comentario"
            variant="outlined"
            size="small"
            fullWidth
            multiline
            rows={5}
          />
        </Paper>
        <Paper className={classes.footer} elevation={3}>
          <span>Calificación</span>
          <span>{calification}</span>
        </Paper>
      </Paper>
    </div>
  );
};

export default RubricEvaluation;
