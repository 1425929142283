import React, { useEffect } from 'react';
import {
  Box,
  Fab,
  Typography,
  Paper,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { getAllTaskBySubjectIdTeacherThunk } from '../../redux/ducks/TaskDucks';
import { RootState } from '../../redux/store';
import { ITask } from '../../interfaces/ITask';
import Edit from '../../assets/svg/Edit.svg';
import gradeIcon from '../../assets/svg/gradeIcon.svg';
import { timeStampToStringView } from '../../helpers/convert-to-timestamp';
import { useStyles } from './Styles';
import SubjectTaskStatus from '../SubjectTaskStatus/SubjectTaskStatus';
import { IStudent } from '../../interfaces/IStudent';

interface Params {
  id: string;
}

interface ICardHomeworkProps {
  students: IStudent[];
  partial: string;
}

const CardHomework: React.FC<ICardHomeworkProps> = ( props ) => {
  const { students, partial } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const { tasks } = useSelector(( state: RootState ) => state.task );

  const params = useParams<Params>();

  const history = useHistory();

  const handleNavigateTo = ( route: string ): void => {
    if ( route ) {
      history.push( route );
    }
  };

  useEffect(() => {
    dispatch( getAllTaskBySubjectIdTeacherThunk( params.id ));
  }, []);

  return (
    tasks && tasks.length > 0
    && tasks.map(( task: ITask, i: number ) => (
      task.partial === partial
      && (
        <Box
          className={classes.root}
          key={i.toString()}
        >
          <Paper
            className={`${classes.card} ${classes.taskColumn}`}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              flexWrap="wrap"
              gridColumnGap={10}
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                flexWrap="wrap"
                gridColumnGap={10}
                gridRowGap={10}
              >
                <Fab
                  className={classes.fabEdit}
                  onClick={
                    () => handleNavigateTo(
                      task ? `/docente/tareas/editar/${task.id}` : '',
                    )
                  }
                >
                  <img
                    className={classes.svgEdit}
                    src={Edit}
                    alt="editHomework"
                  />
                </Fab>
                <Fab
                  className={classes.fabEdit}
                  onClick={
                    () => handleNavigateTo(
                      task
                        ? `/docente/tareas/calificaciones/${task.id}`
                        : '',
                    )
                  }
                >
                  <img
                    className={classes.svgEdit}
                    src={gradeIcon}
                    alt="editHomework"
                  />
                </Fab>
              </Box>
              <Typography
                color="textSecondary"
              >
                {task.title}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              gridColumnGap={10}
              flexWrap="wrap"
            >
              <Typography
                color="textSecondary"
              >
                {timeStampToStringView( task.dateStart.seconds )}
              </Typography>
              <Typography
                color="textSecondary"
                className={classes.marginLeft}
              >
                hasta
              </Typography>
              <Typography
                color="textSecondary"
                className={classes.marginLeft}
              >
                {timeStampToStringView( task.dateEnd.seconds )}
              </Typography>
            </Box>
          </Paper>
          <Paper
            className={`${classes.card} ${classes.taskStatusColumn}`}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexWrap="wrap"
              gridColumnGap={10}
            >
              {
                students && task && (
                  <SubjectTaskStatus
                    students={students || []}
                    task={task}
                    iconClasses={classes.svg}
                    iconTextClasses={classes.exclamationText}
                  />
                )
              }
            </Box>
          </Paper>
        </Box>
      )
    ))
  );
};

export default CardHomework;
