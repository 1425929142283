import { db } from './firebase';
import { IScheme } from '../interfaces/IScheme';

const COLLECTION_SCHEME = 'scheme';

export const createNoteInScheme = async (
  idScheme: string, idStudent: string, data: any,
): Promise<boolean> => {
  try {
    await db.collection( COLLECTION_SCHEME )
      .doc( idScheme )
      .collection( 'notes' )
      .doc( idStudent )
      .set( data );
    return true;
  } catch ( error ) {
    //
    return false;
  }
};

export const getNoteInScheme = async (
  idScheme: string, idStudent: string,
): Promise<number> => {
  try {
    const note = await db.collection( COLLECTION_SCHEME )
      .doc( idScheme )
      .collection( 'notes' )
      .doc( idStudent )
      .get();
    if ( note.exists ) {
      const { noteFinal } = note.data() as any;
      return noteFinal;
    }
    return 0;
  } catch ( error ) {
    //
    return 0;
  }
};

export const getSchemesByPartial = async (
  index: number,
  idSubject: string,
): Promise<IScheme[]> => {
  try {
    const schemes: any[] = [];
    const data = await db.collection( COLLECTION_SCHEME )
      .where( 'indexPartial', '==', index )
      .where( 'idSubject', '==', idSubject )
      .where( 'deleted', '==', false )
      .get();
    if ( data.empty ) {
      return schemes;
    }
    const { docs } = data;
    docs.forEach(( item ) => {
      schemes.push({ ...item.data(), id: item.id });
    });
    return schemes;
  } catch ( err ) {
    return [];
  }
};

export const getAllNotesInScheme = async (
  idScheme: string,
): Promise<any[]> => {
  try {
    const notes = await db.collection( COLLECTION_SCHEME )
      .doc( idScheme )
      .collection( 'notes' )
      .get();
    if ( !notes.empty ) {
      const data: any[] = [];
      notes.docs.forEach(( doc ) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      return data;
    }
    return [];
  } catch ( error ) {
    //
    return [];
  }
};

export const getSchemeDetail = async ( id: string ): Promise<any> => {
  try {
    const collection = await db.collection( 'scheme' )
      .doc( id ).get();
    let scheme = {};
    if ( collection.exists ) {
      const data = collection.data();
      scheme = { ...data, id };
    }
    return scheme;
  } catch ( error ) {
    // eslint-disable-next-line no-console
    console.log( error );
    return {};
  }
};

export const getSchemesByPeriod = async (
  idPeriod: string,
  idSubject: string,
): Promise<IScheme[]> => {
  try {
    const schemes: any[] = [];
    const data = await db.collection( COLLECTION_SCHEME )
      .where( 'idPeriod', '==', idPeriod )
      .where( 'idSubject', '==', idSubject )
      .where( 'deleted', '==', false )
      .get();
    if ( data.empty ) {
      return schemes;
    }
    const { docs } = data;
    docs.forEach(( item ) => {
      schemes.push({ ...item.data(), id: item.id });
    });
    return schemes;
  } catch ( err ) {
    return [];
  }
};
