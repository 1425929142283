import React from 'react';
import { Link } from 'react-router-dom';

// Material UI
import { Box, Grid, makeStyles } from '@material-ui/core';

// Components
import Card from '../Card/Card';
import Spinner from '../Spinner/Spinner';

// Icons
import studentIcon from '../../assets/svg/studentIcon.svg';
import teacherIcon from '../../assets/svg/teacherIcon.svg';
import { IUser } from '../../interfaces/IUser';

interface ICourseUsersProps {
  loading: boolean;
  users: any;
}

const useStyles = makeStyles({
  noDecoration: {
    textDecoration: 'none',
    color: '#000',
  },
});

const CourseUsers: React.FC<ICourseUsersProps> = ( props ) => {
  const classes = useStyles();
  const {
    loading = false,
    users,
  } = props;

  return (
    <Card>
      {loading ? (
        <Spinner />
      ) : (
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <img src={studentIcon} alt="students" />
              <span>Estudiantes</span>
            </Box>
            {users.map(( item: IUser, i: number ) => (
              item.type === 3 && (
                <Link
                  key={i.toString()}
                  to={`/admin/estudiantes/ver/${item.id}`}
                  className={classes.noDecoration}
                >
                  <p>
                    {item.name}
                    {' '}
                    {' '}
                    {item.surname}
                  </p>
                </Link>
              )
            ))}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <img src={teacherIcon} alt="teachers" />
              <span>Maestros</span>
            </Box>
            {users.map(( item: IUser, i: number ) => (
              item.type === 2 && (
                <Link
                  key={i.toString()}
                  to={`/admin/docentes/ver/${item.id}`}
                  className={classes.noDecoration}
                >
                  <p>
                    {item.name}
                    {' '}
                    {' - '}
                    {' '}
                    {item.surname}
                  </p>
                </Link>
              )
            ))}
          </Grid>
          <Grid item xs={12} sm={6} />
        </Grid>
      )}
    </Card>
  );
};

export default CourseUsers;
