import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 10,
    // border: '1px solid #000',
    padding: 5,
  },
  response: {
  },
  sentenceItem: {
    marginRight: 5,
  },
  input: {
    backgroundColor: '#fff',
  },
}));

export default useStyles;
