import React from 'react';
import {
  FormControl, makeStyles, MenuItem, InputLabel,
} from '@material-ui/core';
import SelectMaterial from '@material-ui/core/Select';
import { WrappedFieldProps } from 'redux-form';
import ErrorInput from '../ErrorInput/ErrorInput';

const useStyles = makeStyles(( ) => ({
  input: {
    width: '100%',
    marginBottom: 10,
    // borderRadius: 5,
    // border: '2px solid #ddd',
    // padding: '10px 15px',
  },
}));

interface IInputProps {
  // eslint-disable-next-line react/require-default-props
  placeholder?: string;
  // eslint-disable-next-line react/require-default-props
  label?: string;
  // eslint-disable-next-line react/require-default-props
  children?: JSX.Element | JSX.Element[];
  // eslint-disable-next-line react/require-default-props
  options?: any[];
}

const Select: React.FC<WrappedFieldProps & IInputProps> = ( props ) => {
  const classes = useStyles();
  const {
    input,
    meta,
    options = [],
    label = '',
    children,
  } = props;
  const { touched, error = '', warning } = meta;

  return (
    <FormControl
      variant="outlined"
      className={classes.input}
      error={error.length > 0 && touched}
      size="small"
      margin="none"
    >
      <InputLabel htmlFor={`${label}-demo-simple-select-label`}>
        {label}
      </InputLabel>
      <SelectMaterial
        {...input}
        label={label}
        inputProps={{
          name: label,
          id: `${label}-demo-simple-select-label`,
        }}
        fullWidth
        variant="outlined"
      >
        {children || options.map(( item: any, index: number ) => (
          <MenuItem
            key={index.toString()}
            value={item.id}
          >
            {item.name}
          </MenuItem>
        ))}
      </SelectMaterial>
      {touched
        && <ErrorInput error={error} warning={warning} />}
    </FormControl>
  );
};

export default Select;
