import React from 'react';
import useStyles from './Styles';
import { IActivity } from '../../../../../../interfaces/IActivity';

interface IProps {
  data: IActivity;
  response: string;
}

const ItemNormal: React.FC<IProps> = ( props ) => {
  const classes = useStyles();
  const { data, response } = props;
  // eslint-disable-next-line no-console
  console.log( data );
  // eslint-disable-next-line no-console
  console.log( response );
  return (
    <div className={classes.root}>
      <span>Tarea: </span>
      {data.guideUrl
        ? (
          <a href={data.guideUrl} className={classes.link} download>
            Descargar Indicaciones
          </a>
        )
        : <p>No ha subido indicaciones.</p>}
      <span>Respuesta: </span>
      <a href={response} className={classes.link} download>
        Descargar Documento
      </a>
    </div>
  );
};

export default ItemNormal;
