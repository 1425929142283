import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles(( theme: Theme ) => ({
  iconButton: ( primary ) => ({
    backgroundColor: primary ? theme.palette.primary.main : '',
    '&:hover': {
      backgroundColor: primary ? theme.palette.primary.dark : '',
    },
  }),
}));

interface IButtonIconProps {
  // eslint-disable-next-line react/require-default-props
  children?: JSX.Element;
  // eslint-disable-next-line react/require-default-props
  handleClick?: any;
  // eslint-disable-next-line react/require-default-props
  primary?: boolean;
}

const ButtonIcon: React.FC<IButtonIconProps> = ( props ) => {
  const { children, handleClick, primary = false } = props;
  const classes = useStyles( primary );

  return (
    <IconButton
      className={classes.iconButton}
      aria-label="delete"
      onClick={handleClick}
    >
      { children }
    </IconButton>
  );
};

export default ButtonIcon;
