import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import StudentForm from '../../../components/StudentForm/StudentForm';
import {
  getStudentByIdThunk,
  updateStudentThunk,
  actions,
} from '../../../redux/ducks/StudentDucks';
// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import Breadcrums from '../../../components/Breadcrumbs/Breadcrumbs';
import { RootState } from '../../../redux/store';
import { IStudent } from '../../../interfaces/IStudent';
import Spinner from '../../../components/Spinner/Spinner';
import Card from '../../../components/Card/Card';

interface IParams {
  id: string;
}

const StudentEdit = (): JSX.Element => {
  const dispatch = useDispatch();
  const { formData, loading } = useSelector((
    state: RootState,
  ) => state.student );
  const { id } = useParams<IParams>();
  const history = useHistory();

  const handlerSubmit = ( values: any ): void => {
    const { photo, ...data } = values;
    const student = { ...data, id } as IStudent;
    if ( !String( photo ).includes( 'https' )) {
      dispatch( updateStudentThunk( student, photo ));
    } else {
      dispatch( updateStudentThunk( student, undefined ));
    }
    dispatch( actions.getStudentSuccess());
    history.push( '/admin/estudiantes' );
  };

  useEffect(() => {
    if ( id ) {
      // dispatch( reset( 'student' ));
      dispatch( getStudentByIdThunk( id ));
    }
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <ContainerBreadcrumbs>
            <Breadcrums
              itemsLinks={[{ name: 'Estudiantes', url: 'admin/estudiantes' }]}
              itemsText={['Editar estudiante']}
            />
          </ContainerBreadcrumbs>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          { !loading
            ? <StudentForm onSubmit={handlerSubmit} initialValues={formData} />
            : (
              <Spinner />
            )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default StudentEdit;
