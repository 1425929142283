import React from 'react';
import { Paper } from '@material-ui/core';
import useStyles from './Styles';
import { IActivity } from '../../../../../interfaces/IActivity';

interface IProps {
  // eslint-disable-next-line react/require-default-props
  data?: IActivity[];
  calification: number;
}

const RubricEvaluation: React.FC<IProps> = ( props ) => {
  const classes = useStyles();
  const { calification, data = [] } = props;

  return (
    <div>
      <Paper className={classes.root} elevation={3}>
        <span className={classes.title}>Rubrica de evaluación</span>
        {
          data.map(( item: IActivity, i: number ) => (
            <div key={i.toString()} className={classes.itemRubric}>
              <span className={classes.titleItem}>
                {item.name}
              </span>
              <div className={classes.row}>
                <span className={classes.rubric}>
                  {item.rubric}
                </span>
                <Paper className={classes.calification} elevation={3}>
                  {item.calification}
                </Paper>
              </div>
            </div>
          ))
        }
        <Paper className={classes.footer} elevation={3}>
          <span>Calificación</span>
          <span>{calification}</span>
        </Paper>
      </Paper>
    </div>
  );
};

export default RubricEvaluation;
