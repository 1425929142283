import { auth, emailAuthProvider } from './firebase';

export const reauthenticate = ( currentPassword : string ): any => {
  const user = auth.currentUser;
  const email = auth.currentUser?.email || '';
  const cred = emailAuthProvider.credential(
    email, currentPassword,
  );
  return user?.reauthenticateWithCredential( cred );
};
