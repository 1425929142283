import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import InputToUpload from '../../../../../components/FileInput/InputToUpload';
import useStyles from './Styles';
import { IActivity } from '../../../../../interfaces/IActivity';
import { actions } from '../../../../../redux/ducks/ActivityDucks';
import { RootState } from '../../../../../redux/store';
import Spinner from '../../../../../components/Spinner/Spinner';
import { size5MB } from '../../../../../helpers/validate-forms';
import Dialog from '../../../../../components/Dialog/Dialog';

// eslint-disable-next-line max-len
const typesFile = ['doc', 'docx', 'pdf', 'png', 'jpeg', 'ppt', 'pptx', 'xls', 'xlsx'];

interface IProps {
  data: IActivity;
  onSave: any;
}

const ItemNormal: React.FC<IProps> = ( props ) => {
  const classes = useStyles();
  const { data, onSave } = props;
  const { userData } = useSelector(( state: RootState ) => state.login );
  const dispatch = useDispatch();
  const { loadingAnswer, urlActNormalTemp } = useSelector(
    ( state: RootState ) => state.activity,
  );

  const [open, setOpen] = useState( false );
  const [titleDialog, setTitleDialog] = useState<string>( '' );
  const [messageDialog, setMessageDialog] = useState<string>( '' );

  useEffect(() => {
    onSave( urlActNormalTemp );
  }, [urlActNormalTemp]);

  const handleGetFile = ( file: File ): void => {
    const { id } = data;
    // eslint-disable-next-line no-console
    if ( id ) {
      if ( size5MB( file.size )) {
        const array = file.name.split( '.' );
        const format = array[array.length - 1];
        if ( typesFile.includes( format )) {
          dispatch( actions.uploadAnswerFileThunk( `${id}-${userData.id}`, file ));
        } else {
          const title = `El formato ( ${format} ) no esta permitido`;
          const msg = `El archivo tiene el formato ( ${format} ) 
          que no es permitido, los formatos aceptados son doc, docx, xlsx, xls,
          ppt,pptx, pdf, jpg y png.`;
          handleOpenDialog( title, msg );
        }
      } else {
        const title = `Tamaño de archivo superior a 5MB`;
        const msg = `El tamaño de archivo elegido supera
        el tamaño maximo que es de 5MB, por favor
        eliga otro archivo o comentelo con su docente.`;
        handleOpenDialog( title, msg );
      }
    }
  };

  const handleOpenDialog = ( title: string, msg: string ): void => {
    setOpen( !open );
    setMessageDialog( msg );
    setTitleDialog( title );
  };

  return (
    <div className={classes.root}>
      {data.guideUrl
        ? (
          <a href={data.guideUrl} className={classes.link} download>
            Descargar Indicaciones
          </a>
        )
        : <p>EL docente no ha subido el archivo de indicaciones.</p>}

      <span>Respuesta: </span>
      {!open
      && <InputToUpload setFile={handleGetFile} />}
      {loadingAnswer && <Spinner />}
      <Dialog
        open={open}
        title={titleDialog}
        onClose={handleOpenDialog}
        size="xs"
      >
        <Box
          flexDirection="column"
          display="flex"
          gridRowGap={10}
        >
          <span>
            {messageDialog}
          </span>
        </Box>
      </Dialog>
    </div>
  );
};

export default ItemNormal;
