/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import {
  Paper,
} from '@material-ui/core';
import { IScheme } from '../../../../../../../../interfaces/IScheme';
import {
  getSchemeDetail,
} from '../../../../../../../../services/Scheme.Services';
import ColorGrade from '../../../../../../../../components/ColorGrade/ColorGrade';
import { useStyles } from './Styles';

interface IAccordeon {
  report: any;
}
const AccordionItem: React.FC<IAccordeon> = ( props ) => {
  const classes = useStyles();
  const { report } = props;
  const [scheme, setScheme] = useState<IScheme>();

  const handleGetData = async (): Promise<void> => {
    const data = await getSchemeDetail(
      report.idScheme,
    );
    setScheme( data );
  };

  useEffect(() => {
    let isMountend = false;
    if ( !isMountend ) {
      handleGetData();
    }
    return () => { isMountend = true; };
  }, [props]);

  return (
    <div className={classes.squeme}>
      <Paper className={classes.comlumn1} elevation={0}>
        {scheme?.name ? scheme?.name : ''}
        {' - '}
        {scheme?.description ? scheme?.description : ''}
      </Paper>
      <Paper className={classes.comlumn2} elevation={0}>
        <ColorGrade number={report.note} />
      </Paper>
    </div>
  );
};

export default AccordionItem;
