import React from 'react';
import { Typography } from '@material-ui/core';
import useStyles from './Styles';

interface ILabelInfoProps {
  // eslint-disable-next-line react/require-default-props
  label?: string;
  // eslint-disable-next-line react/require-default-props
  text?: string;
  // eslint-disable-next-line react/require-default-props
  noWrap?: boolean;
}

const LabelInfo: React.FC<ILabelInfoProps> = ( props ) => {
  const classes = useStyles();
  const { label, text = '', noWrap } = props;
  return (
    <div>
      <Typography className={classes.label}>{label}</Typography>
      <Typography noWrap={noWrap}>
        {text}
      </Typography>
    </div>
  );
};

export default LabelInfo;
