import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    columnGap: 20,
    rowGap: 10,
    flexWrap: 'wrap',
    marginBottom: 10,
  },
  card: {
    borderRadius: 10,
    padding: 10,
  },
  taskColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  taskStatusColumn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  svgEdit: {
    maxWidth: '1.5em',
  },
  fabEdit: {
    padding: '0!important',
    marginRight: '1em!important',
    maxHeight: '2.5em',
    maxWidth: '2.5em',
    minHeight: '2.5em',
    minWidth: '2.5em',
  },
  svgAdd: {
    width: '3.5em',
  },
  buttonAdd: {
    // height: '5em',
    // width: '5em',
    // background: 'white',
  },
  exclamationText: {
    margin: '0',
    fontWeight: 'bold',
  },
  svg: {
    maxWidth: '2em',
    minWidth: '2em',
  },
  maxHeightGrades: {
    // maxHeight: '5.8em',
  },
  minHeightGradesInfo: {
    // minHeight: '5.8em',
  },
  marginLeft: {
    // marginLeft: '1em',
  },
});
