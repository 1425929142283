const getRoute = ( type: number ): string => {
  switch ( type ) {
    case 1:
      return 'admin';
    case 2:
      return 'docente';
    case 3:
      return 'estudiante';
    default:
      return '';
  }
};

export {
  getRoute,
};
