import React, { useEffect } from 'react';
import {
  reduxForm, InjectedFormProps, Field, formValueSelector,
  change,
} from 'redux-form';
import { Grid } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import {
  required,
  minLength5, maxLength15, double,
} from '../../helpers/validate-forms';
import Input from '../Input/Input';
import Button from '../Button/Button';
import CenterLine from '../Center/CenterInLine';
import FileInput from '../FileInput/FileInput';
import ContainerForm from '../ContainerForm/ContainerForm';
import FormRedux from '../FormRedux/FormRedux';
import ContainerInputs from '../ContainerInputs/ContainerInputs';
import Select from '../Select/Select';
import { RootState } from '../../redux/store';

export const activityTypes = [
  { id: 'normal', name: 'Normal' },
  { id: 'interactive', name: 'Interactiva' },
];

export const subTypes = [
  { id: 'complete', name: 'Completar' },
  { id: 'dragAndDrop', name: 'Arrastrar y soltar (palabras)' },
  { id: 'openQuestion', name: 'Pregunta abierta' },
];

const ActivityForm: React.FC<InjectedFormProps> = ( props ) => {
  const selector = formValueSelector( 'activity' );
  const type = useSelector(( state: RootState ) => selector( state, 'type' ));
  const dispatch = useDispatch();
  const {
    handleSubmit, submitting,
  } = props;

  useEffect(() => {
    if ( type === activityTypes[0].id ) {
      dispatch( change( 'activity', 'subtype', '' ));
    }
  }, [type]);

  return (
    <ContainerForm>
      <FormRedux onSubmit={handleSubmit}>
        <ContainerInputs>
          <Grid item xs={12} sm={6}>
            <Field
              name="name"
              type="text"
              label="Nombre"
              component={Input}
              placeholder="Nombre"
              validate={[required, minLength5]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="rubric"
              type="text"
              label="Rubrica"
              component={Input}
              validate={[required]}
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="description"
              type="text"
              label="Descripción"
              component={Input}
              validate={[required]}
              multiline
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="type"
              type="text"
              label="Tipo de actividad"
              component={Select}
              validate={[required]}
              options={activityTypes}
            />
          </Grid>
          { type === activityTypes[1].id && (
            <Grid item xs={12} sm={6}>
              <Field
                name="subtype"
                type="text"
                label="Sub tipo"
                component={Select}
                validate={[required]}
                options={subTypes}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <Field
              name="maxGrade"
              type="text"
              label="Nota máxima"
              component={Input}
              placeholder="Ingrese la nota máxima"
              validate={[required, maxLength15, double]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="guideDocument"
              type="file"
              component={FileInput}
              label="Elija un archivo"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <CenterLine>
              <Button
                type="submit"
                disabled={submitting}
              >
                Enviar
              </Button>
            </CenterLine>
          </Grid>
        </ContainerInputs>
      </FormRedux>
    </ContainerForm>
  );
};

export default reduxForm({
  form: 'activity',
})( ActivityForm );
