// Material Ui
import {
  Box,
  Card,
  CardContent,
  Grid,
} from '@material-ui/core';
// Interface
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { ITask } from '../../../interfaces/ITask';
// Redux
import { RootState } from '../../../redux/store';
// Components
import TaskItemStudent from '../../../components/TaskItemStudent/TaskItemStudent';
import OnlyCalendar from '../../../components/Calendar/Calendar';
import { getAllTaskByDayInCalendarThunk } from '../../../redux/ducks/TaskDucks';
import Spinner from '../../../components/Spinner/Spinner';

const StudentCalendar: React.FC = () => {
  const formData: ITask[] = useSelector(
    ( state: RootState ) => state.task.taskByDay,
  );

  const [date] = useState( new Date());

  const dispatch = useDispatch();

  const { userData } = useSelector((
    state: RootState,
  ) => state.login );

  const { loading } = useSelector((
    state: RootState,
  ) => state.task );

  useEffect(() => {
    dispatch(
      getAllTaskByDayInCalendarThunk( date, userData.id, userData.courseId ),
    );
  }, []);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} md={5}>
          <OnlyCalendar />
        </Grid>
        <Grid item xs={12} md={7}>
          <Grid container spacing={4} direction="row" justify="center">
            {loading ? (
              <Box mt={4}>
                <Spinner />
              </Box>
            ) : (
              <>
                {formData && formData.length > 0 ? formData.map(( task: ITask, i: number ) => (
                  <Grid item xs={12} key={i.toString()}>
                    <TaskItemStudent task={task} />
                  </Grid>
                ))
                  : (
                    <Grid item xs={12}>
                      <Card>
                        <CardContent>
                          <strong>No hay datos! Prueba cargando presionando en los días del calendario.</strong>
                        </CardContent>
                      </Card>
                    </Grid>
                  )}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default StudentCalendar;
