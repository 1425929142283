import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(( theme ) => ({
  red: {
    color: '#FF2950',
    fontSize: 20,
    fontWeight: 600,
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: 20,
    },
  },
  yellow: {
    color: '#F99307',
    fontSize: '2em',
    fontWeight: 600,
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '2em',
    },
  },
  green: {
    color: '#27890D',
    fontSize: '2em',
    fontWeight: 600,
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '2em',
    },
  },
  blue: {
    color: '#249FF2',
    fontSize: '2em',
    fontWeight: 600,
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '2em',
    },
  },
  margin: {
    margin: '0!important',
  },
}));
