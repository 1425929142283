import React, { useState, ChangeEvent } from 'react';
import { reduxForm, InjectedFormProps, Field } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box, Grid, TextField, Typography,
} from '@material-ui/core';
import CenterInLine from '../../../../components/Center/CenterInLine';
import Image from '../../../../components/Image/Image';
import Alert from '../../../../components/Alert/Alert';
import FormRedux from '../../../../components/FormRedux/FormRedux';
import Input from '../../../../components/Input/Input';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import Button from '../../../../components/Button/Button';
import {
  email,
  minLength8,
  required,
} from '../../../../helpers/validate-forms';
import LogoMyBook from '../../../../assets/img/LogoMyBook.png';
import { RootState } from '../../../../redux/store';
import Dialog from '../../../../components/Dialog/Dialog';
import useStyles from './Styles';
import {
  sendEmailResetPasswordThunk,
} from '../../../../redux/ducks/LoginDucks';

const LoginForm: React.FC<InjectedFormProps> = ( props ) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState( false );
  const [emailValue, setEmailValue] = useState<string>( '' );
  const [errorEmail, setErrorEmail] = useState<string>( '' );
  const { errors } = useSelector(( state: RootState ) => state.login );
  const { handleSubmit, submitting } = props;

  const handleSendEmail = (): void => {
    if ( required( emailValue ) !== undefined ) {
      validateEmail( emailValue );
      return;
    }
    dispatch( sendEmailResetPasswordThunk( emailValue ));
    handleOpenDialog();
    setEmailValue( '' );
  };

  const handleOpenDialog = (): void => {
    setOpen( !open );
  };

  const handleOnChange = ( e: ChangeEvent<HTMLInputElement> ): void => {
    validateEmail( e.target.value );
    setEmailValue( e.target.value );
  };

  const validateEmail = ( value: string ): void => {
    if ( required( value ) !== undefined || email( value )) {
      setErrorEmail( required( value ) || email( value ) || '' );
    } else {
      setErrorEmail( '' );
    }
  };

  return (
    <div className={classes.containerForm}>
      <Grid container justify="center">
        <Grid item xs={8} sm={6}>
          <CenterInLine>
            <div className={classes.image}>
              <Image src={LogoMyBook} />
            </div>
          </CenterInLine>
        </Grid>
      </Grid>
      <br />
      {errors && <Alert errors={errors} type="error" />}
      <FormRedux onSubmit={handleSubmit}>
        <Grid container spacing={1} justify="center">
          <Grid item xs={9} sm={8}>
            <Field
              name="email"
              type="email"
              label="Correo"
              component={Input}
              placeholder="Correo electrónico"
              validate={[required, email]}
            />
          </Grid>
          <Grid item xs={9} sm={8}>
            <Field
              name="password"
              type="password"
              label="Contraseña"
              component={Input}
              placeholder="Contraseña"
              validate={[required, minLength8]}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} justify="center">
          <Grid item xs={9} sm={8}>
            <Field
              name="rememberme"
              component={Checkbox}
              title="Recuerda mi usuario"
              type="checkbox"
            />
          </Grid>
          <Grid item xs={9} sm={8}>
            <CenterInLine>
              <Button type="submit" disabled={submitting}>
                Ingresar
              </Button>
            </CenterInLine>
          </Grid>
          <Grid item xs={10} sm={8}>
            <CenterInLine>
              <Typography
                className={classes.link}
                onClick={handleOpenDialog}
              >
                Olvide mi contraseña
              </Typography>
            </CenterInLine>
          </Grid>
        </Grid>
      </FormRedux>

      <Typography className={classes.version} align="center">
        v0.2.7
      </Typography>

      <Dialog
        open={open}
        title="Ingrese su correo por favor"
        onClose={handleOpenDialog}
        onConfirm={handleSendEmail}
        size="xs"
      >
        <Box
          flexDirection="column"
          display="flex"
          gridRowGap={10}
        >
          <span>
            Se le enviará un mensaje a su correo
            con el enlace de recuperación de su contraseña.
          </span>
          <TextField
            label="Correo"
            placeholder="Correo electrónico"
            variant="outlined"
            value={emailValue}
            onChange={handleOnChange}
            size="small"
            error={errorEmail.length > 0}
            helperText={errorEmail}
          />
        </Box>
      </Dialog>
    </div>
  );
};

export default reduxForm({
  form: 'login',
})( LoginForm );
