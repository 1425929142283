import React, { useState, useEffect } from 'react';
import * as IconM from '@material-ui/icons/';
import { useDrop } from 'react-dnd';
import CenterInLine from '../../../../../../components/Center/CenterInLine';
import useStyles from './Styles';
import { IQuestion } from '../../../../../../interfaces/IQuestion';

interface IProps {
  response: string;
  title: string;
  id: string;
  data: IQuestion;
}

const BoxQuestion: React.FC<IProps> = ( props ) => {
  const {
    title, id, response, data,
  } = props;
  const [correct, setCorrect] = useState<boolean>( false );

  // eslint-disable-next-line no-unused-vars
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'ITEM',
    drop: () => ({ name: title, id }),
    collect: ( monitor ) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const classes = useStyles( correct );

  const handleCreateQuestion = (): JSX.Element[] => {
    const str = title.split( ' ' );
    return (
      str.map(( item: string, i: number ) => (
        !item.includes( '**' )
          ? (
            <span key={i.toString()} className={classes.sentenceItem}>
              {item}
            </span>
          )
          : (
            <div
              key={i.toString()}
              className={classes.contentResponse}
            >
              <div className={classes.response}>
                <CenterInLine>
                  <span>{response}</span>
                </CenterInLine>
              </div>
              <span>
                {data.optionCorrect}
              </span>
              {
                correct
                  ? <IconM.CheckCircle />
                  : <IconM.HighlightOff />
              }
            </div>
          )
      ))
    );
  };

  useEffect(() => {
    setCorrect( isCorrect());
  }, [data]);

  const isCorrect = (): boolean => {
    const { optionCorrect } = data;
    return optionCorrect?.toUpperCase() === response?.toUpperCase();
  };

  return (
    <div
      ref={drop}
      className={classes.root}
    >
      {handleCreateQuestion()}
    </div>
  );
};

export default BoxQuestion;
