import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import {
  getStudentAllThunk,
  getStudentDeleteThunk,
} from '../../../redux/ducks/StudentDucks';
import { actions } from '../../../redux/ducks/CourseDucks';
import TableStudent from '../../../components/TableStudent/TableStudent';
import { RootState } from '../../../redux/store';
import DialogConfirm from '../../../components/DialogConfirm/DialogConfirm';
// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import Card from '../../../components/Card/Card';

const StudentList = (): JSX.Element => {
  const dispatch = useDispatch();
  const { students } = useSelector(( state: RootState ) => state.student );
  const history = useHistory();
  const [open, setOpen] = useState( false );
  const [idDoc, setIdDoc] = useState( '' );

  useEffect(() => {
    dispatch( getStudentAllThunk());
    dispatch( actions.getCourses());
  }, []);

  const handleOpenConfirm = ( id: string ): void => {
    setIdDoc( id );
    setOpen( true );
  };

  const handleEdit = ( id: string ): void => {
    history.push( `/admin/estudiantes/editar/${id}` );
  };

  const handleDelete = ( confirm: boolean ): void => {
    if ( confirm ) {
      dispatch( getStudentDeleteThunk( idDoc ));
    }
    setOpen( false );
  };

  const handleNew = (): void => {
    history.push( `/admin/estudiantes/agregar` );
  };

  const handleUploadData = (): void => {
    history.push( `/admin/estudiantes/carga-masiva` );
  };

  const handleShow = ( id: string ): void => {
    history.push( `/admin/estudiantes/ver/${id}` );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <ContainerBreadcrumbs>
            <Breadcrumbs
              back={false}
              itemsLinks={[{ name: 'Estudiantes', url: 'admin/estudiantes' }]}
              itemsText={['Todos los estudiantes']}
            />
          </ContainerBreadcrumbs>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <TableStudent
            students={students}
            handleDelete={handleOpenConfirm}
            handleEdit={handleEdit}
            handleNew={handleNew}
            handleUpload={handleUploadData}
            handleShow={handleShow}
          />
        </Card>
      </Grid>
      <DialogConfirm
        open={open}
        title="¿ Desea eliminar al estudiante ?"
        onClose={handleDelete}
      />
    </Grid>
  );
};

export default StudentList;
