import React, { useEffect, useState } from 'react';
// Material Ui
import {
  Card,
  CardActions,
  Typography,
  Grid,
  Box,
  CardHeader,
  Fab,
  CardMedia,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { ITask } from '../../interfaces/ITask';
import {
  formatAmOrPm, formatDateDDMMYY, formatTime, getDateWithSeconds,
} from '../../helpers/convert-to-timestamp';
import Dialog from '../Dialog/Dialog';
import { auth } from '../../services/firebase';
import { useStyles } from './Styles';
import CalificatorStatus from './components/CalificatorStatus/CalificatorStatus';
import TaskNotes from './components/TaskNotes/TaskNotes';

interface ISubjectTaskItemStudentProps {
  task: ITask;
}

const SubjectTaskItemStudent :
React.FC<ISubjectTaskItemStudentProps> = ( props ) => {
  const classes = useStyles();
  const {
    task,
  } = props;
  const history = useHistory();

  const [taskItem, setTaskItem] = useState<any>();
  const [open, setOpen] = useState( false );
  const [messageDialog, setMessageDialog] = useState<string>( '' );
  const [titleDialog, setTitleDialog] = useState( '' );
  const [showNotes, setShowNote] = useState( false );

  useEffect(() => {
    setTaskItem( task );
  }, [props]);

  const handleGetDate = (): string => (
    formatDateDDMMYY(
      getDateWithSeconds( taskItem && taskItem.dateEnd.seconds ),
    )
  );

  const handleGetTime = (): string => (
    `${formatTime(
      getDateWithSeconds( taskItem && taskItem.dateEnd.seconds ),
    )} \
    ${formatAmOrPm(
      getDateWithSeconds( taskItem && taskItem.dateEnd.seconds ),
    )}`
  );

  const handleNavigateTo = ( route: string ): void => {
    if ( auth.currentUser ) {
      const { uid } = auth.currentUser;
      if ( task.students.includes( uid )) {
        const { notes } = task;
        const noteData = notes ? notes[0] : {};
        if ( noteData === undefined || noteData.comment === undefined ) {
          const title = `La tarea aun no a sido calificada`;
          const msg = `Usted ya realizo esta tarea y 
          debe esperar a que sea calificada 
          por el docente que imparte la materia.`;
          setShowNote( false );
          handleOpenDialog( title, msg );
          return;
        }
        const title = `La tarea fue calificada`;
        const msg = ``;
        setShowNote( true );
        handleOpenDialog( title, msg );
        return;
      }
      history.push( route );
    }
  };

  const handleOpenDialog = ( title: string, msg: string ): void => {
    setOpen( !open );
    setMessageDialog( msg );
    setTitleDialog( title );
  };

  return (
    <>
      <Card className={`${classes.shadow} ${classes.card}`}>
        <CardHeader
          className={classes.cardHeader}
          title={(
            <Grid container>
              <Grid item xs={10}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  className={classes.titleContainer}
                >
                  <Typography
                    className={`${classes.title} textTruncate`}
                    color="textSecondary"
                    gutterBottom
                    component="span"
                  >
                    {taskItem && ( taskItem.title )}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={2} className={classes.paperColor}>
                {taskItem
                && taskItem.notes
                && taskItem.notes.length > 0
                  ? ( Math.trunc( taskItem.notes[0].noteFinal )) : '-'}
              </Grid>
            </Grid>
          )}
        />
        <CardMedia
          className={classes.media}
          image="https://papers.co/wallpaper/papers.co-ag78-google-lollipop-january-blue-background-36-3840x2400-4k-wallpaper.jpg"
          title="Ir a la tarea"
          style={{ position: 'relative' }}
        >
          <div className={classes.overlay}>
            <Box
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Fab
                className={classes.playButton}
                aria-label="add"
                onClick={
                  () => handleNavigateTo(
                    `/estudiante/materias/tareas/${taskItem.id}`,
                  )
                }
              >
                <PlayArrowIcon />
              </Fab>
            </Box>
          </div>
        </CardMedia>
        <CardActions>
          <Grid container>
            <Grid item xs={12}>
              <Box p={1}>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  {`Entrega: ${handleGetDate()}`}
                </Typography>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  {`Hora: ${handleGetTime()}`}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                className={`${classes.textBold} ${classes.paddingStatus}`}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <CalificatorStatus taskItem={taskItem} />
              </Box>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
      <Dialog
        open={open}
        title={titleDialog}
        onClose={handleOpenDialog}
        size="xs"
      >
        <Box
          flexDirection="column"
          display="flex"
          gridRowGap={10}
        >
          <span>
            {messageDialog}
          </span>
          {showNotes && (
            <TaskNotes
              task={task}
            />
          )}
        </Box>
      </Dialog>
    </>
  );
};

export default SubjectTaskItemStudent;
