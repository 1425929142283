import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
    flexDirection: 'column',
  },
}));

interface ICenterProps {
  // eslint-disable-next-line react/require-default-props
  children?: JSX.Element | JSX.Element[];
}

const Center: React.FC<ICenterProps> = ( props ) => {
  const classes = useStyles();

  const { children } = props;

  return (
    <div className={classes.center}>
      { children }
    </div>
  );
};

export default Center;
