export const orderByValue = (
  arr: any[], value: string, asc = true,
): any[] => arr.sort(( a, b ) => {
  if ( a[value].toLowerCase() < b[value].toLowerCase()) {
    return asc ? -1 : 1;
  }
  if ( a[value].toLowerCase() > b[value].toLowerCase()) {
    return asc ? 1 : -1;
  }
  // a must be equal to b
  return 0;
});
