import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Material UI
import {
  Grid,
} from '@material-ui/core';

// Components
import Spinner from '../../../components/Spinner/Spinner';

// Redux
import { actions } from '../../../redux/ducks/CourseDucks';
import { RootState } from '../../../redux/store';

// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import Breadcrums from '../../../components/Breadcrumbs/Breadcrumbs';
import LabelInfo from '../../../components/LabelInfo/LabelInfo';
import Card from '../../../components/Card/Card';
import CourseUsers from '../../../components/CourseUsers/CourseUsers';

interface IParams {
  id: string;
}

const CourseView = (): JSX.Element => {
  const dispatch = useDispatch();
  const {
    courseById,
    loading: loadingCourse,
    usersByCourse,
    loadingUsers,
  } = useSelector((
    state: RootState,
  ) => state.course );
  const { id } = useParams<IParams>();

  useEffect(() => {
    if ( id ) {
      dispatch( actions.getCourse( id ));
      dispatch( actions.getUsersByCourseId( id ));
    }
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <ContainerBreadcrumbs>
            <Breadcrums
              itemsLinks={[{ name: 'Cursos', url: 'admin/cursos' }]}
              itemsText={['Ver Curso']}
            />
          </ContainerBreadcrumbs>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>

          {!loadingCourse && courseById ? (
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <LabelInfo label="Nombre" text={courseById.name} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LabelInfo
                      label="Descripción"
                      text={courseById.description}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LabelInfo
                      label="Letra del curso"
                      text={courseById.course}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LabelInfo label="Código (id)" text={courseById.id} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LabelInfo
                      label="Total de estudiantes"
                      text={courseById.totalStudents}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LabelInfo
                      label="Total de docentes"
                      text={courseById.totalTeachers}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <CourseUsers loading={loadingUsers} users={usersByCourse} />
              </Grid>
            </Grid>
          )
            : <Spinner /> }
        </Card>
      </Grid>
    </Grid>
  );
};

export default CourseView;
