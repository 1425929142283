import React from 'react';
import {
  reduxForm, InjectedFormProps, Field,
} from 'redux-form';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { required, urlValidator } from '../../helpers/validate-forms';
import Input from '../Input/Input';
import Button from '../Button/Button';
import CenterLine from '../Center/CenterInLine';
import { RootState } from '../../redux/store';
import Select from '../Select/Select';
import ContainerForm from '../ContainerForm/ContainerForm';
import ContainerInputs from '../ContainerInputs/ContainerInputs';
import FormRedux from '../FormRedux/FormRedux';

const SubjectForm: React.FC<InjectedFormProps> = ( props ) => {
  const { teachers } = useSelector(( state: RootState ) => state.teacher );
  const { courses } = useSelector(( state: RootState ) => state.course );

  const {
    handleSubmit, submitting,
  } = props;

  return (
    <ContainerForm>
      <FormRedux onSubmit={handleSubmit}>
        <ContainerInputs>
          <Grid item xs={12} sm={6}>
            <Field
              name="name"
              type="text"
              component={Input}
              label="Nombre"
              placeholder="Nombre"
              validate={[required]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="description"
              type="text"
              label="Descripción"
              component={Input}
              multiline
              placeholder="Descripción"
              validate={[required]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="courseId"
              component={Select}
              validate={[required]}
              label="Curso"
              options={courses}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="teacherId"
              component={Select}
              validate={[required]}
              label="Profesor"
              options={teachers}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="urlZoom"
              type="text"
              label="Enlace para Zoom"
              component={Input}
              placeholder="Url para zoom"
              validate={[urlValidator]}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <CenterLine>
              <Button type="submit" disabled={submitting}>
                Enviar
              </Button>
            </CenterLine>
          </Grid>
        </ContainerInputs>
      </FormRedux>
    </ContainerForm>
  );
};

export default reduxForm({
  form: 'subject',
})( SubjectForm );
