import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  Grid,
  Collapse,
  Divider,
} from '@material-ui/core';
import { IQuestion } from '../../../../../../interfaces/IQuestion';
import ItemDragAndDrop from '../ItemDragAndDrop/ItemDragAndDrop';
import useStyles from './Styles';
import {
  getQuestions,
} from '../../../../../../services/Activities.Services';
import { IActivity } from '../../../../../../interfaces/IActivity';
import ItemComplete from '../ItemComplete/ItemComplete';
import ItemNormal from '../ItemNormal/ItemNormal';
import ItemOpenQuestion from '../ItemOpenQuestion/ItemOpenQuestion';
import { actions } from '../../../../../../redux/ducks/ActivityDucks';
import { RootState } from '../../../../../../redux/store';

interface IProps {
  id: string;
  data: IActivity;
  // eslint-disable-next-line react/no-unused-prop-types
  setCalification: any;
  idStudent: string;
}

const ActivityItem: React.FC<IProps> = ( props ) => {
  const classes = useStyles();
  const {
    id, data: activityById, idStudent,
  } = props;
  const [questions, setQuestions] = useState<IQuestion[]>([]);

  const { formData } = useSelector(
    ( state: RootState ) => state.task,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch( actions.uploadAnswerActivitySuccess( '' ));
  }, []);

  useEffect(() => {
    handleUpload();
  }, [activityById]);

  const handleUpload = async (): Promise<void> => {
    if ( activityById ) {
      const resQues = await getQuestions( id, activityById.subtype || '' );
      setQuestions( resQues );
    } else {
      setQuestions([]);
    }
  };

  const getResponseForQuestion = (
    idQuestion: string, type?: string,
  ): string => {
    const { notes } = formData;
    const noteStudent = notes.find(( x: any ) => x.idStudent === idStudent );
    if ( noteStudent ) {
      const { subNotes } = noteStudent;
      const activity = subNotes.find(( x: any ) => x.id === activityById.id );
      if ( activity ) {
        if ( type === 'normal' ) {
          return activity.urlDocAnswer;
        }
        const { answers } = activity;
        const response = answers.find(( x: any ) => x.id === idQuestion );
        // eslint-disable-next-line no-console
        console.log( response );
        return response?.answer ?? '';
      }
      return 'N/A';
    }
    return 'N/A';
  };

  return (
    <div>
      <div className={classes.contentInfo}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6">
              {activityById?.name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Typography>
              {activityById?.description}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Collapse
        in
        timeout="auto"
        unmountOnExit
        className={classes.collapse}
      >
        {activityById?.subtype === 'dragAndDrop'
        && questions.map(( ques: IQuestion, i: number ) => (
          <div key={i.toString()}>
            <ItemDragAndDrop
              data={ques}
              response={getResponseForQuestion( ques.id || '' )}
            />
            <Divider />
          </div>
        ))}
        {activityById?.subtype === 'complete'
        && questions.map(( ques: IQuestion, i: number ) => (
          <div key={i.toString()}>
            <ItemComplete
              response={getResponseForQuestion( ques.id || '' )}
              data={ques}
            />
            <Divider />
          </div>
        ))}
        {activityById?.type === 'normal'
        && (
          <ItemNormal
            response={getResponseForQuestion( '', 'normal' )}
            data={activityById}
          />
        )}
        {activityById?.subtype === 'openQuestion'
        && questions.map(( ques: IQuestion, i: number ) => (
          <div key={i.toString()}>
            <ItemOpenQuestion
              data={ques}
              response={getResponseForQuestion( ques.id || '' )}
            />
            <Divider />
          </div>
        ))}
      </Collapse>

    </div>
  );
};

export default ActivityItem;
