import React, { Fragment } from 'react';
import * as IconM from '@material-ui/icons/';
import { GridValueGetterParams, GridColDef } from '@material-ui/data-grid';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import ContainerTable from '../Table/ContainerTable/ContainerTable';
import Table from '../Table/Table';
import { IBook } from '../../interfaces/IBook';
import CenterInLine from '../Center/CenterInLine';
import ToolTip from '../ToolTip/ToolTip';

interface ITableProps{
  books: IBook[];
  handleEdit: any;
  handleDelete: any;
  handleNew: any;
  handleShow: any;
}

const TableStudent: React.FC<ITableProps> = ( props ) => {
  const {
    books, handleDelete, handleEdit, handleNew, handleShow,
  } = props;

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 250,
    },
    {
      field: 'name',
      type: 'string',
      headerName: 'Nombre',
      editable: false,
      minWidth: 200,
    },
    {
      field: 'description',
      headerName: 'Descripción',
      type: 'string',
      editable: false,
      minWidth: 200,
    },
    {
      field: 'editorial',
      headerName: 'Email',
      type: 'string',
      editable: false,
      minWidth: 200,
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      minWidth: 300,
      filterable: false,
      renderCell: ( params: GridValueGetterParams ) => (
        <CenterInLine isRow>
          <ToolTip title="Mostrar detalle del libro">
            <ButtonIcon handleClick={() => handleShow( params.id )}>
              <IconM.Visibility />
            </ButtonIcon>
          </ToolTip>
          <ToolTip title="Editar libro">
            <ButtonIcon handleClick={() => handleEdit( params.id )}>
              <IconM.Edit />
            </ButtonIcon>
          </ToolTip>
          <ToolTip title="Eliminar libro">
            <ButtonIcon handleClick={() => handleDelete( params.id )}>
              <IconM.Delete />
            </ButtonIcon>
          </ToolTip>
        </CenterInLine>
      ),
    },
  ];

  return (
    <>
      <ButtonIcon handleClick={handleNew}>
        <IconM.Add />
      </ButtonIcon>
      <span>Agregar nuevo libro</span>
      <ContainerTable>
        <Table
          data={books}
          columns={columns}
        />
      </ContainerTable>
    </>
  );
};

export default TableStudent;
