import React, { useState, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import { ITask } from '../../../../interfaces/ITask';
import { getActivitiesById } from '../../../../services/Activities.Services';
import { IActivity } from '../../../../interfaces/IActivity';
import ItemTaskNote from '../ItemTaskNote/ItemTaskNote';
import useStyles from './Styles';
import Spinner from '../../../Spinner/Spinner';

interface IProps {
  task: ITask;
}

const TaskNotes: React.FC<IProps> = ( props ) => {
  const classes = useStyles();
  const { task } = props;
  const [calificationList, setCalificationList] = useState<IActivity[]>([]);
  const [comment, setComment] = useState<string>( '' );
  const [calification, setCalification] = useState( '0' );
  const [loadingNotes, setLoadingNotes] = useState<boolean>( false );

  useEffect(() => {
    if ( task.activities ) {
      handleGetData();
      const { notes } = task;
      if ( notes.length > 0 ) {
        const { comment: commentText, noteFinal } = notes[0];
        setComment( commentText );
        setCalification( noteFinal );
      }
    }
  }, [task]);

  const handleGetData = async (): Promise<void> => {
    setLoadingNotes( true );
    const actTotals = task.activities.concat( task.activitiesBook );
    const actListAux = await getActivitiesById( actTotals );
    setCalificationList( actListAux );
    setLoadingNotes( false );
  };

  const getCalification = ( id: string ): string => {
    const { subNotes } = task.notes[0];
    const act = subNotes.find(( x: any ) => x.id === id );
    return act ? act.calification.toString() : '0';
  };

  return (
    <div>
      { !loadingNotes
        ? calificationList.map(( item: IActivity, i: number ) => (
          <div key={i.toString()} className={classes.itemRubric}>
            <ItemTaskNote
              idAct={item.id || ''}
              name={item.name}
              rubric={item.rubric}
              calification={getCalification( item.id || '' )}
            />
          </div>
        ))
        : <Spinner />}
      <span className={classes.titleItem}>
        Comentarios
      </span>
      <Paper className={classes.comment}>
        <span>
          {comment}
        </span>
      </Paper>
      <Paper className={classes.footer} elevation={3}>
        <span>Calificación</span>
        <span>{calification}</span>
      </Paper>
    </div>
  );
};

export default TaskNotes;
