import React, { Fragment } from 'react';
import { GridValueGetterParams, GridColDef } from '@material-ui/data-grid';
import * as IconM from '@material-ui/icons/';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import ContainerTable from '../Table/ContainerTable/ContainerTable';
import Table from '../Table/Table';
import ToolTip from '../ToolTip/ToolTip';
import CenterInLine from '../Center/CenterInLine';
import { ISubject } from '../../interfaces/ISubject';
import { ITeacher } from '../../interfaces/ITeacher';

interface ITableProps{
  data: ISubject[];
  handleEdit: any;
  handleDelete: any;
  handleNew: any;
  // eslint-disable-next-line react/require-default-props,react/no-unused-prop-types
  handleUpload?: any;
  handleShow: any;
  // eslint-disable-next-line react/require-default-props
  handleOpenAdd?: any;
  teachers: ITeacher[];
  handleOpenSchedule: any;
}

const TableSubject: React.FC<ITableProps> = ( props ) => {
  const {
    data,
    handleDelete,
    handleEdit,
    handleNew,
    handleShow,
    handleOpenAdd,
    teachers,
    handleOpenSchedule,
  } = props;

  const handleGetName = ( id: string ): string => {
    const userName = teachers.find(( x: ITeacher ) => x.id === id );
    return userName ? `${userName.name} ${userName.surname}` : 'N/A';
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 250,
    },
    {
      field: 'name',
      type: 'string',
      headerName: 'Nombre',
      editable: false,
      minWidth: 200,
    },
    {
      field: 'teacherId',
      headerName: 'Docente',
      editable: false,
      minWidth: 200,
      valueFormatter: (
        { value }: any,
      ) => handleGetName( value ),
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      minWidth: 200,
      filterable: false,
      renderCell: ({ id }: GridValueGetterParams ) => (
        <CenterInLine isRow>
          <ToolTip title="Mostrar detalle de materia">
            <ButtonIcon handleClick={() => handleShow( id )}>
              <IconM.Visibility />
            </ButtonIcon>
          </ToolTip>
          <ToolTip title="Editar materia">
            <ButtonIcon handleClick={() => handleEdit( id )}>
              <IconM.Edit />
            </ButtonIcon>
          </ToolTip>
          <ToolTip title="Eliminar materia">
            <ButtonIcon handleClick={() => handleDelete( id )}>
              <IconM.Delete />
            </ButtonIcon>
          </ToolTip>
        </CenterInLine>
      ),
    },
    {
      field: 'addBook',
      headerName: 'Añadir libro',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      minWidth: 150,
      filterable: false,
      renderCell: ({ id }: GridValueGetterParams ) => (
        <CenterInLine isRow>
          <ToolTip title="Agregar libros">
            <ButtonIcon handleClick={() => handleOpenAdd( id )}>
              <IconM.PlaylistAdd />
            </ButtonIcon>
          </ToolTip>
        </CenterInLine>
      ),
    },
    {
      field: 'schedule',
      headerName: 'Horario',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      minWidth: 150,
      filterable: false,
      renderCell: ({ id }: GridValueGetterParams ) => (
        <CenterInLine isRow>
          <ToolTip title="Horario">
            <ButtonIcon
              handleClick={() => handleOpenSchedule( id )}
            >
              <IconM.QueryBuilder />
            </ButtonIcon>
          </ToolTip>
        </CenterInLine>
      ),
    },
  ];

  return (
    <>
      <CenterInLine isRow justify="flex-start">
        <ToolTip title="Agregar materia" placement="left">
          <ButtonIcon handleClick={handleNew}>
            <IconM.Add />
          </ButtonIcon>
        </ToolTip>
        <span>Agregar nueva materia</span>
      </CenterInLine>
      <ContainerTable>
        <Table
          columns={columns}
          data={data}
        />
      </ContainerTable>
    </>
  );
};

export default TableSubject;
