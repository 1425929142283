import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CardContent, Card } from '@material-ui/core';

const useStyles = makeStyles({
  center: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '0',
    '& h3': {
      margin: '0',
    },
  },
});

interface IMessageCardProps {
  // eslint-disable-next-line react/require-default-props
  children?: JSX.Element | JSX.Element[] | null;
}

const MessageCard: React.FC<IMessageCardProps> = ( props ) => {
  const classes = useStyles();

  const { children } = props;

  return (
    <Card className={classes.center}>
      <CardContent>
        {children}
      </CardContent>
    </Card>
  );
};

export default MessageCard;
