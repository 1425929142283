import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';

// Redux
import { useDispatch } from 'react-redux';
import { InjectedFormProps } from 'redux-form';

// components
import BookForm from '../../../components/BookForm/BookForm';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { actions } from '../../../redux/ducks/BookDucks';
// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';

// Interfaz
import { IBook } from '../../../interfaces/IBook';
import Card from '../../../components/Card/Card';

const BookCreate: React.FC<InjectedFormProps> = ( ) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const handleSubmit = ( values: any ): void => {
    const { photo, ...data } = values;
    const book: IBook = { ...data };
    dispatch( actions.createBook( book, photo ));
    return history.push( '/admin/libros' );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <ContainerBreadcrumbs>
            <Breadcrumbs
              itemsLinks={[{ name: 'libros', url: 'admin/libros' }]}
              itemsText={['crear-libro']}
            />
          </ContainerBreadcrumbs>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <BookForm onSubmit={handleSubmit} />
        </Card>
      </Grid>
    </Grid>
  );
};

export default BookCreate;
