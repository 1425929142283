export const required = ( value: string ): string | undefined => (
  value || typeof value === 'number'
    ? undefined
    : `Este campo es requerido`
);

export const isImage = ( file: File ): string | undefined => {
  if ( !String( file ).includes( 'https' ) && file ) {
    return ( /(\.jpeg|\.png|\.jpg)$/i ).exec( file.name )
      ? undefined
      : `Formato de imagen invalido`;
  }
  return undefined;
};

const maxLength = ( max: number ) => ( value: string ) => (
  value && value.length > max
    ? `Deben ser ${max} caracteres o menos`
    : undefined );

export const maxLength15 = maxLength( 15 );

export const maxLength30 = maxLength( 30 );

export const maxLength50 = maxLength( 50 );

export const maxLength150 = maxLength( 150 );

export const maxLength500 = maxLength( 500 );

const minLength = ( min: number ) => ( value: string ) => (
  value && value.length < min
    ? `Deben ser ${min} caracteres o mas`
    : undefined );

export const minLength2 = minLength( 2 );

export const minLength5 = minLength( 5 );

export const minLength10 = minLength( 10 );

export const number = ( value: number ): any => (
  value && Number.isNaN( Number( value ))
    ? 'Debería ser un número'
    : undefined );

const minValue = ( min: number ) => ( value: number ) => (
  value && value < min ? `Debe ser menor que ${min}` : undefined );

export const minValue13 = minValue( 13 );

export const email = ( value: string ): string | undefined => (
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test( value )
    ? 'Correo invalido, revice los datos y evite espacios al final o inicio'
    : undefined );

export const alphaNumeric = ( value: string ): string | undefined => (
  value && /[^a-zA-Z0-9 ]/i.test( value )
    ? 'Only alphanumeric characters'
    : undefined );

export const phoneNumber = ( value: string ): string | undefined => (
  value && !/^(0|[1-9][0-9]{9})$/i.test( value )
    ? 'Número de telefono invalido, debe tener 10 digitos'
    : undefined );

export const minLength8 = minLength( 8 );

export const urlValidator = ( value: string ): string | undefined => (
  // eslint-disable-next-line max-len
  value && !/http(s?)(:\/\/)((www.)?)(([^.]+)\.)?([a-zA-z0-9\-_]+)(.com|.net|.gov|.org|.in|.es|.us)(\/[^\s]*)?/.test( value )
    ? 'Ingrese una url válida'
    : undefined );

export const percentageValidator = ( value: string ): string | undefined => (
  // eslint-disable-next-line max-len
  value && !/(\d+(\.\d+)?%)/g.test( value )
    ? 'Ingrese un porcentaje válido'
    : undefined );

export const lessThan100Validator = ( value: string ): string | undefined => (
  // eslint-disable-next-line max-len
  value && !/^([0-9]|[1-9][0-9]|100)$/g.test( value )
    ? 'el valor debe ser igual o menor a 100'
    : undefined );

export const double = ( value: string ): string | undefined => (
  // eslint-disable-next-line max-len
  value && !/^(-?)(0|([1-9][0-9]*))(\.[0-9]+)?$/g.test( value )
    ? 'Ingrese un valor entero o decimal, use el punto.'
    : undefined );

export const isObjEmpty = (
  obj: any,
): boolean => Object.keys( obj ).length === 0;

export const existAnswer = ( value: string ): string | undefined => {
  const reg = /.[^((].[))]/i;
  return reg.test( value )
    ? undefined
    : `Falta la respuesta entre parentesis dobles (( -- ))`;
};

export const existOneAnswer = (
  value: string,
): string | undefined => ( value.split( '((' ).length === 2
  ? undefined
  : `La oración pueden tener una sola respuesta.` );

export const existAnswerDnD = ( value: string ): string | undefined => (
  value.includes( '**' )
    ? undefined
    : `Indique el lugar de la respuesta con los 2 asteriscos **` );

export const formatAnswerDnD = ( value: string ): string | undefined => (
  value.includes( ' ** ' )
    ? undefined
    : `Deben existir espacios al rededor 
    de los asteriscos. Ejemplo espacio**espacio.` );

export const existOneAnswerDnD = (
  value: string,
): string | undefined => ( value.split( '**' ).length === 2
  ? undefined
  : `Las oración debe tener una sola respuesta.` );

export const size5MB = ( value: number ): boolean => {
  const size = ( value / ( 1024 * 1024 )).toFixed( 2 );
  return !( parseFloat( size ) > 5 );
};
