import React from 'react';
import {
  reduxForm, InjectedFormProps, Field, formValueSelector,
} from 'redux-form';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import {
  required, minLength5, maxLength30,
  maxLength50, minLength10, email, isImage,
} from '../../helpers/validate-forms';
import {
  existEmail,
} from '../../helpers/async-validate-form';
import Input from '../Input/Input';
import Button from '../Button/Button';
import Checkbox from '../Checkbox/Checkbox';
import ImageForm from '../ImageForm/ImageForm';
import { RootState } from '../../redux/store';
import FileInput from '../FileInput/FileInput';
import Image from '../Image/Image';
import UserDefault from '../../assets/img/user-photo-default.png';
import ContainerForm from '../ContainerForm/ContainerForm';
import FormRedux from '../FormRedux/FormRedux';
import ContainerInputs from '../ContainerInputs/ContainerInputs';
import CenterInLine from '../Center/CenterInLine';
import ContainerImageForm from '../ContainerImageForm/ContainerImageForm';
import Select from '../Select/Select';

const TeacherForm: React.FC<InjectedFormProps> = ( props ) => {
  const selector = formValueSelector( 'teacher' );
  const { courses } = useSelector(( state: RootState ) => state.course );
  const photo = useSelector(( state: RootState ) => selector( state, 'photo' ));
  const {
    handleSubmit, submitting,
  } = props;

  return (
    <ContainerForm>
      <Grid item xs={12} sm={8}>
        <FormRedux onSubmit={handleSubmit}>
          <ContainerInputs>
            <Grid item xs={12} sm={6}>
              <Field
                name="identification"
                type="text"
                label="Identificación"
                component={Input}
                placeholder="Nro. Cédula"
                validate={[required, minLength10]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="name"
                type="text"
                label="Nombres"
                component={Input}
                placeholder="Nombres del docente"
                validate={[required, minLength5, maxLength30]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="surname"
                type="text"
                label="Apellidos"
                component={Input}
                placeholder="Apellidos del docente"
                validate={[required, minLength5, maxLength30]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="email"
                type="text"
                label="Correo"
                component={Input}
                placeholder="Correo electrónico"
                validate={[
                  required, minLength5, maxLength50, email]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="phone"
                type="text"
                label="Teléfono"
                component={Input}
                placeholder="Número de contacto"
                validate={[required]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="courseId"
                component={Select}
                label="Curso"
                placeholder="Escoja un curso"
                validate={[required]}
                options={courses}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="status"
                type="checkbox"
                component={Checkbox}
                title="Activo"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="photo"
                type="file"
                component={FileInput}
                label="Elija una imagen"
                validate={[isImage]}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <CenterInLine>
                <Button type="submit" disabled={submitting}>
                  Enviar
                </Button>
              </CenterInLine>
            </Grid>
          </ContainerInputs>
        </FormRedux>
      </Grid>
      <Grid item xs={12} sm={4}>
        <CenterInLine>
          <ContainerImageForm>
            {photo
              ? <ImageForm photo={photo} />
              : <Image src={UserDefault} />}
          </ContainerImageForm>
        </CenterInLine>
      </Grid>
    </ContainerForm>
  );
};

export default reduxForm({
  form: 'teacher',
  asyncValidate: existEmail,
  asyncBlurFields: ['email'],
})( TeacherForm );
