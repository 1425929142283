import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Material Ui
import { Grid } from '@material-ui/core';
// Interface
import { useDispatch, useSelector } from 'react-redux';
// Redux
import { RootState } from '../../../redux/store';
import { actions as actionsBook } from '../../../redux/ducks/BookDucks';
import { actions as actionsUnits } from '../../../redux/ducks/UnitDucks';
// Components
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import LabelInfo from '../../../components/LabelInfo/LabelInfo';
import CenterInLine from '../../../components/Center/CenterInLine';
// eslint-disable-next-line max-len
import ContainerImageForm from '../../../components/ContainerImageForm/ContainerImageForm';
import Image from '../../../components/Image/Image';
import BookDefault from '../../../assets/img/BookDefault.jpg';
import UnitDropdown from '../../../components/UnitDropdown/UnitDropdown';
import Card from '../../../components/Card/Card';
import { getRoute } from '../../../helpers/get-user-by-type';

interface Params {
  id: string;
}

const BookView: React.FC = () => {
  const params = useParams<Params>();

  const dispatch = useDispatch();

  const { userData: { type } } = useSelector(
    ( state: RootState ) => state.login,
  );

  const { bookById: formData } = useSelector(
    ( state: RootState ) => state.book,
  );

  const units = useSelector(
    ( state: RootState ) => state.unit.units,
  );

  useEffect(() => {
    if ( params.id ) {
      dispatch( actionsBook.getBook( params.id ));
      dispatch( actionsUnits.getUnitsByBookId( params.id ));
    }
  }, []);

  return (

    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <ContainerBreadcrumbs>
            <Breadcrumbs
              itemsLinks={[
                { name: 'libros', url: `${getRoute( type )}/libros` }]}
              itemsText={['leer-libro']}
            />
          </ContainerBreadcrumbs>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          {formData && (
            <Grid container>
              { type === 1 && (
                <>
                  <Grid item xs={12} sm={8}>
                    <Grid container>
                      <Grid item xs={12} sm={6}>
                        <LabelInfo label="Nombre" text={formData.name} />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <LabelInfo label="Descripción" text={formData.description} />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <LabelInfo
                          noWrap
                          label="Url para embeber"
                          text={formData.mainUrl}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <LabelInfo
                          label="Editorial"
                          text={formData.editorial}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CenterInLine>
                      <ContainerImageForm>
                        {formData && <Image src={formData.photo || BookDefault} />}
                      </ContainerImageForm>
                    </CenterInLine>
                  </Grid>

                </>
              )}
              <Grid item xs={12}>
                <UnitDropdown units={units} />
              </Grid>
              <Grid item xs={12}>
                <LabelInfo
                  label="Libro embebido"
                  text=""
                />
                <iframe
                  width="100%"
                  height="500px"
                  src={formData.mainUrl}
                  title={formData.name}
                  seamless={false}
                  // allowTransparency
                  allowFullScreen
                />
              </Grid>
            </Grid>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default BookView;
