import React from 'react';
import { Box } from '@material-ui/core';

interface ICBProps {
  // eslint-disable-next-line react/require-default-props
  children?: JSX.Element | JSX.Element[];
}

const ContainerBreadcrumbs: React.FC<ICBProps> = ( props ) => {
  const { children } = props;

  return (
    <Box display="flex" justifyContent="space-between" pt="1em" pb="1em">
      { children }
    </Box>
  );
};

export default ContainerBreadcrumbs;
