/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
// Material Ui
import { useHistory } from 'react-router-dom';
// Interface
import { useDispatch, useSelector } from 'react-redux';
// Redux
import {
  Box,
  Fab,
  Grid, makeStyles,
} from '@material-ui/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import {
  getStudentBySubjectThunk,
  getSubjectByIdThunk,
  updateSubjectThunk,
} from '../../../../redux/ducks/SubjectDucks';
// Components
import { RootState } from '../../../../redux/store';
import SubjectClassUrlItemTeacher from '../../../../components/SubjectClassUrlItemTeacher/SubjectClassUrlItemTeacher';
import SubjectClassTaskItemTeacher from '../../../../components/SubjectClassTaskItemTeacher/SubjectClassTaskItemTeacher';
import SubjectTaskItemTeacher from '../../../../components/SubjectTaskItemTeacher/SubjectTaskItemTeacher';

import { getAllTaskBySubjectIdTeacherThunk } from '../../../../redux/ducks/TaskDucks';
import { ITask } from '../../../../interfaces/ITask';
import { getInstituteThunk } from '../../../../redux/ducks/InstituteDucks';
import { actions as actionsUnit } from '../../../../redux/ducks/UnitDucks';

// Icons
import arrowRight from '../../../../assets/svg/arrowRight.svg';
import Add from '../../../../assets/svg/Add.svg';
import ContainerBreadcrumbs from '../../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import Dialog from '../../../../components/Dialog/Dialog';
import ZoomForm from '../../../../components/ZoomForm/ZoomForm';
import Spinner from '../../../../components/Spinner/Spinner';
import ToolTip from '../../../../components/ToolTip/ToolTip';

interface Params {
  id: string;
}
const useStyles = makeStyles({
  dFlex: {
    display: 'flex',
  },
  svgAdd: {
    width: '3.5em',
  },
  buttonAdd: {
    height: '5em',
    width: '5em',
    background: 'white',
  },
});
const TeacherSubjectDetail: React.FC = () => {
  const classes = useStyles();
  const [open, setOpen] = useState( false );

  const dispatch = useDispatch();
  const params = useParams<Params>();
  const history = useHistory();

  const { formData } = useSelector((
    state: RootState,
  ) => state.subject );

  const { studentList } = useSelector((
    state: RootState,
  ) => state.subject );

  const { tasks, loading } = useSelector(( state: RootState ) => state.task );

  const handleClose = (): void => {
    setOpen( false );
  };

  const handleOpen = (): void => {
    setOpen( true );
  };

  const handleOnSubmit = ( values: any ): void => {
    dispatch( updateSubjectThunk( values ));
  };

  useEffect(() => {
    dispatch( getInstituteThunk());
    dispatch( actionsUnit.getUnits());
    dispatch( getStudentBySubjectThunk( params.id ));
    dispatch( getSubjectByIdThunk( params.id ));
    dispatch( getAllTaskBySubjectIdTeacherThunk( params.id ));
  }, []);

  const handleShowTaskByPartials = (): void => {
    history.push( `/docente/tareas/detalle/${params.id}` );
  };

  return (
    <>
      <ContainerBreadcrumbs>
        <Breadcrumbs
          itemsLinks={[
            {
              name: 'Materias',
              url: `docente/inicio`,
            }]}
          itemsText={[formData ? formData.name : '']}
        />
      </ContainerBreadcrumbs>
      {formData && (
        <Grid container spacing={6}>
          <SubjectClassUrlItemTeacher subject={formData} handleOpenChangeUrl={handleOpen} />
          <SubjectClassTaskItemTeacher subject={formData} students={studentList && studentList} />
        </Grid>
      )}
      <h2 className={classes.dFlex}>
        <img src={arrowRight} alt="arrowRight" />
        Tareas
      </h2>
      {loading ? (
        <Spinner /> )
        : (
          <Grid container spacing={6} direction="row" justify="center" alignItems="center">
            {tasks.length > 0 && (
              <Grid item xs={12} md={9}>
                <Swiper
                  spaceBetween={15}
                  slidesPerView={3}
                  navigation
                  grabCursor
                  breakpoints={{
                    400: {
                      slidesPerView: 1,
                    },
                    900: {
                      slidesPerView: 2,
                    },
                    1300: {
                      slidesPerView: 3,
                    },
                  }}
                >
                  {tasks && tasks.map(( task: ITask, i: number ) => (
                    <SwiperSlide key={i.toString()}>
                      <SubjectTaskItemTeacher students={studentList && studentList} task={task} key={task.id} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Grid>
            )}
            <Grid item xs={3}>
              <Fab
                className={classes.buttonAdd}
                onClick={handleShowTaskByPartials}
              >
                <ToolTip title="Nueva tarea">
                  <Box display="flex">
                    <img className={classes.svgAdd} src={Add} alt="newHomework" />
                  </Box>
                </ToolTip>
              </Fab>
            </Grid>
          </Grid>
        )}
      <Dialog
        open={open}
        title="Cambiar link"
        onClose={handleClose}
        size="xs"
      >
        <ZoomForm onSubmit={handleOnSubmit} initialValues={formData && formData} />
      </Dialog>
    </>
  );
};

export default TeacherSubjectDetail;
