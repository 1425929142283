import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Divider, Grid } from '@material-ui/core';
import * as IconM from '@material-ui/icons/';
import { useHistory } from 'react-router';
// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import { RootState } from '../../../../redux/store';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import ActivityItem from './components/ActivityItem/ActivityItem';
import Card from '../../../../components/Card/Card';
import RubricEvaluation from './components/RubricEvaluation/RubricEvaluation';
import { getActivitiesById } from '../../../../services/Activities.Services';
import { IActivity } from '../../../../interfaces/IActivity';
import { actions } from '../../../../redux/ducks/ActivityDucks';
import useStyles from './Styles';
import ButtonIcon from '../../../../components/ButtonIcon/ButtonIcon';
import Dialog from '../../../../components/Dialog/Dialog';
// import { getStudentByIdThunk } from '../../../../redux/ducks/StudentDucks';
import { updateNoteByIdThunk } from '../../../../redux/ducks/TaskDucks';

const NOTE_BASE = process.env.REACT_APP_NOTE_BASE;

interface Params {
  id: string;
  studentId: string;
}

const Homework: React.FC = ( ) => {
  const classes = useStyles();
  const params = useParams<Params>();
  const dispatch = useDispatch();

  const history = useHistory();
  const subject = useSelector(( state: RootState ) => state.subject.formData );
  const [calification = 0, setCalification] = useState<number>( 0 );
  const [calificationList, setCalificationList] = useState<IActivity[]>([]);
  const [open, setOpen] = useState( false );
  const [messageDialog, setMessageDialog] = useState<string>( '' );
  const [titleDialog, setTitleDialog] = useState( '' );
  const [notesList, setNotesList] = useState<any[]>([]);
  const [comment, setComment] = useState<string>( '' );

  const { activities } = useSelector(
    ( state: RootState ) => state.activity,
  );

  const { formData } = useSelector(
    ( state: RootState ) => state.task,
  );

  const { formData: studentData } = useSelector(
    ( state: RootState ) => state.student,
  );

  useEffect(() => {
    dispatch( actions.setActivities([]));
    // TODO: Es nevesario consultar el estudiante de nuevo?
    /*
    if ( params.studentId ) {
      dispatch( getStudentByIdThunk( params.studentId ));
    }
     */
  }, []);

  useEffect(() => {
    if ( formData?.activities ) {
      handleGetData();
      const { notes } = formData;
      const { subNotes, comment: commentText } = notes.find(( e: { id: string; }) => e.id === params.studentId );
      setNotesList( subNotes );
      setComment( commentText );
    }
  }, [formData]);

  useEffect(() => {
    handleGetTotal();
  }, [notesList, activities]);

  const handleGetTotal = (): void => {
    let noteObtained = 0;
    notesList.forEach(( item: any ) => {
      noteObtained += item.calification || 0;
    });

    let noteMax = 0;
    activities.forEach(( item: any ) => {
      noteMax += parseFloat( item.maxGrade ) || 0;
    });

    if ( noteMax !== 0 && NOTE_BASE ) {
      const total = (
        ( noteObtained * parseInt( NOTE_BASE, 10 )
        ) / noteMax ).toFixed( 2 );
      setCalification( parseFloat( total ));
    } else {
      setCalification( 0 );
    }
  };

  const handleGetData = async (): Promise<void> => {
    const actTotals = formData.activities.concat( formData.activitiesBook );
    const actList = await getActivitiesById( actTotals );
    dispatch( actions.setActivities( actList ));
    const actListAux = await getActivitiesById( actTotals );
    setCalificationList( actListAux );
  };

  const handleSetCalification = (
    id: string, total?: number,
  ): void => {
    const notesData = notesList;
    const act = notesData.find(( x: any ) => x.id === id );
    Object.assign( act, {
      calification: total,
    });
    setNotesList( notesData );
    handleGetTotal();
  };

  const handleSaveCalification = (): void => {
    const { notes, id } = formData;
    const note = notes.find(( e: { id: string; }) => e.id === params.studentId );
    // const idNote = note.id;
    Object.assign( note, {
      reviewed: true,
      subNotes: notesList,
      noteFinal: calification,
      comment: comment || 'N/A',
      student: '',
    });
    dispatch( updateNoteByIdThunk( id, note ));
    history.goBack();
  };

  const handleOpenDialog = ( title: string, msg: string ): void => {
    setOpen( !open );
    setMessageDialog( msg );
    setTitleDialog( title );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <div className={classes.card}>
            <ContainerBreadcrumbs>
              <Breadcrumbs
                itemsLinks={[
                  {
                    name: subject.name,
                    url: `docente/tareas/calificaciones/${formData.id}`,
                  }]}
                itemsText={['Calificando tarea del estudiante ',
                  `${studentData?.name} ${studentData?.surname}`]}
              />
            </ContainerBreadcrumbs>
            <div className={classes.send}>
              <ButtonIcon primary handleClick={handleSaveCalification}>
                <IconM.PlayArrow />
              </ButtonIcon>
              <span>Guardar</span>
            </div>
          </div>
        </Card>
      </Grid>
      <Grid item container xs={12} sm={8}>
        <Grid item xs={12}>
          <Card>
            {activities
            && activities.map(( item: any, i: number ) => (
              <div key={i.toString()}>
                <ActivityItem
                  id={item.id}
                  data={item}
                  setCalification={handleSetCalification}
                  idStudent={params.studentId}
                />
                <Divider />
              </div>
            ))}
          </Card>
        </Grid>
      </Grid>
      <Grid item container xs={12} sm={4}>
        {
          formData && formData.notes && (
            <RubricEvaluation
              data={calificationList}
              calification={calification}
              dataNote={formData.notes.find(( e: { id: string; }) => e.id === params.studentId )}
              setCalification={handleSetCalification}
              onCalcTotal={handleGetTotal}
              onAddComment={setComment}
            />
          )
        }
      </Grid>
      <Dialog
        open={open}
        title={titleDialog}
        onClose={handleOpenDialog}
        size="xs"
      >
        <Box
          flexDirection="column"
          display="flex"
          gridRowGap={10}
        >
          <span>
            {messageDialog}
          </span>
        </Box>
      </Dialog>
    </Grid>
  );
};

export default Homework;
