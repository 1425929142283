import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  makeStyles,
} from '@material-ui/core';
import * as IconM from '@material-ui/icons/';
// import { ArrowDropDown } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import {
  Accordion, AccordionSummary, AccordionDetails, Popover,
} from './Styles';
import { IUnit } from '../../interfaces/IUnit';
import ButtonIcon from '../ButtonIcon/ButtonIcon';

interface IUnitDropdownProps {
  units: IUnit[];
}

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    minWidth: '30em',
    borderRadius: '0!important',
    border: '0 2px 2px 2px black',
  },
  block: {
    overflow: 'auto',
    display: 'block',
  },
  ml: {
    marginLeft: 'auto',
  },
  link: {
    textDecoration: 'none!important',
    color: `rgba(0, 0, 0, 0.50)`,
  },
  icon: {
    color: '#fff',
    '&:hover': {
      color: '#000',
    },
  },
}));

const UnitDropdown: React.FC<IUnitDropdownProps> = ( props ) => {
  const classes = useStyles();
  const { units } = props;
  const [anchorEl, setAnchorEl] = React.useState( null );
  const [expanded, setExpanded] = React.useState<string | false>( false );

  const handleChange = ( panel: string ) => (
    event: React.ChangeEvent<any>,
    isExpanded: boolean,
  ) => {
    setExpanded( isExpanded ? panel : false );
  };

  const handleClick = ( event: any ): void => {
    setAnchorEl( event.currentTarget );
  };

  const handleClose = (): void => {
    setAnchorEl( null );
  };

  const open = Boolean( anchorEl );
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <ButtonIcon primary handleClick={handleClick}>
        <IconM.Menu className={classes.icon} />
      </ButtonIcon>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className="MuiPaper-rounded"
      >
        {units && units.length > 0
          ? units.sort(( a: IUnit, b: IUnit ) => a.name.localeCompare( b.name ))
            .map(( unit: IUnit, index: number ) => (
              <Accordion
                expanded={expanded === `panel${index}`}
                onChange={handleChange( `panel${index}` )}
                key={unit.id}
              >
                <AccordionSummary>
                  <Typography>{unit.name}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.block}>
                  {unit.topics
                    && unit.topics.length > 0
                    ? unit.topics.map(( topic: any, idx: number ) => (
                      <Card className={classes.card} key={topic.id}>
                        <CardContent>
                          <Link
                            to={`${unit.id}/actividad/${topic.id}`}
                            className={classes.link}
                          >
                            <Box display="flex">
                              <Typography>
                                Tema
                                {' '}
                                {idx + 1}
                                {' '}
                              </Typography>
                              <Typography className={classes.ml}>
                                {topic.name}
                              </Typography>
                            </Box>
                          </Link>
                        </CardContent>
                      </Card>
                    ))
                    : (
                      <Card className={classes.card}>
                        <CardContent>
                          <Box display="flex">
                            <Typography>
                              No se encuentran temas asignados.
                            </Typography>
                          </Box>
                        </CardContent>
                      </Card>
                    )}
                </AccordionDetails>
              </Accordion>
            ))

          : (
            <Typography>No se encuentran unidades asignados.</Typography>
          )}
      </Popover>
    </div>
  );
};

export default UnitDropdown;
