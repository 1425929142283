import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  title: {
    fontSize: 14,
    fontWeight: 600,
  },
  shadow: {
    boxShadow: `rgb(0 0 0 / 20%) 0px 10px 10px -4px, 
    rgb(0 0 0 / 14%) 0px 3px 4px 0px, 
    rgb(0 0 0 / 12%) 0px 1px 8px 0px`,
  },
  cardHeader: {
    padding: '1em 2em 1em 2em',
  },
  cardActions: {
    padding: '1em 2em 1em 2em',
  },
  card: {
    minHeight: '100%',
    borderRadius: 10,
  },
  playButton: {
    width: 50,
    height: 50,
    backgroundColor: '#FECA19',
    color: '#fff',
  },
  playButtonText: {
    fontWeight: 'bold',
  },
  rowBody: {
    width: '100%',
  },
  titleTask: {
    fontSize: 11,
  },
  containerTask: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 5,
  },
  containerIconsTasks: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 10,
  },
  containerIcon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: 5,
  },
  icon: {
    width: 25,
    height: 25,
  },
});
