import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Typography,
  Grid,
  Collapse,
  Divider,
} from '@material-ui/core';
import { IQuestion } from '../../../../../interfaces/IQuestion';
import ItemDragAndDrop from '../ItemDragAndDrop/ItemDragAndDrop';
import useStyles from './Styles';
import {
  getQuestions,
} from '../../../../../services/Activities.Services';
import { IActivity } from '../../../../../interfaces/IActivity';
import ItemComplete from '../ItemComplete/ItemComplete';
import ItemNormal from '../ItemNormal/ItemNormal';
import ItemOpenQuestion from '../ItemOpenQuestion/ItemOpenQuestion';
import { actions } from '../../../../../redux/ducks/ActivityDucks';

interface IProps {
  id: string;
  data: IActivity;
  setCalification: any;
}

const ActivityItem: React.FC<IProps> = ( props ) => {
  const classes = useStyles();
  const { id, data: activityById, setCalification } = props;
  const [questions, setQuestions] = useState<IQuestion[]>([]);
  const [answers, setAnswers] = useState<any[]>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch( actions.uploadAnswerActivitySuccess( '' ));
  }, []);

  useEffect(() => {
    handleUpload();
  }, [activityById]);

  const handleUpload = async (): Promise<void> => {
    if ( activityById ) {
      const resQues = await getQuestions( id, activityById.subtype || '' );
      setQuestions( resQues );
    } else {
      setQuestions([]);
    }
  };

  const handleResponseDnD = (
    correct?: boolean, idQuestion?: string, answer?: string,
  ): void => {
    const arr = answers;
    const itemAns = arr.find(( x ) => x.idQuestion === idQuestion );
    if ( itemAns ) {
      arr.splice( arr.indexOf( itemAns ), 1 );
    }
    arr.push({ correct, idQuestion });
    const correctList = arr.filter(( x ) => x.correct === true );
    if ( correctList.length > 0 ) {
      const total = calcTotal(
        correctList.length,
        activityById.maxGrade,
        questions.length,
      );

      const response = { id: idQuestion, answer: answer || '' };
      setCalification(
        activityById.id,
        parseFloat( total.toFixed( 2 )),
        response,
      );
    } else {
      const response = { id: idQuestion, answer: answer || '' };
      setCalification( activityById.id, 0, response );
    }
    setAnswers( arr );
  };

  const handleResponseOpenQuestion = (
    idQuestion: string, answer?: string,
  ): void => {
    const response = { id: idQuestion, answer };
    setCalification( activityById.id, 0, response );
  };

  const handleResponseNormal = ( urlFile?: string ): void => {
    setCalification( activityById.id, 0, undefined, urlFile );
  };

  const calcTotal = (
    countCorrectList: number, noteMax: number, countListQuestions: number,
  ): number => (
    countCorrectList * noteMax
  ) / countListQuestions;

  return (
    <div>
      <div className={classes.contentInfo}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6">
              {activityById?.name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Typography>
              {activityById?.description}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Collapse
        in
        timeout="auto"
        unmountOnExit
        className={classes.collapse}
      >
        {activityById?.subtype === 'dragAndDrop'
        && questions.map(( ques: IQuestion, i: number ) => (
          <div key={i.toString()}>
            <ItemDragAndDrop onSave={handleResponseDnD} data={ques} />
            <Divider />
          </div>
        ))}
        {activityById?.subtype === 'complete'
        && questions.map(( ques: IQuestion, i: number ) => (
          <div key={i.toString()}>
            <ItemComplete onSave={handleResponseDnD} data={ques} />
          </div>
        ))}
        {activityById?.type === 'normal'
        && (
          <ItemNormal onSave={handleResponseNormal} data={activityById} />
        )}
        {activityById?.subtype === 'openQuestion'
        && questions.map(( ques: IQuestion, i: number ) => (
          <div key={i.toString()}>
            <ItemOpenQuestion onSave={handleResponseOpenQuestion} data={ques} />
          </div>
        ))}
      </Collapse>

    </div>
  );
};

export default ActivityItem;
