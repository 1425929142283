import React, { Fragment } from 'react';
import { GridColDef, GridValueGetterParams } from '@material-ui/data-grid';
import * as IconM from '@material-ui/icons/';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import ContainerTable from '../Table/ContainerTable/ContainerTable';
import Table from '../Table/Table';
import { IBook } from '../../interfaces/IBook';
import CenterInLine from '../Center/CenterInLine';
import ToolTip from '../ToolTip/ToolTip';

interface ITableProps{
  courses: IBook[];
  handleEdit: any;
  handleDelete: any;
  handleNew: any;
  handleShow: any;
}

const TableStudent: React.FC<ITableProps> = ( props ) => {
  const {
    courses, handleDelete, handleShow, handleEdit, handleNew,
  } = props;

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 250,
    },
    {
      field: 'course',
      type: 'string',
      headerName: 'Nombre',
      editable: false,
      minWidth: 150,
    },
    {
      field: 'name',
      type: 'string',
      headerName: 'Nombre',
      editable: false,
      minWidth: 200,
    },
    {
      field: 'description',
      headerName: 'Descripción',
      type: 'string',
      editable: false,
      minWidth: 200,
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      minWidth: 300,
      renderCell: ({ id }: GridValueGetterParams ) => (
        <CenterInLine isRow>
          <ToolTip title="Mostrar detalle del curso">
            <ButtonIcon handleClick={() => handleShow( id )}>
              <IconM.Visibility />
            </ButtonIcon>
          </ToolTip>
          <ToolTip title="Editar curso">
            <ButtonIcon handleClick={() => handleEdit( id )}>
              <IconM.Edit />
            </ButtonIcon>
          </ToolTip>
          <ToolTip title="Eliminar curso">
            <ButtonIcon
              handleClick={() => handleDelete( id )}
            >
              <IconM.Delete />
            </ButtonIcon>
          </ToolTip>
        </CenterInLine>

      ),
    },
  ];

  return (
    <>
      <ButtonIcon handleClick={() => handleNew( 1 )}>
        <IconM.Add />
      </ButtonIcon>
      <span>Agregar nuevo curso</span>
      <ContainerTable>
        <Table
          columns={columns}
          data={courses}
        />
      </ContainerTable>
    </>
  );
};

export default TableStudent;
