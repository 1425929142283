import ExcelJS from 'exceljs';
import * as fs from 'file-saver';
import { getSchemesByPeriod } from '../services/Scheme.Services';
import { IInstitute } from '../interfaces/IInstitute';
import { ISubject } from '../interfaces/ISubject';
import { ICourse } from '../interfaces/ICourse';
import { IScheme } from '../interfaces/IScheme';
import { IStudent } from '../interfaces/IStudent';
import { IReportCard } from '../interfaces/IReportCard';
import { getReportsByPeriodAndSubject } from '../services/Report.Services';

interface IPartial {
  prefix: string;
  name: string;
  length: number;
  scheme: string[];
}

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

export const writeFileStyle = async (
  institute: IInstitute,
  subject: ISubject,
  course: ICourse,
  students: IStudent[],
  fileName: string,
): Promise<void> => {
  const { period, name: instituteName, partial } = institute;
  const { id: idSubject } = subject;
  const { name: courseName, course: courseLetter } = course;
  const schemes = await handleGetSchemes( period.id, idSubject );
  const reports = await handleGetReports( period.id, idSubject );
  const dataQ1AndQ2 = hadleCreateQuimestres( partial.length );

  if ( schemes && reports ) {
    // add data file
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'MyBook+';
    workbook.created = new Date();
    const worksheet = workbook.addWorksheet( period.name );

    // add info institute
    worksheet.addRow([instituteName]);
    worksheet.mergeCells( 'A1:F1' );
    worksheet.getCell( 'A1' ).alignment = {
      horizontal: 'center',
      vertical: 'middle',
    };
    worksheet.getCell( 'A1' ).font = { size: 20, bold: true, family: 1 };
    worksheet.getCell( 'A1' ).border = {
      bottom: { style: 'hair' },
    };

    // add info course
    worksheet.addRow([`${courseName} "${courseLetter}"`]);
    worksheet.mergeCells( 'A2:F2' );
    worksheet.getCell( 'A2' ).alignment = { horizontal: 'center' };
    worksheet.getCell( 'A2' ).font = { size: 16, bold: true, family: 2 };
    worksheet.getCell( 'A2' ).border = {
      bottom: { style: 'hair' },
    };

    // add info period
    worksheet.addRow([period.name]);
    worksheet.mergeCells( 'A3:F3' );
    worksheet.getCell( 'A3' ).alignment = { horizontal: 'center' };
    worksheet.getCell( 'A3' ).font = { size: 12, bold: true, family: 2 };

    // add row empty
    worksheet.addRow([]);

    // add cell index
    worksheet.mergeCells( 'A5', 'A7' );
    worksheet.getCell( 'A5' ).alignment = {
      horizontal: 'center',
      vertical: 'middle',
    };
    worksheet.getCell( 'A5' ).value = '#';

    // add cell students
    worksheet.mergeCells( 5, 2, 7, 5 );
    worksheet.getCell( 'B5' ).alignment = {
      horizontal: 'center',
      vertical: 'middle',
    };
    worksheet.getCell( 'B5' ).value = 'Estudiantes';

    worksheet.mergeCells( 'F5', 'F7' );
    worksheet.getCell( 'F5' ).alignment = {
      horizontal: 'center',
      vertical: 'justify',
    };
    worksheet.getCell( 'F5' ).value = 'Promedio General';
    worksheet.getCell( 'F5' ).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '000000' },
    };
    worksheet.getCell( 'F5' ).font = {
      color: { argb: 'ffffff' },
    };
    worksheet.getCell( 'F5' )
      .border = {
        top: { style: 'medium' },
        left: { style: 'medium' },
        bottom: { style: 'medium' },
        right: { style: 'medium' },
      };

    // get partials by quimestre
    // Q1
    const { q1, q2 } = dataQ1AndQ2;
    const partialsQ1List: IPartial[] = [];
    let q1Lenght = 1;
    q1.forEach(( item: number, i: number ) => {
      const schemesAndSize = handleGetAmountSchemesInPartial( item, schemes );
      const schemesArr = schemesAndSize.arr.map(( x: IScheme ) => x.name );
      const { name: pName } = partial[item];
      const pPrefix = `P${i + 1}`;
      const partialItem: IPartial = {
        prefix: pPrefix,
        name: pName,
        length: schemesArr.length + 1,
        scheme: schemesArr,
      };
      q1Lenght += schemesArr.length + 1;
      partialsQ1List.push( partialItem );
    });

    // get partials by quimestre
    // Q1
    const partialsQ2List: IPartial[] = [];
    let q2Lenght = 1;
    q2.forEach(( item: number, i: number ) => {
      const schemesAndSize = handleGetAmountSchemesInPartial( item, schemes );
      const schemesArr = schemesAndSize.arr.map(( x: IScheme ) => x.name );
      const { name: pName } = partial[item];
      const pPrefix = `P${i + 1}`;
      const partialItem: IPartial = {
        prefix: pPrefix,
        name: pName,
        length: schemesArr.length + 1,
        scheme: schemesArr,
      };
      q2Lenght += schemesArr.length + 1;
      partialsQ2List.push( partialItem );
    });

    // object header
    const header = [
      {
        prefix: 'Q1',
        name: 'Quimestre 1',
        length: q1Lenght,
        partials: partialsQ1List,
      },
      {
        prefix: 'Q2',
        name: 'Quimestre 2',
        length: q2Lenght,
        partials: partialsQ2List,
      },
    ];

    // add cell first partial
    const letterQ1Start = 'G';
    const letterQ1End = numToAlpha(( header[0].length ) + 5 );

    worksheet.mergeCells( `${letterQ1Start}5:${letterQ1End}5` );
    worksheet.getCell( `G5:${numToAlpha(( header[0].length - 1 ) + 5 )}5` )
      .value = header[0].name;
    worksheet.getCell( `G5:${numToAlpha(( header[0].length - 1 ) + 5 )}5` )
      .alignment = {
        horizontal: 'center',
      };
    worksheet.getCell( `G5:${numToAlpha(( header[0].length - 1 ) + 5 )}5` )
      .fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '7e6eb0' },
      };
    worksheet.getCell( `G5:${numToAlpha(( header[0].length - 1 ) + 5 )}5` )
      .font = {
        color: { argb: 'ffffff' },
      };
    worksheet.getCell( `G5:${numToAlpha(( header[0].length - 1 ) + 5 )}5` )
      .border = {
        top: { style: 'medium' },
        left: { style: 'medium' },
        bottom: { style: 'medium' },
        right: { style: 'medium' },
      };

    worksheet.mergeCells( `G6`, `G7` );
    worksheet.getCell( 'G6' ).value = header[0].prefix;
    worksheet.getCell( 'G6' ).alignment = {
      horizontal: 'center',
      vertical: 'middle',
    };
    worksheet.getCell( 'G6' ).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'ffc657' },
    };
    worksheet.getCell( 'G6' ).border = {
      top: { style: 'medium' },
      left: { style: 'medium' },
      bottom: { style: 'medium' },
      right: { style: 'medium' },
    };

    // add sub cells in Q1, partials and schemes
    let sizeQ1 = 7;
    let letterP1Q1Start = numToAlpha( sizeQ1 );
    let letterP1Q1End = '';
    const { partials: partialsQ1 } = header[0];
    partialsQ1.forEach(( item: any, i: number ) => {
      worksheet.getCell( `${letterP1Q1Start}7` ).value = item.prefix;
      worksheet.getCell( `${letterP1Q1Start}7` ).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '848484' },
      };
      worksheet.getCell( `${letterP1Q1Start}7` ).font = {
        color: { argb: 'ffffff' },
      };
      worksheet.getCell( `${letterP1Q1Start}7` ).alignment = {
        horizontal: 'center',
      };
      worksheet.getCell( `${letterP1Q1Start}7` ).border = {
        top: { style: 'medium' },
        left: { style: 'medium' },
        bottom: { style: 'medium' },
        right: { style: 'medium' },
      };

      let schemeSize = 0;
      if ( i === 0 ) {
        schemeSize = sizeQ1 + 1;
        sizeQ1 += item.length - 1;
      } else {
        schemeSize = sizeQ1 + 2;
        sizeQ1 += item.length;
      }
      letterP1Q1End = numToAlpha( sizeQ1 );
      worksheet.mergeCells( `${letterP1Q1Start}6:${letterP1Q1End}6` );
      worksheet.getCell( `${letterP1Q1Start}6` ).value = item.name;
      worksheet.getCell( `${letterP1Q1Start}6` ).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '307ecc' },
      };
      worksheet.getCell( `${letterP1Q1Start}6` ).font = {
        color: { argb: 'ffffff' },
      };
      worksheet.getCell( `${letterP1Q1Start}6` ).border = {
        top: { style: 'medium' },
        left: { style: 'medium' },
        bottom: { style: 'medium' },
        right: { style: 'medium' },
      };

      let letterQ1Scheme = numToAlpha( schemeSize );
      item.scheme.forEach(( scheme: any ) => {
        worksheet.getCell( `${letterQ1Scheme}7` ).value = scheme;
        worksheet.getCell( `${letterQ1Scheme}7` ).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '82af6f' },
        };
        worksheet.getCell( `${letterQ1Scheme}7` ).font = {
          color: { argb: 'ffffff' },
        };
        worksheet.getCell( `${letterQ1Scheme}7` ).border = {
          top: { style: 'medium' },
          left: { style: 'medium' },
          bottom: { style: 'medium' },
          right: { style: 'medium' },
        };
        schemeSize += 1;
        letterQ1Scheme = numToAlpha( schemeSize );
      });

      letterP1Q1Start = numToAlpha( sizeQ1 + 1 );
    });

    // add cell second quimestre
    let sizeQ2 = sizeQ1 + 1;
    const letterQ2Start = numToAlpha( sizeQ2 );
    const letterQ2End = numToAlpha(
      sizeQ1 + header[1].length,
    );
    worksheet.mergeCells( `${letterQ2Start}5:${letterQ2End}5` );
    worksheet.getCell( `${letterQ2Start}5:${letterQ2End}5` )
      .value = header[1].name;
    worksheet.getCell( `${letterQ2Start}5:${letterQ2End}5` )
      .alignment = {
        horizontal: 'center',
      };
    worksheet.getCell( `${letterQ2Start}5:${letterQ2End}5` ).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '7e6eb0' },
    };
    worksheet.getCell( `${letterQ2Start}5:${letterQ2End}5` ).font = {
      color: { argb: 'ffffff' },
    };
    worksheet.getCell( `${letterQ2Start}5:${letterQ2End}5` ).border = {
      top: { style: 'medium' },
      left: { style: 'medium' },
      bottom: { style: 'medium' },
      right: { style: 'medium' },
    };

    worksheet.mergeCells( `${letterQ2Start}6`, `${letterQ2Start}7` );
    worksheet.getCell( `${letterQ2Start}6` ).value = header[0].prefix;
    worksheet.getCell( `${letterQ2Start}6` ).alignment = {
      horizontal: 'center',
      vertical: 'middle',
    };
    worksheet.getCell( `${letterQ2Start}6` ).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'ffc657' },
    };
    worksheet.getCell( `${letterQ2Start}6` ).border = {
      top: { style: 'medium' },
      left: { style: 'medium' },
      bottom: { style: 'medium' },
      right: { style: 'medium' },
    };

    // add sub cells in Q2, partials and schemes
    sizeQ2 += 1;
    let letterP1Q2Start = numToAlpha( sizeQ2 );
    let letterP1Q2End = '';
    const { partials: partialsQ2 } = header[1];
    partialsQ2.forEach(( item: any, i: number ) => {
      // add partial prefix
      worksheet.getCell( `${letterP1Q2Start}7` ).value = item.prefix;
      worksheet.getCell( `${letterP1Q2Start}7` ).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '848484' },
      };
      worksheet.getCell( `${letterP1Q2Start}7` ).font = {
        color: { argb: 'ffffff' },
      };
      worksheet.getCell( `${letterP1Q2Start}7` ).alignment = {
        horizontal: 'center',
      };
      worksheet.getCell( `${letterP1Q2Start}7` ).border = {
        top: { style: 'medium' },
        left: { style: 'medium' },
        bottom: { style: 'medium' },
        right: { style: 'medium' },
      };

      let schemeSize = 0;
      if ( i === 0 ) {
        schemeSize = sizeQ2 + 1;
        sizeQ2 += item.length - 1;
      } else {
        schemeSize = sizeQ2 + 2;
        sizeQ2 += item.length;
      }

      // add partial header
      letterP1Q2End = numToAlpha( sizeQ2 );
      worksheet.mergeCells( `${letterP1Q2Start}6:${letterP1Q2End}6` );
      worksheet.getCell( `${letterP1Q2Start}6` ).value = item.name;
      worksheet.getCell( `${letterP1Q2Start}6` ).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '307ecc' },
      };
      worksheet.getCell( `${letterP1Q2Start}6` ).font = {
        color: { argb: 'ffffff' },
      };
      worksheet.getCell( `${letterP1Q2Start}6` ).border = {
        top: { style: 'medium' },
        left: { style: 'medium' },
        bottom: { style: 'medium' },
        right: { style: 'medium' },
      };

      // add cells schemes header
      let letterQ2Scheme = numToAlpha( schemeSize );
      item.scheme.forEach(( scheme: any ) => {
        worksheet.getCell( `${letterQ2Scheme}7` ).value = scheme;
        worksheet.getCell( `${letterQ2Scheme}7` ).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '82af6f' },
        };
        worksheet.getCell( `${letterQ2Scheme}7` ).font = {
          color: { argb: 'ffffff' },
        };
        worksheet.getCell( `${letterQ2Scheme}7` ).border = {
          top: { style: 'medium' },
          left: { style: 'medium' },
          bottom: { style: 'medium' },
          right: { style: 'medium' },
        };
        schemeSize += 1;
        letterQ2Scheme = numToAlpha( schemeSize );
      });

      letterP1Q2Start = numToAlpha( sizeQ2 + 1 );
    });

    // create body or list data students and notes
    const json: any[] = [];
    students.forEach(( student: IStudent, i: number ) => {
      const notesQ1: any = {};
      q1.forEach(( item: number, j: number ) => {
        notesQ1[`Q1P${j + 1}`] = hangleGetNotePartial(
          student.id, item, reports,
        );
        const { arr } = handleGetAmountSchemesInPartial( item, schemes );

        arr.forEach(( scheme: IScheme ) => {
          notesQ1[`${scheme.id}`] = hangleGetNoteScheme(
            student.id, scheme.id, reports,
          );
        });
      });

      const notesQ2: any = {};
      q2.forEach(( item: number, j: number ) => {
        notesQ2[`Q2P${j + 1}`] = hangleGetNotePartial(
          student.id, item, reports,
        );
        const { arr } = handleGetAmountSchemesInPartial( item, schemes );

        arr.forEach(( scheme: IScheme ) => {
          notesQ1[`${scheme.id}`] = hangleGetNoteScheme(
            student.id, scheme.id, reports,
          );
        });
      });

      const q1Total = hangleGetNoteByQuimestre(
        student.id, dataQ1AndQ2, 1, reports,
      );
      const q2Total = hangleGetNoteByQuimestre(
        student.id, dataQ1AndQ2, 2, reports,
      );

      const totalGeneral = parseFloat(
        (( q1Total + q2Total ) / 2 ).toFixed( 2 ),
      );

      json.push({
        index: i + 1,
        student: student.surname + student.name,
        averageGeneral: totalGeneral,
        q1: q1Total,
        ...notesQ1,
        q2: q2Total,
        ...notesQ2,
      });
    });

    let columnsArray: any[] = [];
    // eslint-disable-next-line no-restricted-syntax
    for ( const key in json ) {
    // eslint-disable-next-line no-prototype-builtins
      if ( json.hasOwnProperty( key )) {
        columnsArray = Object.keys( json[key]);
      }
    }

    // add columns in row
    let rowIndex = 8;
    json.forEach(( element: any ) => {
      const eachRow: any[] = [];
      columnsArray.forEach(( column: any, i: number ) => {
        if ( i === 1 ) {
          eachRow.push( element[column]);
          eachRow.push( element['']);
          eachRow.push( element['']);
          eachRow.push( element['']);
        } else {
          eachRow.push( element[column]);
        }
      });

      worksheet.addRow( eachRow );
      worksheet.mergeCells( `B${rowIndex}:E${rowIndex}` );
      rowIndex += 1;
    });

    const data = await workbook.xlsx.writeBuffer() as ArrayBuffer;
    const blob = new Blob([data], { type: EXCEL_TYPE });
    fs.saveAs( blob, `${fileName}${EXCEL_EXTENSION}` );
  }
};

const numToAlpha = ( num: number ): string => {
  let alpha = '';
  let numAux = num;
  for ( ; numAux >= 0; numAux = parseInt(( numAux / 26 ).toString(), 10 ) - 1 ) {
    alpha = String.fromCharCode(( numAux % 26 ) + 0x41 ) + alpha;
  }
  return alpha;
};

const handleGetSchemes = async (
  idPeriod: string, idSubject: string,
): Promise<any> => {
  const data = await getSchemesByPeriod( idPeriod, idSubject );
  return data;
};

const hadleCreateQuimestres = (
  partialLength: number,
): { q1: number[], q2: number[]} => {
  const q1 = [];
  const q2 = [];
  const q1aux = getStartAndEnd( 1, partialLength );
  for ( let i = q1aux.start; i < q1aux.end; i++ ) {
    q1.push( i );
  }
  const q2aux = getStartAndEnd( 2, partialLength );
  for ( let i = q2aux.start; i < q2aux.end; i++ ) {
    q2.push( i );
  }
  return {
    q1,
    q2,
  };
};

const getStartAndEnd = (
  q: number, partialLength: number,
): { start: number, end: number} => {
  const partials = partialLength / 2;
  const start = q === 1 ? 0 : partials;
  const end = q === 1 ? partials : partials * 2;
  return { start, end };
};

const handleGetAmountSchemesInPartial = (
  partialIndex: number, schemes: IScheme[],
): {arr: IScheme[], size: number} => {
  const data = schemes.filter(( x: any ) => x.indexPartial === partialIndex );
  return { arr: data, size: data.length };
};

const hangleGetNotePartial = (
  idStudent: string, partial: number, reports: IReportCard[],
): number => {
  const report = reports.find(
    ( x: IReportCard ) => x.idStudent === idStudent
    && x.indexPartial === partial,
  );
  if ( !report ) { return 0; }
  return report.noteFinal;
};

const handleGetReports = async (
  idPeriod: string, idSubject: string,
): Promise<IReportCard[]> => {
  const data = await getReportsByPeriodAndSubject(
    idPeriod, idSubject,
  );
  return data;
};

const hangleGetNoteScheme = (
  idStudent: string, idScheme: string, reports: IReportCard[],
): number => {
  const report = reports.find(
    ( x: IReportCard ) => x.idStudent === idStudent,
  );
  if ( !report ) { return 0; }
  const { notes } = report;
  const note = notes.find(( x: any ) => x.idScheme === idScheme );
  return note ? note.note : 0;
};

const hangleGetNoteByQuimestre = (
  idStudent: string, q1AndQ2: any, q: number, reports: IReportCard[],
): number => {
  const { q1, q2 } = q1AndQ2;
  const data = reports.filter(
    ( x: IReportCard ) => ( x.idStudent === idStudent
    && ( q === 1 )
      ? q1.includes( x.indexPartial )
      : q2.includes( x.indexPartial )),
  );
  if ( !data ) { return 0; }
  let total = 0;
  const partials = q === 1 ? q1 : q2;
  data.forEach(( item: IReportCard ) => {
    total += item.noteFinal;
  });
  total = ( partials > 0 )
    ? parseFloat(( total / partials ).toFixed( 2 ))
    : parseFloat( total.toFixed( 2 ));
  return total;
};
