import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../redux/ducks/ActivityDucks';
import { actions as actionsBook } from '../../../redux/ducks/BookDucks';
import { actions as actionsUnit } from '../../../redux/ducks/UnitDucks';
import { RootState } from '../../../redux/store';
// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import Breadcrums from '../../../components/Breadcrumbs/Breadcrumbs';
import TableActivity from '../../../components/TableActivity/TableActivity';
import DialogConfirm from '../../../components/DialogConfirm/DialogConfirm';
import Card from '../../../components/Card/Card';
import { getRoute } from '../../../helpers/get-user-by-type';

const ActivityBookList = (): JSX.Element => {
  const [open, setOpen] = useState( false );
  const [idDoc, setIdDoc] = useState( '' );

  const dispatch = useDispatch();

  const history = useHistory();

  const {
    userData,
  } = useSelector(( state: RootState ) => state.login );

  const activitiesInStore = useSelector((
    state: RootState,
  ) => state.activity.activitiesBook );

  const handleEdit = ( id: string ): void => {
    history.push( `/${getRoute( userData.type )}/actividades/editar/${id}` );
  };

  const handleOpenConfirm = ( id: string ): void => {
    setIdDoc( id );
    setOpen( true );
  };

  const handleDelete = ( confirm: boolean ): void => {
    if ( confirm ) {
      dispatch( actions.deleteActivity( idDoc ));
    }
    setOpen( false );
  };

  const handleShow = ( id: string ): void => {
    history.push( `/${getRoute( userData.type )}/actividades/ver/${id}` );
  };

  const handleNew = (): void => {
    history.push( `/${getRoute( userData.type )}/actividades/agregar` );
  };

  useEffect(() => {
    dispatch( actions.getActivities());
    dispatch( actionsBook.getBooks());
    dispatch( actionsUnit.getUnits());
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <ContainerBreadcrumbs>
            <Breadcrums
              back={false}
              itemsLinks={[
                {
                  name: 'Actividades',
                  url: `${getRoute( userData.type )}/actividades`,
                }]}
              itemsText={['Todas tus actividades']}
            />
          </ContainerBreadcrumbs>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <TableActivity
            activities={activitiesInStore}
            handleDelete={handleOpenConfirm}
            handleEdit={handleEdit}
            handleNew={handleNew}
            handleShow={handleShow}
          />
        </Card>
      </Grid>
      <DialogConfirm
        open={open}
        title="Eliminar Actividad"
        onClose={handleDelete}
      />
    </Grid>
  );
};

export default ActivityBookList;
