import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { getStudentByIdThunk } from '../../../redux/ducks/StudentDucks';
// eslint-disable-next-line max-len
import ContainerBreadcrumbs from '../../../components/ContainerBreadcrumbs/ContainerBreadcrumbs';
import Breadcrums from '../../../components/Breadcrumbs/Breadcrumbs';
import { RootState } from '../../../redux/store';
import LabelInfo from '../../../components/LabelInfo/LabelInfo';
import UserDefault from '../../../assets/img/user-photo-default.png';
import Image from '../../../components/Image/Image';
import CenterInLine from '../../../components/Center/CenterInLine';
// eslint-disable-next-line max-len
import ContainerImageForm from '../../../components/ContainerImageForm/ContainerImageForm';
import Card from '../../../components/Card/Card';

interface IParams {
  id: string;
}

const StudentView = (): JSX.Element => {
  const dispatch = useDispatch();
  const { formData, loading } = useSelector((
    state: RootState,
  ) => state.student );
  const { id } = useParams<IParams>();

  useEffect(() => {
    if ( id ) {
      dispatch( getStudentByIdThunk( id ));
    }
  }, []);

  const handleGetStatus = ( status: boolean ): string => (
    status ? 'Activo' : 'Inactivo'
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <ContainerBreadcrumbs>
            <Breadcrums
              itemsLinks={[{ name: 'Estudiantes', url: 'admin/estudiantes' }]}
              itemsText={['Datos del estudiante']}
            />
          </ContainerBreadcrumbs>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          {!loading && formData && (
            <Grid container>
              <Grid item xs={12} sm={8}>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <LabelInfo
                      label="Identificación"
                      text={formData.identification}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LabelInfo label="Nombre" text={formData.name} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LabelInfo label="Apellido" text={formData.surname} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LabelInfo label="Correo" text={formData.email} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LabelInfo
                      label="Representante"
                      text={formData.representative}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LabelInfo label="Teléfono" text={formData.phone} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LabelInfo
                      label="Estado"
                      text={handleGetStatus( formData.status )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <CenterInLine>
                  <ContainerImageForm>
                    {formData && <Image src={formData.photo || UserDefault} />}
                  </ContainerImageForm>
                </CenterInLine>
              </Grid>
            </Grid>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default StudentView;
